import React, { useEffect } from 'react';
import 'src/vendor/libs/react-toastify/react-toastify.scss';

import LayoutNavbar from './LayoutHeader';
import LayoutSidenav from './LayoutSidenav';
import LayoutFooter from './LayoutFooter';
import layoutHelpers from './helpers';

interface IProps {
  children: JSX.Element | JSX.Element[] | null;
}

const LayoutMain = (props: IProps) => {
  const { children } = props;
  const closeSidenav = (e: React.MouseEvent) => {
    e.preventDefault();
    layoutHelpers.setCollapsed(true);
  };

  useEffect(() => {
    layoutHelpers.init();
    layoutHelpers.update();
    layoutHelpers.setAutoUpdate(true);
    return () => {
      layoutHelpers.destroy();
    };
  }, []);

  return (
    <div className={'layout-wrapper layout-2'}>
      <div className={'layout-inner'}>
        <LayoutSidenav sidenavBg={'sidenav-theme'} {...props} />

        <div className={'layout-container'}>
          <LayoutNavbar {...props} sidenavToggle navbarBg={'navbar-theme'} />

          <div className={'layout-content'}>
            <div style={{ marginBottom: 70 }} className={'flex-grow-1'}>
              {children}
            </div>
          </div>
          <LayoutFooter />
        </div>
      </div>
      <div className={'layout-overlay'} onClick={closeSidenav} />
    </div>
  );
};

export default LayoutMain;
