import { PlatformCRMWeb } from 'src/services/GrpcService';

export class OtpRequestDto {
  phone!: string;
}

export class LoginRequestDto implements PlatformCRMWeb.LoginRequest {
  phone!: string;
  code!: string;
}

export class SendEmailAndPassRequestDto implements PlatformCRMWeb.SendEmailAndPassRequest {
  email!: string;
  password!: string;
}

export class TokenResponseDto implements PlatformCRMWeb.TokenResponse {
  accessToken!: string;
  refreshToken!: string;
}

export type AuthTokenValues = SendEmailAndPassRequestDto & { isRememberMe: boolean };
