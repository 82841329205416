import React from 'react';

import { SketchPicker } from 'react-color';
import { Form } from 'react-bootstrap';
import { Field } from 'react-final-form';
import { FieldLabel } from '../FieldLabel';
import { TFieldProp } from '../field.interface';
import { FieldError } from '../FieldError';

import './style.css';

interface State {
  showColorPicker: boolean;
}

export class ColorField extends React.Component<TFieldProp<string>, State> {
  state = {
    showColorPicker: false,
  };

  static defaultProps: Partial<TFieldProp<string>> = {
    isReadonly: false,
    isRequired: false,
  };

  private handleClick = () => {
    const { isReadonly } = this.props;

    if (!isReadonly) {
      this.setState((prevState) => ({ showColorPicker: !prevState.showColorPicker }));
    }
  };

  private handleClose = () => {
    this.setState({ showColorPicker: false });
  };

  render() {
    const { name, placeholder, initialValue, isRequired, isReadonly, label } = this.props;
    const { showColorPicker } = this.state;

    return (
      <Field
        name={name}
        initialValue={initialValue}
        render={({ input, meta }) => (
          <Form.Group controlId={name}>
            <FieldLabel {...label} isRequired={isRequired} />
            <Form.Control
              {...input}
              hidden
              readOnly={true}
              placeholder={placeholder}
              isInvalid={FieldError.isInvalid(meta)}
            />
            <div className="picker-field">
              <div
                className="picker-button-container"
                style={{ cursor: isReadonly ? 'default' : 'pointer' }}
                onClick={this.handleClick}
              >
                <div className="picker-button-color" style={{ backgroundColor: input.value }}></div>
              </div>
            </div>
            {showColorPicker ? (
              <div className="picker-sketch-container">
                <div className="picker-sketch-cover" onClick={this.handleClose}></div>
                <SketchPicker
                  color={input.value}
                  onChangeComplete={(color) => input.onChange(color.hex)}
                />
              </div>
            ) : null}
            <FieldError error={meta.error} />
          </Form.Group>
        )}
      />
    );
  }
}
