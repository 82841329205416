import React from 'react';
import { Modal } from 'react-bootstrap';

import DynamicForm from 'src/components/Forms/DynamicForm';
import { FIELDS } from 'src/components/Forms/form.interface';

interface IProps {
  isShowModal: boolean;
  closeModal: () => void;
  onSubmit: (values: { commentForDialOut: string }) => void;
  initialComment?: string;
  isLoading?: boolean;
}

const DialOutModal = ({ isShowModal, closeModal, onSubmit, initialComment, isLoading }: IProps) => {
  const dynamicForm = {
    name: 'dial-out',
    rows: [
      [
        {
          type: FIELDS.STRING,
          name: 'commentForDialOut',
          label: {
            label: 'Комментарий к задаче',
          },
          placeholder: 'Введите комментарий к задаче',
          initialValue: initialComment,
          variant: 'textarea',
        },
      ],
    ],
  };
  return (
    <Modal show={isShowModal} size="sm" onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title as="h5">Недозвон</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DynamicForm
          form={dynamicForm}
          onCancel={closeModal}
          onSubmit={onSubmit}
          submitText="Подтвердить"
          isLoading={isLoading}
        />
      </Modal.Body>
    </Modal>
  );
};

export default DialOutModal;
