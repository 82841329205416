import React, { useState } from 'react';

import { Form, Row, Col, Dropdown } from 'react-bootstrap';
import { AsyncTypeahead, Menu, MenuItem } from 'react-bootstrap-typeahead';

import { FieldLabel } from 'src/components/Fields/FieldLabel';
import { FieldError } from 'src/components/Fields/FieldError';
import { SimpleText } from 'src/components/Fields/Text/SimpleText';
import usePrevious from 'src/utils/usePrevious';

import { IAddressProps } from './interfaces/address.interface';
import { ISuggestion } from './interfaces/dadata.interface';
import { getAddressBySuggestion } from './AddressField.utls';
import { ReactComponent as IconFavourite } from './star.svg';
import { ReactComponent as IconClear } from './clear.svg';
import { METRO_LINE_COLORS } from 'src/utils/metro-colors';
import { TITLES, PLACEHOLDERS } from './const';

export const Address = (props: IAddressProps) => {
  const {
    isRequired,
    label,
    isReadonly,
    initialValue,
    showError,
    errorMessage,
    input,
    name,
    options,
    placeholder,
    selected,
    isLoading,
    onSearch,
    onChange,
    onFocus,
    onChangeInput,
    inputValue,
    onBlur,
    favouriteOptions,
    isShortForm,
    isValid,
    onChangeFlatNumber,
    onChangeAddressDetails,
    onClickClear,
    isFocused,
    withRegion,
    isActiveOrder,
  } = props;
  const isDisabled = !Boolean(selected?.regionId) || initialValue?.isCannotBeChanged;
  const [isMenuOpened, setIsOpenedMenu] = useState(false);
  const prevSelected = usePrevious(selected);
  if (!withRegion && prevSelected?.regionId !== selected?.regionId && inputValue === '') {
    onClickClear();
  }

  const renderFavouriteMenu = () => {
    return (
      <>
        {favouriteOptions && (
          <Dropdown.Menu alignRight={true}>
            {favouriteOptions.length ? (
              <>
                <div>
                  <div className="custom-dropdown-menu text-uppercase text-muted mx-3 py-2">
                    Адреса клиента
                  </div>
                </div>
                {favouriteOptions.map((option: ISuggestion, index) => {
                  const isLast = index === (favouriteOptions.length || 0) - 1;
                  return (
                    <Dropdown.Item
                      as={MenuItem}
                      key={option.id || option.value}
                      className="p-0"
                      option={{ ...option }}
                    >
                      <div
                        className="mx-3 py-2"
                        onClick={() => onBlur(getAddressBySuggestion(option, selected))}
                        style={{ borderBottom: isLast ? '' : 'solid 1px #ECEDEE' }}
                      >
                        <IconFavourite width="14px" height="14px" className="mr-2" />
                        {option.value}
                      </div>
                    </Dropdown.Item>
                  );
                })}
              </>
            ) : (
              <div className="px-3 py-2 text-muted">Нет адресов</div>
            )}
          </Dropdown.Menu>
        )}
      </>
    );
  };

  const renderPoint = () => {
    if (!selected?.address?.coordinates) {
      return null;
    }

    const { lat, lng } = selected?.address?.coordinates;

    return (
      <>
        <span className="align-items-center text-body">
          <i className="ion ion-ios-pin" />
          &nbsp;
          <a
            target="_blank"
            rel="noopener noreferrer"
            data-ref="geo-link"
            title="Открыть на Yandex.Картах"
            href={`//maps.yandex.ru/?text=${lat},${lng}`}
          >
            {lat.toFixed(2)}, {lng.toFixed(2)}
          </a>
        </span>
        &nbsp;&nbsp;&nbsp;
      </>
    );
  };

  const renderMetro = () => {
    if (!selected?.address?.metro) {
      return null;
    }

    const renderOne = (metro, idx, list) => (
      <span key={`${metro.line}_${metro.name}`} className="align-items-center text-body">
        <i className="ion ion-ios-square" style={{ color: METRO_LINE_COLORS[metro.line] }} />
        <span>
          &nbsp;{metro.name}&nbsp;({metro.distance.toFixed(1)} км)
          {list.length - 1 !== idx ? ',' : ''}&nbsp;
        </span>
      </span>
    );

    return (selected?.address.metro || []).map(renderOne);
  };

  const onFavouriteToggle = (
    isOpen: boolean,
    event: React.SyntheticEvent<Dropdown>,
    metadata: { source: 'select' | 'click' | 'rootClose' | 'keydown' }
  ) => {
    setIsOpenedMenu(isOpen);
    if (isOpen) {
      onFocus();
    } else if (!metadata.source) {
      onBlur(selected?.address);
    }
  };

  const renderInput = ({ inputRef, referenceElementRef, ...suggestProps }) => {
    return (
      <div
        style={{
          background: isDisabled ? '#f1f1f2' : '#ffffff',
          border: showError
            ? '1px solid #d9534f'
            : isFocused
            ? '1px solid #26B4FF'
            : '1px solid rgba(24, 28, 33, 0.1)',
        }}
        className={`d-flex form-control react-select pr-0 py-0 ${showError ? 'is-invalid' : ''}`}
      >
        <input
          {...suggestProps}
          className="custom-input"
          disabled={isDisabled}
          onChange={(e) => onChangeInput(e, suggestProps.onChange)}
          autoComplete={'new-address-field'}
          style={{
            background: isDisabled ? '#f1f1f2' : '#ffffff',
          }}
          value={inputValue}
          ref={(input) => {
            inputRef(input);
            referenceElementRef(input);
          }}
        />
        {selected?.address?.value && !isActiveOrder && <IconClear className="clear-icon" onClick={onClickClear} />}
        {favouriteOptions && (
          <>
            <span className="react-select__indicator-separator css-1okebmr-indicatorSeparator"></span>
            <Dropdown
              style={{
                backgroundColor: isDisabled ? '#f1f1f2' : '#ffffff',
                borderRadius: '4px',
              }}
              className={`${isMenuOpened ? 'react-select__control--menu-is-open' : ''}`}
              onToggle={onFavouriteToggle}
            >
              <Dropdown.Toggle
                split
                id="favouriteAddesses"
                //@ts-ignore
                variant="default"
                className="react-select__indicator react-select__dropdown-indicator css-tlfecz-indicatorContainer custom-toggle"
                disabled={isDisabled}
              >
                <svg
                  height="20"
                  width="20"
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                  focusable="false"
                  className="css-19bqh2r"
                >
                  <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                </svg>
              </Dropdown.Toggle>
              {renderFavouriteMenu()}
            </Dropdown>
          </>
        )}
      </div>
    );
  };

  const renderMenu = (results: ISuggestion[], menuProps) => {
    return (
      <Menu {...menuProps} style={{ width: 'auto' }}>
        {results.length ? (
          <>
            {results.map((result: ISuggestion, index) => (
              <MenuItem className="px-3 py-2" key={result.value} option={result}>
                {result.value}
              </MenuItem>
            ))}
          </>
        ) : (
          <MenuItem className="px-3 py-2 text-muted" key="empty" option="Нет адресов">
            Нет адресов
          </MenuItem>
        )}
      </Menu>
    );
  };

  return (
    <Form.Group>
      <FieldLabel {...label} isRequired={isRequired} />
      <Form.Control
        autoComplete={'new-address-field'}
        readOnly={true}
        hidden={!isReadonly}
        value={(initialValue || {}).value?.address?.value}
        isInvalid={showError}
      />
      {!isReadonly && (
        <AsyncTypeahead
          {...input}
          id={`${name}-value`}
          options={options}
          hidden={true}
          placeholder={placeholder}
          align="justify"
          minLength={initialValue?.favourites?.length ? 0 : 3}
          delay={300}
          disabled={isDisabled}
          useCache={false}
          isLoading={isLoading}
          isInvalid={showError}
          defaultInputValue={(initialValue || {}).value?.address?.value}
          labelKey={(option) => option.value}
          filterBy={(option) => option}
          onSearch={onSearch}
          onChange={(query) => onChange(query, input)}
          onBlur={(e) => onBlur(selected?.address)}
          onFocus={onFocus}
          className="mb-1"
          renderInput={renderInput}
          renderMenu={renderMenu}
        />
      )}
      <FieldError error={errorMessage} />
      <div className="mb-1">
        {renderPoint()}
        {renderMetro()}
      </div>
      {!isShortForm && isValid(selected?.address) && (
        <div>
          <Row>
            <Col>
              <FieldLabel label={TITLES.flat} isRequired={false} />
              <Form.Control
                id={`${name}-object-flat`}
                value={selected?.address?.object?.flat || ''}
                readOnly={isReadonly || isDisabled}
                placeholder={PLACEHOLDERS.flat}
                onChange={(evt) => onChangeFlatNumber(evt)}
              />
            </Col>
            <Col>
              <FieldLabel label={TITLES.intercom} isRequired={false} />
              <Form.Control
                id={`${name}-detail-intercom`}
                value={selected?.address?.detail?.intercom || ''}
                readOnly={isReadonly}
                placeholder={PLACEHOLDERS.intercom}
                onChange={(evt) => onChangeAddressDetails('intercom', evt)}
              />
            </Col>
            <Col>
              <FieldLabel label={TITLES.floor} isRequired={false} />
              <Form.Control
                id={`${name}-detail-floor`}
                value={selected?.address?.detail?.floor || ''}
                readOnly={isReadonly}
                placeholder={PLACEHOLDERS.floor}
                onChange={(evt) => onChangeAddressDetails('floor', evt)}
              />
            </Col>
            <Col>
              <FieldLabel label={TITLES.entrance} isRequired={false} />
              <Form.Control
                id={`${name}-detail-entrance`}
                value={selected?.address?.detail?.entrance || ''}
                readOnly={isReadonly}
                placeholder={PLACEHOLDERS.entrance}
                onChange={(evt) => onChangeAddressDetails('entrance', evt)}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <FieldLabel label={TITLES.comment} isRequired={false} />
              <SimpleText
                {...input}
                placeholder={PLACEHOLDERS.comment}
                name={`${name}-comment`}
                value={selected?.address?.detail?.comment || ''}
                readOnly={isReadonly}
                onChange={(evt) => onChangeAddressDetails('comment', evt)}
              />
            </Col>
          </Row>
        </div>
      )}
    </Form.Group>
  );
};
