import React, {useEffect, useState} from 'react';
import { observer } from 'mobx-react';
import { useParams, useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { logger } from '@qlean/front-logger';
import {Row, Col, Spinner} from 'react-bootstrap';

import CommunicationStore, {TaskDto} from 'src/store/CommunicationStore';
import ManagerStore from 'src/store/ManagerStore';
import ProspectStore from 'src/store/ProspectStore';
import { PlatformCRMWeb } from 'src/services/GrpcService';

import Info from './Info';
import { CrossellTabs } from './CrossellTabs';
import Prospect from '../Prospect';
import Person from '../Person';
import ProspectLead from '../Prospect/ProspectLead';
import {Ticket} from '../../Ticket/Ticket';

interface IProps {
  setTitle: () => void;
}

interface IRouteParams {
  communicationId: string;
  taskId?: string;
}

const Communication = observer((props: IProps) => {
  const params = useParams<IRouteParams>();
  const history = useHistory();
  const { setTitle } = props;
  const [activeTask, setActiveTask] = useState<TaskDto | undefined>(undefined);
  const [isTicket, setIsTicket] = useState<boolean>(false);
  const { communicationId, taskId = null } = params;
  const { communication, getByIdCommunication, isActionProcessing } = CommunicationStore;
  const { manager } = ManagerStore;
  const { getProspectById, prospect, isLoading } = ProspectStore;

  const person = prospect?.person;

  useEffect(() => {
    setTitle();

    if (!manager) {
      return;
    }

    if (manager?.state !== PlatformCRMWeb.ManagerState.ONLINE) {
      toast('Необходимо быть онлайн чтобы попасть на данную страницу', {
        type: 'warning',
        autoClose: 1500,
      });
      history.push('/');
      return;
    }

    if (!communicationId) {
      toast('Коммуникация не найдена', { type: 'warning', autoClose: 1500 });
      history.push('/');
      return;
    }

    getByIdCommunication({ id: communicationId });
  }, [communicationId, manager]);

  useEffect(() => {
    if (!communication || communication.id !== communicationId) {
      return;
    }

    let active;

    if (!communication.taskList || !communication.taskList.length) {
      logger.warn('no tasks in communication', communication);
      history.push('/');
    }

    if (taskId) {
      active = communication.taskList.find(({ task: { id } }) => id === taskId);
    } else {
      active = communication.taskList
        .sort((a, b) => a.order - b.order)
        .find(({ task: { state, entity } }) => state === PlatformCRMWeb.TaskState.IN_WORK && entity);
    }

    setActiveTask(
      active?.task ||
        communication.taskList
          .sort((a, b) => a.order - b.order)
          .find(({ task }) =>
            [PlatformCRMWeb.TaskState.NEW, PlatformCRMWeb.TaskState.POSTPONED, PlatformCRMWeb.TaskState.RESCHEDULED].includes(task.state)
          )?.task ||
        communication.taskList[0].task
    );
  }, [communication, taskId]);

  useEffect(() => {
    const isTicketType: boolean = activeTask?.entity?.entityType === PlatformCRMWeb.EntityType.TICKET;
    setIsTicket(isTicketType);

    if (((activeTask?.entity && taskId && taskId === activeTask.id) || (activeTask?.entity && !taskId)) && !isTicketType) {
      logger.debug('getProspectById', activeTask.entity);
      getProspectById({ id: activeTask.entity.entityId });
    } else {
      logger.warn('There is not entity');
    }
  }, [activeTask]);

  return manager && communication ? (
    <>
      <Info
        communication={communication}
        activeTaskIndex={activeTask ? communication.taskList.findIndex(({ task }) => task.id === activeTask?.id) : 0}
      />
      <div className="pl-4 pr-4 container-p-y">
        <Row>
          <Col sm={12}>
            {activeTask && (
              <CrossellTabs
                tasks={communication.taskList}
                activeTaskIndex={communication.taskList.findIndex(({ task }) => task.id === activeTask.id)}
                communicationId={communication.id}
                phone={person?.phone || prospect?.phone}
                isReadonly={
                  manager?.ssoUid !== communication?.managerSsoId ||
                  (activeTask.id === communication.taskList[communication.taskList.length - 1].task.id &&
                    activeTask.state !== PlatformCRMWeb.TaskState.IN_WORK)
                }
              />
            )}
          </Col>
        </Row>
        <Row>
          {activeTask && prospect && !isTicket && <Col sm={8}>
            {communication &&
            activeTask.entity?.entityType === PlatformCRMWeb.EntityType.PROSPECT &&
            prospect.id === activeTask.entity?.entityId ? (
              <Prospect
                {...props}
                prospect={prospect}
                taskId={activeTask.id}
                communication={communication}
                isReadonly={
                  communication?.state !== PlatformCRMWeb.CommunicationState.ASSIGNED ||
                  communication?.managerSsoId !== manager.ssoUid
                }
              />
            ) : (
              <Spinner animation="border" variant="success" />
            )}
          </Col>}
          <Col sm={4}>
            {person?.id && prospect && activeTask?.entity?.entityId === prospect.id && (
              <Person person={person} communicationId={communicationId} />
            )}
            {!person?.id && prospect && activeTask?.entity?.entityId === prospect.id && (
              <ProspectLead prospect={prospect} communicationId={communicationId} />
            )}
          </Col>
        </Row>
        <Row>
          {isTicket && activeTask && <Col sm={6}>
            <Ticket
                task={activeTask}
                communication={communication}
                isLoading={isLoading || isActionProcessing}
                manager={manager}
            />
          </Col>}
        </Row>
      </div>
    </>
  ) : (
    <Spinner animation="border" variant="success" />
  );
});

export default Communication;
