/* eslint-disable */
import React, { useEffect } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import axios from 'axios';
import { Form, Badge, Row, Col, InputGroup, Button } from 'react-bootstrap';
import { Field } from 'react-final-form';
import Select from 'react-select';

import { StringField } from 'src/components/Fields/String/StringField';
import { logger } from '@qlean/front-logger';

import { PlatformCRMWeb } from 'src/services/GrpcService';
import { FieldLabel } from '../FieldLabel';
import { FieldError } from '../FieldError';
import { getFullName } from 'src/utils/formatters';

const DADATA_URL = `https://qlean.ru/dadata/suggestions/api/4_1/rs/suggest/fio`;
const DADATA_TOKEN = '1a3b0db7bee65d261d6b29ac9f5907446bf788d5';

const options = [
  { title: 'М', value: 1 },
  { title: 'Ж', value: 0 },
];

export function FioField(props) {
  const { name, initialValue, isRequired, label } = props;
  const defaultOptions: PlatformCRMWeb.IFIO[] = [];

  const [selected, setSelected] = React.useState<PlatformCRMWeb.IFIO>(initialValue);
  const [loading, setLoading] = React.useState(false);
  const [asyncOptions, setAsyncOptions] = React.useState(defaultOptions);
  const [manualEdit, setManualEdit] = React.useState(false);

  const onSearch = async (query) => {
    setLoading(true);

    try {
      const { data } = await axios.post(
        DADATA_URL,
        { query },
        {
          headers: {
            Authorization: `Token ${DADATA_TOKEN}`,
          },
        }
      );

      setAsyncOptions(
        data.suggestions.map((item) => ({
          fullName: item.value,
          lastName: item.data.surname,
          middleName: item.data.patronymic,
          firstName: item.data.name,
          isValid: item.data.qc === 0,
          gender: item.gender === 'MALE' ? 0 : 1,
          object: item.data,
        }))
      );
    } catch (err) {
      logger.error(`[FioField] onSearch error ${err.message}`, { err });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Field
      name={name}
      initialValue={initialValue}
      render={({ input: { onChange }, meta }) => (
        <Form.Group controlId={name}>
          {!manualEdit && (
            <div>
              <FieldLabel {...label} isRequired={isRequired} />
              <div className="d-flex">
                <div className="flex-fill">
                  <Field
                    name="fio.object"
                    label={{ label: 'ФИО' }}
                    initialValue={initialValue.object}
                  >
                    {({ input }) => {
                      const showError = !input?.value?.data?.name && !input?.value?.data?.surname;
                      return (
                        <AsyncTypeahead
                          id={name + '-suggest'}
                          isLoading={loading}
                          defaultInputValue={getFullName(
                            selected?.firstName,
                            selected?.lastName,
                            selected?.middleName
                          )}
                          options={asyncOptions}
                          labelKey={(option) => `${option.fullName}`}
                          // выбор из саджеста, А НЕ ОНЧЕНЖ ИНПУТА!!!!
                          onChange={(value) => {
                            setSelected(value[0]);
                            onChange(value[0]);
                          }}
                          // А ВОТ ЭТО УЖЕ onChange инпута
                          onSearch={onSearch}
                          style={{ flex: 1 }}
                        />
                      );
                    }}
                  </Field>
                </div>
                <InputGroup.Append>
                  <Button variant="primary" onClick={() => setManualEdit(true)}>
                    Редактировать
                  </Button>
                </InputGroup.Append>
              </div>
            </div>
          )}
          {manualEdit && (
            <Field name="fio.object" label={{ label: 'ФИО' }} initialValue={initialValue.object}>
              {({ input }) => {
                return (
                  <div>
                    <div style={{ display: 'none' }}>
                      <StringField
                        name={`${name}-fullName`}
                        initialValue={selected?.fullName || ''}
                      />
                    </div>
                    <Row className="mb-1">
                      <FieldLabel label={'Фамилия'} isRequired={true} />
                      <Form.Control
                        id={`${name}-lastName`}
                        value={selected?.lastName || ''}
                        placeholder={'Фамилия'}
                        onChange={(evt) => {
                          const newSelected = {
                            ...selected,
                            lastName: evt.target.value,
                            fullName: getFullName(
                              selected.firstName,
                              evt.target.value,
                              selected.middleName
                            ),
                            object: {
                              ...selected.object,
                              surname: evt.target.value,
                            },
                          };
                          setSelected(newSelected);
                          onChange(newSelected);
                        }}
                        isInvalid={FieldError.isInvalid(meta)}
                      />
                    </Row>
                    <Row className="mb-1">
                      <FieldLabel label={'Имя'} isRequired={true} />
                      <Form.Control
                        id={`${name}-firstName`}
                        value={selected?.firstName || ''}
                        placeholder={'Имя'}
                        onChange={(evt) => {
                          const newSelected = {
                            ...selected,
                            firstName: evt.target.value,
                            fullName: getFullName(
                              evt.target.value,
                              selected.lastName,
                              selected.middleName
                            ),
                            object: {
                              ...selected.object,
                              name: evt.target.value,
                            },
                          };
                          setSelected(newSelected);
                          onChange(newSelected);
                        }}
                        isInvalid={FieldError.isInvalid(meta)}
                      />
                    </Row>
                    <Row className="mb-1">
                      <FieldLabel label={'Отчество'} isRequired={false} />
                      <Form.Control
                        id={`${name}-middleName`}
                        value={selected?.middleName || ''}
                        placeholder={'Отчество'}
                        onChange={(evt) => {
                          const newSelected = {
                            ...selected,
                            middleName: evt.target.value,
                            fullName: getFullName(
                              selected.firstName,
                              selected.lastName,
                              evt.target.value
                            ),
                            object: {
                              ...selected.object,
                              patronymic: evt.target.value,
                            },
                          };
                          setSelected(newSelected);
                          onChange(newSelected);
                        }}
                        isInvalid={FieldError.isInvalid(meta)}
                      />
                    </Row>
                    <Row className="mb-1">
                      <div className="w-100">
                        <FieldLabel label={'Пол'} isRequired={true} />
                        <div className="d-flex">
                          <div className="flex-fill">
                            <Field
                              name={`${name}-gender`}
                              type="radio"
                              render={({ input: { ...restInput } }) => {
                                return (
                                  <Select
                                    {...restInput}
                                    options={options}
                                    getOptionLabel={(option) => option.title}
                                    getOptionValue={(option) => option.value}
                                    defaultValue={options.find(
                                      ({ value }) => value === initialValue.gender
                                    )}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    onChange={(evt) => {
                                      const newSelected = {
                                        ...selected,
                                        gender: evt.value,
                                        object: {
                                          ...selected.object,
                                          gender: evt.value === 0 ? 'FEMALE' : 'MALE',
                                        },
                                      };
                                      setSelected(newSelected);
                                      onChange(newSelected);
                                    }}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                  />
                                );
                              }}
                            />
                          </div>
                          <InputGroup.Append>
                            <Button variant="primary" onClick={() => setManualEdit(false)}>
                              Сохранить
                            </Button>
                          </InputGroup.Append>
                        </div>
                      </div>
                    </Row>
                  </div>
                );
              }}
            </Field>
          )}
        </Form.Group>
      )}
    />
  );
}
