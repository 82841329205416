import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import { observer } from 'mobx-react';

import { ManagerCommunications } from 'src/features/ManagerCommunications';
import CommunicationStore from 'src/store/CommunicationStore';

import { ApplyCommunicationModal } from './ApplyCommunicationModal';

interface IRouteParams {
  taskId: string;
}

interface IProps {
  setTitle: () => void;
}

export const Task = observer(({ setTitle }: IProps) => {
  const params = useParams<IRouteParams>();
  const history = useHistory();

  const {
    applyCommunication,
    findRecentCommunication,
    recentCommunicationLoading,
    recentCommunication,
  } = CommunicationStore;
  const { taskId } = params;

  const [showApplyCommunicationModal, setShowCommunicationModal] = useState(!!taskId);

  useEffect(() => {
    setTitle();
  }, []);

  useEffect(() => {
    if (taskId) {
      findRecentCommunication({taskId});
      setShowCommunicationModal(true);
    }
  }, [taskId]);

  const onDecline = () => {
    setShowCommunicationModal(false)
    history.push('/');
  };

  const onMove = () => {
    if (recentCommunication) {
      history.push(`/communication/${recentCommunication.id}`);
    }
  }

  const onAccept = () => {
    applyCommunication(taskId).then((newCommunication) => {
      if (newCommunication?.hasAvailableTask && newCommunication?.activeCommunicationId) {
        history.push(`/communication/${newCommunication.activeCommunicationId}`);
      }
    });
  };

  return (
    <>
      <ManagerCommunications />;
      <ApplyCommunicationModal
        isShowModal={showApplyCommunicationModal}
        closeModal={onDecline}
        onAccept={onAccept}
        onMove={onMove}
        onDecline={onDecline}
        isLoading={recentCommunicationLoading}
        recentCommunication={recentCommunication}
      />
    </>
  );
});
