import React from 'react';
import Select from 'react-select';

import { Form } from 'react-bootstrap';

import { FieldLabel } from 'src/components/Fields/FieldLabel';
import { FieldError } from 'src/components/Fields/FieldError';
import { dictionary } from 'src/utils/dictionary';

import { IRegionProps } from './interfaces/address.interface';

export const Region = (props: IRegionProps) => {
  const {
    isRequired,
    initialValue,
    showError,
    input,
    isReadonly,
    selected,
    onFocus,
    onBlur,
    onChange,
    errorMessage,
  } = props;
  const isDisabled = isReadonly || initialValue?.isCannotBeChanged;
  return (
    <Form.Group controlId="region">
      <FieldLabel label="Регион" isRequired={isRequired} />
      <Form.Control
        readOnly={true}
        hidden={true}
        value={(initialValue || {}).value?.address?.value}
        isInvalid={showError}
      />
      <Select
        {...input}
        name="region"
        isDisabled={isDisabled}
        options={dictionary.selectOptions.region}
        getOptionLabel={(option) => option.value}
        // @ts-ignore
        getOptionValue={(option) => option}
        placeholder="Выберите регион"
        className={`react-select ${showError ? 'is-invalid' : ''}`}
        classNamePrefix="react-select"
        value={dictionary.selectOptions.region.find((region) => region.id === selected?.regionId)}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
        menuPortalTarget={document.body}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      />
      <FieldError error={errorMessage} />
    </Form.Group>
  );
};
