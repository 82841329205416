import React from 'react';
import { observer } from 'mobx-react';

import { ProspectDto } from 'src/store/ProspectStore';
import ProspectData from './ProspectData';
import ProspectCalculator from './ProspectCalculator';
import ProspectSummary from './ProspectSummary';
import { CommunicationDto } from 'src/store/CommunicationStore';

interface IProps {
  prospect: ProspectDto;
  communication: CommunicationDto;
  taskId: string;
  isReadonly?: boolean;
}

const Prospect = observer((props: IProps) => {
  const { prospect, isReadonly, communication } = props;
  return (
    <>
      <ProspectData isReadonly={isReadonly} prospect={prospect} />
      <ProspectCalculator isReadonly={isReadonly} prospect={prospect} />
      <ProspectSummary {...props} communication={communication} prospect={prospect} />
    </>
  );
});

export default Prospect;
