
/* eslint-disable */
//@ts-ignore
import { GrpcWebClientBase, GrpcWebClientBaseOptions, Metadata, MethodDescriptor, UnaryInterceptor } from 'grpc-web';
import { PlatformCRMWeb, sso } from './codegen/app_pb';

type MethodOptions = {
  ignoreInterceptors?: boolean
}

export type GrpcServiceOptions = GrpcWebClientBaseOptions & {
  unaryInterceptors?: ArrayLike<UnaryInterceptor<any, any>>
}

export class GrpcService {
  private client: GrpcWebClientBase;
  private metadata: Metadata = {};
  private hostname: string;
  private interceptingPromise?: Promise<any>;
  public interceptors: { errors: ((e: any) => Promise<any>)[] } = {
    errors: []
  };
  constructor(hostname: string, opts: GrpcServiceOptions = {}) {
    this.client = new GrpcWebClientBase(opts);
    this.hostname = hostname;
  }
  private makeInterceptedUnaryCall = <Result, Params>(command: string, params: Params, methodDescriptor: MethodDescriptor<Params, Result>, options: MethodOptions = {}): Promise<Result> => {
    const unaryCallHandler = (): Promise<Result> => this.client.thenableCall(this.hostname + command, params, this.metadata, methodDescriptor)
    
    if (options.ignoreInterceptors) {
      return unaryCallHandler()
    }
    
    if (this.interceptingPromise) {
      return this.interceptingPromise.then(() => {
        this.interceptingPromise = undefined;
        return unaryCallHandler()
      });
    }
    
    return new Promise((resolve, reject) => {
      unaryCallHandler().then(resolve).catch(e => {
        this.chainingInterceptors(this.interceptors.errors, e).then(() => {
          this.makeInterceptedUnaryCall<Result, Params>(command, params, methodDescriptor).then(resolve).catch(reject)
        }).catch(reject)
      });
    });
  }
  private chainingInterceptors = (interceptors: ((e: any) => Promise<any>)[], value: any) => {
    this.interceptingPromise = interceptors.reduce(
      (chain, nextInterceptor) => chain.then(nextInterceptor),
      Promise.resolve(value)
    );
    return this.interceptingPromise;
  }
  public setMetadata = (metadata: Metadata = {}) => {
    this.metadata = Object.assign({}, this.metadata, metadata);
  };

  public PlatformCRMWeb = {
    CommunicationService: {
      methodDescriptor_AddTask: new MethodDescriptor<PlatformCRMWeb.AddTaskRequest, PlatformCRMWeb.Communication>(
        '/PlatformCRMWeb.CommunicationService/AddTask',
        'unary',
        PlatformCRMWeb.AddTaskRequest,
        PlatformCRMWeb.Communication,
        (req: PlatformCRMWeb.AddTaskRequest) => PlatformCRMWeb.AddTaskRequest.encode(req).finish(),
        PlatformCRMWeb.Communication.decode,
      ),
      AddTask: (params: PlatformCRMWeb.IAddTaskRequest, options: MethodOptions = {}): Promise<PlatformCRMWeb.Communication> => {
        return this.makeInterceptedUnaryCall('/PlatformCRMWeb.CommunicationService/AddTask', params, this.PlatformCRMWeb.CommunicationService.methodDescriptor_AddTask, options);
      },
      methodDescriptor_Apply: new MethodDescriptor<PlatformCRMWeb.ApplyCommunicationRequest, PlatformCRMWeb.ApplyCommunicationResponse>(
        '/PlatformCRMWeb.CommunicationService/Apply',
        'unary',
        PlatformCRMWeb.ApplyCommunicationRequest,
        PlatformCRMWeb.ApplyCommunicationResponse,
        (req: PlatformCRMWeb.ApplyCommunicationRequest) => PlatformCRMWeb.ApplyCommunicationRequest.encode(req).finish(),
        PlatformCRMWeb.ApplyCommunicationResponse.decode,
      ),
      Apply: (params: PlatformCRMWeb.IApplyCommunicationRequest, options: MethodOptions = {}): Promise<PlatformCRMWeb.ApplyCommunicationResponse> => {
        return this.makeInterceptedUnaryCall('/PlatformCRMWeb.CommunicationService/Apply', params, this.PlatformCRMWeb.CommunicationService.methodDescriptor_Apply, options);
      },
      methodDescriptor_CancelTask: new MethodDescriptor<PlatformCRMWeb.CancelTaskRequest, PlatformCRMWeb.Communication>(
        '/PlatformCRMWeb.CommunicationService/CancelTask',
        'unary',
        PlatformCRMWeb.CancelTaskRequest,
        PlatformCRMWeb.Communication,
        (req: PlatformCRMWeb.CancelTaskRequest) => PlatformCRMWeb.CancelTaskRequest.encode(req).finish(),
        PlatformCRMWeb.Communication.decode,
      ),
      CancelTask: (params: PlatformCRMWeb.ICancelTaskRequest, options: MethodOptions = {}): Promise<PlatformCRMWeb.Communication> => {
        return this.makeInterceptedUnaryCall('/PlatformCRMWeb.CommunicationService/CancelTask', params, this.PlatformCRMWeb.CommunicationService.methodDescriptor_CancelTask, options);
      },
      methodDescriptor_Check: new MethodDescriptor<PlatformCRMWeb.CheckCommunicationRequest, PlatformCRMWeb.CheckCommunicationResponse>(
        '/PlatformCRMWeb.CommunicationService/Check',
        'unary',
        PlatformCRMWeb.CheckCommunicationRequest,
        PlatformCRMWeb.CheckCommunicationResponse,
        (req: PlatformCRMWeb.CheckCommunicationRequest) => PlatformCRMWeb.CheckCommunicationRequest.encode(req).finish(),
        PlatformCRMWeb.CheckCommunicationResponse.decode,
      ),
      Check: (params: PlatformCRMWeb.ICheckCommunicationRequest, options: MethodOptions = {}): Promise<PlatformCRMWeb.CheckCommunicationResponse> => {
        return this.makeInterceptedUnaryCall('/PlatformCRMWeb.CommunicationService/Check', params, this.PlatformCRMWeb.CommunicationService.methodDescriptor_Check, options);
      },
      methodDescriptor_CreateCommunication: new MethodDescriptor<PlatformCRMWeb.CreateCommunicationRequest, PlatformCRMWeb.Communication>(
        '/PlatformCRMWeb.CommunicationService/CreateCommunication',
        'unary',
        PlatformCRMWeb.CreateCommunicationRequest,
        PlatformCRMWeb.Communication,
        (req: PlatformCRMWeb.CreateCommunicationRequest) => PlatformCRMWeb.CreateCommunicationRequest.encode(req).finish(),
        PlatformCRMWeb.Communication.decode,
      ),
      CreateCommunication: (params: PlatformCRMWeb.ICreateCommunicationRequest, options: MethodOptions = {}): Promise<PlatformCRMWeb.Communication> => {
        return this.makeInterceptedUnaryCall('/PlatformCRMWeb.CommunicationService/CreateCommunication', params, this.PlatformCRMWeb.CommunicationService.methodDescriptor_CreateCommunication, options);
      },
      methodDescriptor_Fail: new MethodDescriptor<PlatformCRMWeb.FailRequest, PlatformCRMWeb.Communication>(
        '/PlatformCRMWeb.CommunicationService/Fail',
        'unary',
        PlatformCRMWeb.FailRequest,
        PlatformCRMWeb.Communication,
        (req: PlatformCRMWeb.FailRequest) => PlatformCRMWeb.FailRequest.encode(req).finish(),
        PlatformCRMWeb.Communication.decode,
      ),
      Fail: (params: PlatformCRMWeb.IFailRequest, options: MethodOptions = {}): Promise<PlatformCRMWeb.Communication> => {
        return this.makeInterceptedUnaryCall('/PlatformCRMWeb.CommunicationService/Fail', params, this.PlatformCRMWeb.CommunicationService.methodDescriptor_Fail, options);
      },
      methodDescriptor_FindAvailableRescheduledCommunications: new MethodDescriptor<PlatformCRMWeb.FindAvailableRescheduledCommunicationsRequest, PlatformCRMWeb.FindAvailableRescheduledCommunicationsResponse>(
        '/PlatformCRMWeb.CommunicationService/FindAvailableRescheduledCommunications',
        'unary',
        PlatformCRMWeb.FindAvailableRescheduledCommunicationsRequest,
        PlatformCRMWeb.FindAvailableRescheduledCommunicationsResponse,
        (req: PlatformCRMWeb.FindAvailableRescheduledCommunicationsRequest) => PlatformCRMWeb.FindAvailableRescheduledCommunicationsRequest.encode(req).finish(),
        PlatformCRMWeb.FindAvailableRescheduledCommunicationsResponse.decode,
      ),
      FindAvailableRescheduledCommunications: (params: PlatformCRMWeb.IFindAvailableRescheduledCommunicationsRequest, options: MethodOptions = {}): Promise<PlatformCRMWeb.FindAvailableRescheduledCommunicationsResponse> => {
        return this.makeInterceptedUnaryCall('/PlatformCRMWeb.CommunicationService/FindAvailableRescheduledCommunications', params, this.PlatformCRMWeb.CommunicationService.methodDescriptor_FindAvailableRescheduledCommunications, options);
      },
      methodDescriptor_FindCommunications: new MethodDescriptor<PlatformCRMWeb.FindCommunicationsRequest, PlatformCRMWeb.FindCommunicationsResponse>(
        '/PlatformCRMWeb.CommunicationService/FindCommunications',
        'unary',
        PlatformCRMWeb.FindCommunicationsRequest,
        PlatformCRMWeb.FindCommunicationsResponse,
        (req: PlatformCRMWeb.FindCommunicationsRequest) => PlatformCRMWeb.FindCommunicationsRequest.encode(req).finish(),
        PlatformCRMWeb.FindCommunicationsResponse.decode,
      ),
      FindCommunications: (params: PlatformCRMWeb.IFindCommunicationsRequest, options: MethodOptions = {}): Promise<PlatformCRMWeb.FindCommunicationsResponse> => {
        return this.makeInterceptedUnaryCall('/PlatformCRMWeb.CommunicationService/FindCommunications', params, this.PlatformCRMWeb.CommunicationService.methodDescriptor_FindCommunications, options);
      },
      methodDescriptor_FindRecentCommunication: new MethodDescriptor<PlatformCRMWeb.FindRecentCommunicationRequest, PlatformCRMWeb.FindRecentCommunicationResponse>(
        '/PlatformCRMWeb.CommunicationService/FindRecentCommunication',
        'unary',
        PlatformCRMWeb.FindRecentCommunicationRequest,
        PlatformCRMWeb.FindRecentCommunicationResponse,
        (req: PlatformCRMWeb.FindRecentCommunicationRequest) => PlatformCRMWeb.FindRecentCommunicationRequest.encode(req).finish(),
        PlatformCRMWeb.FindRecentCommunicationResponse.decode,
      ),
      FindRecentCommunication: (params: PlatformCRMWeb.IFindRecentCommunicationRequest, options: MethodOptions = {}): Promise<PlatformCRMWeb.FindRecentCommunicationResponse> => {
        return this.makeInterceptedUnaryCall('/PlatformCRMWeb.CommunicationService/FindRecentCommunication', params, this.PlatformCRMWeb.CommunicationService.methodDescriptor_FindRecentCommunication, options);
      },
      methodDescriptor_GetById: new MethodDescriptor<PlatformCRMWeb.CommunicationByIdRequest, PlatformCRMWeb.Communication>(
        '/PlatformCRMWeb.CommunicationService/GetById',
        'unary',
        PlatformCRMWeb.CommunicationByIdRequest,
        PlatformCRMWeb.Communication,
        (req: PlatformCRMWeb.CommunicationByIdRequest) => PlatformCRMWeb.CommunicationByIdRequest.encode(req).finish(),
        PlatformCRMWeb.Communication.decode,
      ),
      GetById: (params: PlatformCRMWeb.ICommunicationByIdRequest, options: MethodOptions = {}): Promise<PlatformCRMWeb.Communication> => {
        return this.makeInterceptedUnaryCall('/PlatformCRMWeb.CommunicationService/GetById', params, this.PlatformCRMWeb.CommunicationService.methodDescriptor_GetById, options);
      },
      methodDescriptor_GetManyProductReasons: new MethodDescriptor<PlatformCRMWeb.GetManyProductReasonsRequest, PlatformCRMWeb.GetManyProductReasonsResponse>(
        '/PlatformCRMWeb.CommunicationService/GetManyProductReasons',
        'unary',
        PlatformCRMWeb.GetManyProductReasonsRequest,
        PlatformCRMWeb.GetManyProductReasonsResponse,
        (req: PlatformCRMWeb.GetManyProductReasonsRequest) => PlatformCRMWeb.GetManyProductReasonsRequest.encode(req).finish(),
        PlatformCRMWeb.GetManyProductReasonsResponse.decode,
      ),
      GetManyProductReasons: (params: PlatformCRMWeb.IGetManyProductReasonsRequest, options: MethodOptions = {}): Promise<PlatformCRMWeb.GetManyProductReasonsResponse> => {
        return this.makeInterceptedUnaryCall('/PlatformCRMWeb.CommunicationService/GetManyProductReasons', params, this.PlatformCRMWeb.CommunicationService.methodDescriptor_GetManyProductReasons, options);
      },
      methodDescriptor_GetManyReasons: new MethodDescriptor<PlatformCRMWeb.GetManyReasonsRequest, PlatformCRMWeb.GetManyReasonsResponse>(
        '/PlatformCRMWeb.CommunicationService/GetManyReasons',
        'unary',
        PlatformCRMWeb.GetManyReasonsRequest,
        PlatformCRMWeb.GetManyReasonsResponse,
        (req: PlatformCRMWeb.GetManyReasonsRequest) => PlatformCRMWeb.GetManyReasonsRequest.encode(req).finish(),
        PlatformCRMWeb.GetManyReasonsResponse.decode,
      ),
      GetManyReasons: (params: PlatformCRMWeb.IGetManyReasonsRequest, options: MethodOptions = {}): Promise<PlatformCRMWeb.GetManyReasonsResponse> => {
        return this.makeInterceptedUnaryCall('/PlatformCRMWeb.CommunicationService/GetManyReasons', params, this.PlatformCRMWeb.CommunicationService.methodDescriptor_GetManyReasons, options);
      },
      methodDescriptor_GetMissCallsCount: new MethodDescriptor<PlatformCRMWeb.CommunicationByIdRequest, PlatformCRMWeb.GetMissCallsCountResponse>(
        '/PlatformCRMWeb.CommunicationService/GetMissCallsCount',
        'unary',
        PlatformCRMWeb.CommunicationByIdRequest,
        PlatformCRMWeb.GetMissCallsCountResponse,
        (req: PlatformCRMWeb.CommunicationByIdRequest) => PlatformCRMWeb.CommunicationByIdRequest.encode(req).finish(),
        PlatformCRMWeb.GetMissCallsCountResponse.decode,
      ),
      GetMissCallsCount: (params: PlatformCRMWeb.ICommunicationByIdRequest, options: MethodOptions = {}): Promise<PlatformCRMWeb.GetMissCallsCountResponse> => {
        return this.makeInterceptedUnaryCall('/PlatformCRMWeb.CommunicationService/GetMissCallsCount', params, this.PlatformCRMWeb.CommunicationService.methodDescriptor_GetMissCallsCount, options);
      },
      methodDescriptor_Postpone: new MethodDescriptor<PlatformCRMWeb.PostponeRequest, PlatformCRMWeb.Communication>(
        '/PlatformCRMWeb.CommunicationService/Postpone',
        'unary',
        PlatformCRMWeb.PostponeRequest,
        PlatformCRMWeb.Communication,
        (req: PlatformCRMWeb.PostponeRequest) => PlatformCRMWeb.PostponeRequest.encode(req).finish(),
        PlatformCRMWeb.Communication.decode,
      ),
      Postpone: (params: PlatformCRMWeb.IPostponeRequest, options: MethodOptions = {}): Promise<PlatformCRMWeb.Communication> => {
        return this.makeInterceptedUnaryCall('/PlatformCRMWeb.CommunicationService/Postpone', params, this.PlatformCRMWeb.CommunicationService.methodDescriptor_Postpone, options);
      },
      methodDescriptor_ProceedTask: new MethodDescriptor<PlatformCRMWeb.ProceedTaskRequest, PlatformCRMWeb.ProceedTaskResponse>(
        '/PlatformCRMWeb.CommunicationService/ProceedTask',
        'unary',
        PlatformCRMWeb.ProceedTaskRequest,
        PlatformCRMWeb.ProceedTaskResponse,
        (req: PlatformCRMWeb.ProceedTaskRequest) => PlatformCRMWeb.ProceedTaskRequest.encode(req).finish(),
        PlatformCRMWeb.ProceedTaskResponse.decode,
      ),
      ProceedTask: (params: PlatformCRMWeb.IProceedTaskRequest, options: MethodOptions = {}): Promise<PlatformCRMWeb.ProceedTaskResponse> => {
        return this.makeInterceptedUnaryCall('/PlatformCRMWeb.CommunicationService/ProceedTask', params, this.PlatformCRMWeb.CommunicationService.methodDescriptor_ProceedTask, options);
      },
      methodDescriptor_ProceedTicketTask: new MethodDescriptor<PlatformCRMWeb.ProceedTicketTaskRequest, PlatformCRMWeb.Communication>(
        '/PlatformCRMWeb.CommunicationService/ProceedTicketTask',
        'unary',
        PlatformCRMWeb.ProceedTicketTaskRequest,
        PlatformCRMWeb.Communication,
        (req: PlatformCRMWeb.ProceedTicketTaskRequest) => PlatformCRMWeb.ProceedTicketTaskRequest.encode(req).finish(),
        PlatformCRMWeb.Communication.decode,
      ),
      ProceedTicketTask: (params: PlatformCRMWeb.IProceedTicketTaskRequest, options: MethodOptions = {}): Promise<PlatformCRMWeb.Communication> => {
        return this.makeInterceptedUnaryCall('/PlatformCRMWeb.CommunicationService/ProceedTicketTask', params, this.PlatformCRMWeb.CommunicationService.methodDescriptor_ProceedTicketTask, options);
      },
      methodDescriptor_PushTask: new MethodDescriptor<PlatformCRMWeb.PushTaskRequest, PlatformCRMWeb.Communication>(
        '/PlatformCRMWeb.CommunicationService/PushTask',
        'unary',
        PlatformCRMWeb.PushTaskRequest,
        PlatformCRMWeb.Communication,
        (req: PlatformCRMWeb.PushTaskRequest) => PlatformCRMWeb.PushTaskRequest.encode(req).finish(),
        PlatformCRMWeb.Communication.decode,
      ),
      PushTask: (params: PlatformCRMWeb.IPushTaskRequest, options: MethodOptions = {}): Promise<PlatformCRMWeb.Communication> => {
        return this.makeInterceptedUnaryCall('/PlatformCRMWeb.CommunicationService/PushTask', params, this.PlatformCRMWeb.CommunicationService.methodDescriptor_PushTask, options);
      },
      methodDescriptor_Reschedule: new MethodDescriptor<PlatformCRMWeb.RescheduleRequest, PlatformCRMWeb.Communication>(
        '/PlatformCRMWeb.CommunicationService/Reschedule',
        'unary',
        PlatformCRMWeb.RescheduleRequest,
        PlatformCRMWeb.Communication,
        (req: PlatformCRMWeb.RescheduleRequest) => PlatformCRMWeb.RescheduleRequest.encode(req).finish(),
        PlatformCRMWeb.Communication.decode,
      ),
      Reschedule: (params: PlatformCRMWeb.IRescheduleRequest, options: MethodOptions = {}): Promise<PlatformCRMWeb.Communication> => {
        return this.makeInterceptedUnaryCall('/PlatformCRMWeb.CommunicationService/Reschedule', params, this.PlatformCRMWeb.CommunicationService.methodDescriptor_Reschedule, options);
      },
    },
        CustomerService: {
      methodDescriptor_AttachCard: new MethodDescriptor<PlatformCRMWeb.AttachCardRequest, PlatformCRMWeb.AttachCardResponse>(
        '/PlatformCRMWeb.CustomerService/AttachCard',
        'unary',
        PlatformCRMWeb.AttachCardRequest,
        PlatformCRMWeb.AttachCardResponse,
        (req: PlatformCRMWeb.AttachCardRequest) => PlatformCRMWeb.AttachCardRequest.encode(req).finish(),
        PlatformCRMWeb.AttachCardResponse.decode,
      ),
      AttachCard: (params: PlatformCRMWeb.IAttachCardRequest, options: MethodOptions = {}): Promise<PlatformCRMWeb.AttachCardResponse> => {
        return this.makeInterceptedUnaryCall('/PlatformCRMWeb.CustomerService/AttachCard', params, this.PlatformCRMWeb.CustomerService.methodDescriptor_AttachCard, options);
      },
      methodDescriptor_CustomerInfo: new MethodDescriptor<PlatformCRMWeb.GetCustomerInfoRequest, PlatformCRMWeb.GetCustomerInfoResponse>(
        '/PlatformCRMWeb.CustomerService/CustomerInfo',
        'unary',
        PlatformCRMWeb.GetCustomerInfoRequest,
        PlatformCRMWeb.GetCustomerInfoResponse,
        (req: PlatformCRMWeb.GetCustomerInfoRequest) => PlatformCRMWeb.GetCustomerInfoRequest.encode(req).finish(),
        PlatformCRMWeb.GetCustomerInfoResponse.decode,
      ),
      CustomerInfo: (params: PlatformCRMWeb.IGetCustomerInfoRequest, options: MethodOptions = {}): Promise<PlatformCRMWeb.GetCustomerInfoResponse> => {
        return this.makeInterceptedUnaryCall('/PlatformCRMWeb.CustomerService/CustomerInfo', params, this.PlatformCRMWeb.CustomerService.methodDescriptor_CustomerInfo, options);
      },
      methodDescriptor_GetBonusesAccount: new MethodDescriptor<PlatformCRMWeb.GetBonusesAccountRequest, PlatformCRMWeb.GetBonusesAccountResponse>(
        '/PlatformCRMWeb.CustomerService/GetBonusesAccount',
        'unary',
        PlatformCRMWeb.GetBonusesAccountRequest,
        PlatformCRMWeb.GetBonusesAccountResponse,
        (req: PlatformCRMWeb.GetBonusesAccountRequest) => PlatformCRMWeb.GetBonusesAccountRequest.encode(req).finish(),
        PlatformCRMWeb.GetBonusesAccountResponse.decode,
      ),
      GetBonusesAccount: (params: PlatformCRMWeb.IGetBonusesAccountRequest, options: MethodOptions = {}): Promise<PlatformCRMWeb.GetBonusesAccountResponse> => {
        return this.makeInterceptedUnaryCall('/PlatformCRMWeb.CustomerService/GetBonusesAccount', params, this.PlatformCRMWeb.CustomerService.methodDescriptor_GetBonusesAccount, options);
      },
      methodDescriptor_GetClientAddresses: new MethodDescriptor<PlatformCRMWeb.GetCustomerAddressesRequest, PlatformCRMWeb.GetCustomerAddressesResponse>(
        '/PlatformCRMWeb.CustomerService/GetClientAddresses',
        'unary',
        PlatformCRMWeb.GetCustomerAddressesRequest,
        PlatformCRMWeb.GetCustomerAddressesResponse,
        (req: PlatformCRMWeb.GetCustomerAddressesRequest) => PlatformCRMWeb.GetCustomerAddressesRequest.encode(req).finish(),
        PlatformCRMWeb.GetCustomerAddressesResponse.decode,
      ),
      GetClientAddresses: (params: PlatformCRMWeb.IGetCustomerAddressesRequest, options: MethodOptions = {}): Promise<PlatformCRMWeb.GetCustomerAddressesResponse> => {
        return this.makeInterceptedUnaryCall('/PlatformCRMWeb.CustomerService/GetClientAddresses', params, this.PlatformCRMWeb.CustomerService.methodDescriptor_GetClientAddresses, options);
      },
      methodDescriptor_GetCustomerCalls: new MethodDescriptor<PlatformCRMWeb.GetCustomerCallsRequest, PlatformCRMWeb.GetCustomerCallsResponse>(
        '/PlatformCRMWeb.CustomerService/GetCustomerCalls',
        'unary',
        PlatformCRMWeb.GetCustomerCallsRequest,
        PlatformCRMWeb.GetCustomerCallsResponse,
        (req: PlatformCRMWeb.GetCustomerCallsRequest) => PlatformCRMWeb.GetCustomerCallsRequest.encode(req).finish(),
        PlatformCRMWeb.GetCustomerCallsResponse.decode,
      ),
      GetCustomerCalls: (params: PlatformCRMWeb.IGetCustomerCallsRequest, options: MethodOptions = {}): Promise<PlatformCRMWeb.GetCustomerCallsResponse> => {
        return this.makeInterceptedUnaryCall('/PlatformCRMWeb.CustomerService/GetCustomerCalls', params, this.PlatformCRMWeb.CustomerService.methodDescriptor_GetCustomerCalls, options);
      },
      methodDescriptor_GetCustomerOrders: new MethodDescriptor<PlatformCRMWeb.GetCustomerOrdersRequest, PlatformCRMWeb.GetCustomerOrdersResponse>(
        '/PlatformCRMWeb.CustomerService/GetCustomerOrders',
        'unary',
        PlatformCRMWeb.GetCustomerOrdersRequest,
        PlatformCRMWeb.GetCustomerOrdersResponse,
        (req: PlatformCRMWeb.GetCustomerOrdersRequest) => PlatformCRMWeb.GetCustomerOrdersRequest.encode(req).finish(),
        PlatformCRMWeb.GetCustomerOrdersResponse.decode,
      ),
      GetCustomerOrders: (params: PlatformCRMWeb.IGetCustomerOrdersRequest, options: MethodOptions = {}): Promise<PlatformCRMWeb.GetCustomerOrdersResponse> => {
        return this.makeInterceptedUnaryCall('/PlatformCRMWeb.CustomerService/GetCustomerOrders', params, this.PlatformCRMWeb.CustomerService.methodDescriptor_GetCustomerOrders, options);
      },
      methodDescriptor_SearchCustomer: new MethodDescriptor<PlatformCRMWeb.SearchCustomerRequest, PlatformCRMWeb.SearchCustomerResponse>(
        '/PlatformCRMWeb.CustomerService/SearchCustomer',
        'unary',
        PlatformCRMWeb.SearchCustomerRequest,
        PlatformCRMWeb.SearchCustomerResponse,
        (req: PlatformCRMWeb.SearchCustomerRequest) => PlatformCRMWeb.SearchCustomerRequest.encode(req).finish(),
        PlatformCRMWeb.SearchCustomerResponse.decode,
      ),
      SearchCustomer: (params: PlatformCRMWeb.ISearchCustomerRequest, options: MethodOptions = {}): Promise<PlatformCRMWeb.SearchCustomerResponse> => {
        return this.makeInterceptedUnaryCall('/PlatformCRMWeb.CustomerService/SearchCustomer', params, this.PlatformCRMWeb.CustomerService.methodDescriptor_SearchCustomer, options);
      },
      methodDescriptor_Update: new MethodDescriptor<PlatformCRMWeb.UpdateRequest, PlatformCRMWeb.UpdateResponse>(
        '/PlatformCRMWeb.CustomerService/Update',
        'unary',
        PlatformCRMWeb.UpdateRequest,
        PlatformCRMWeb.UpdateResponse,
        (req: PlatformCRMWeb.UpdateRequest) => PlatformCRMWeb.UpdateRequest.encode(req).finish(),
        PlatformCRMWeb.UpdateResponse.decode,
      ),
      Update: (params: PlatformCRMWeb.IUpdateRequest, options: MethodOptions = {}): Promise<PlatformCRMWeb.UpdateResponse> => {
        return this.makeInterceptedUnaryCall('/PlatformCRMWeb.CustomerService/Update', params, this.PlatformCRMWeb.CustomerService.methodDescriptor_Update, options);
      },
    },
        FinanceService: {
      methodDescriptor_PayForOrder: new MethodDescriptor<PlatformCRMWeb.PayForOrderRequest, PlatformCRMWeb.PayForOrderResponse>(
        '/PlatformCRMWeb.FinanceService/PayForOrder',
        'unary',
        PlatformCRMWeb.PayForOrderRequest,
        PlatformCRMWeb.PayForOrderResponse,
        (req: PlatformCRMWeb.PayForOrderRequest) => PlatformCRMWeb.PayForOrderRequest.encode(req).finish(),
        PlatformCRMWeb.PayForOrderResponse.decode,
      ),
      PayForOrder: (params: PlatformCRMWeb.IPayForOrderRequest, options: MethodOptions = {}): Promise<PlatformCRMWeb.PayForOrderResponse> => {
        return this.makeInterceptedUnaryCall('/PlatformCRMWeb.FinanceService/PayForOrder', params, this.PlatformCRMWeb.FinanceService.methodDescriptor_PayForOrder, options);
      },
    },
        LoginService: {
      methodDescriptor_Login: new MethodDescriptor<PlatformCRMWeb.LoginRequest, PlatformCRMWeb.TokenResponse>(
        '/PlatformCRMWeb.LoginService/Login',
        'unary',
        PlatformCRMWeb.LoginRequest,
        PlatformCRMWeb.TokenResponse,
        (req: PlatformCRMWeb.LoginRequest) => PlatformCRMWeb.LoginRequest.encode(req).finish(),
        PlatformCRMWeb.TokenResponse.decode,
      ),
      Login: (params: PlatformCRMWeb.ILoginRequest, options: MethodOptions = {}): Promise<PlatformCRMWeb.TokenResponse> => {
        return this.makeInterceptedUnaryCall('/PlatformCRMWeb.LoginService/Login', params, this.PlatformCRMWeb.LoginService.methodDescriptor_Login, options);
      },
      methodDescriptor_RefreshToken: new MethodDescriptor<PlatformCRMWeb.RefreshTokenRequest, PlatformCRMWeb.TokenResponse>(
        '/PlatformCRMWeb.LoginService/RefreshToken',
        'unary',
        PlatformCRMWeb.RefreshTokenRequest,
        PlatformCRMWeb.TokenResponse,
        (req: PlatformCRMWeb.RefreshTokenRequest) => PlatformCRMWeb.RefreshTokenRequest.encode(req).finish(),
        PlatformCRMWeb.TokenResponse.decode,
      ),
      RefreshToken: (params: PlatformCRMWeb.IRefreshTokenRequest, options: MethodOptions = {}): Promise<PlatformCRMWeb.TokenResponse> => {
        return this.makeInterceptedUnaryCall('/PlatformCRMWeb.LoginService/RefreshToken', params, this.PlatformCRMWeb.LoginService.methodDescriptor_RefreshToken, options);
      },
      methodDescriptor_RequestOtp: new MethodDescriptor<PlatformCRMWeb.OtpRequest, PlatformCRMWeb.OtpResponse>(
        '/PlatformCRMWeb.LoginService/RequestOtp',
        'unary',
        PlatformCRMWeb.OtpRequest,
        PlatformCRMWeb.OtpResponse,
        (req: PlatformCRMWeb.OtpRequest) => PlatformCRMWeb.OtpRequest.encode(req).finish(),
        PlatformCRMWeb.OtpResponse.decode,
      ),
      RequestOtp: (params: PlatformCRMWeb.IOtpRequest, options: MethodOptions = {}): Promise<PlatformCRMWeb.OtpResponse> => {
        return this.makeInterceptedUnaryCall('/PlatformCRMWeb.LoginService/RequestOtp', params, this.PlatformCRMWeb.LoginService.methodDescriptor_RequestOtp, options);
      },
      methodDescriptor_SendEmailAndPass: new MethodDescriptor<PlatformCRMWeb.SendEmailAndPassRequest, PlatformCRMWeb.TokenResponse>(
        '/PlatformCRMWeb.LoginService/SendEmailAndPass',
        'unary',
        PlatformCRMWeb.SendEmailAndPassRequest,
        PlatformCRMWeb.TokenResponse,
        (req: PlatformCRMWeb.SendEmailAndPassRequest) => PlatformCRMWeb.SendEmailAndPassRequest.encode(req).finish(),
        PlatformCRMWeb.TokenResponse.decode,
      ),
      SendEmailAndPass: (params: PlatformCRMWeb.ISendEmailAndPassRequest, options: MethodOptions = {}): Promise<PlatformCRMWeb.TokenResponse> => {
        return this.makeInterceptedUnaryCall('/PlatformCRMWeb.LoginService/SendEmailAndPass', params, this.PlatformCRMWeb.LoginService.methodDescriptor_SendEmailAndPass, options);
      },
    },
        ManagerService: {
      methodDescriptor_ChangeManagerStatus: new MethodDescriptor<PlatformCRMWeb.ChangeManagerStatusRequest, PlatformCRMWeb.CurrentManagerResponse>(
        '/PlatformCRMWeb.ManagerService/ChangeManagerStatus',
        'unary',
        PlatformCRMWeb.ChangeManagerStatusRequest,
        PlatformCRMWeb.CurrentManagerResponse,
        (req: PlatformCRMWeb.ChangeManagerStatusRequest) => PlatformCRMWeb.ChangeManagerStatusRequest.encode(req).finish(),
        PlatformCRMWeb.CurrentManagerResponse.decode,
      ),
      ChangeManagerStatus: (params: PlatformCRMWeb.IChangeManagerStatusRequest, options: MethodOptions = {}): Promise<PlatformCRMWeb.CurrentManagerResponse> => {
        return this.makeInterceptedUnaryCall('/PlatformCRMWeb.ManagerService/ChangeManagerStatus', params, this.PlatformCRMWeb.ManagerService.methodDescriptor_ChangeManagerStatus, options);
      },
      methodDescriptor_GetCurrentManager: new MethodDescriptor<PlatformCRMWeb.CurrentManagerRequest, PlatformCRMWeb.CurrentManagerResponse>(
        '/PlatformCRMWeb.ManagerService/GetCurrentManager',
        'unary',
        PlatformCRMWeb.CurrentManagerRequest,
        PlatformCRMWeb.CurrentManagerResponse,
        (req: PlatformCRMWeb.CurrentManagerRequest) => PlatformCRMWeb.CurrentManagerRequest.encode(req).finish(),
        PlatformCRMWeb.CurrentManagerResponse.decode,
      ),
      GetCurrentManager: (params: PlatformCRMWeb.ICurrentManagerRequest, options: MethodOptions = {}): Promise<PlatformCRMWeb.CurrentManagerResponse> => {
        return this.makeInterceptedUnaryCall('/PlatformCRMWeb.ManagerService/GetCurrentManager', params, this.PlatformCRMWeb.ManagerService.methodDescriptor_GetCurrentManager, options);
      },
    },
        MetaService: {
      methodDescriptor_GetAvailableDates: new MethodDescriptor<PlatformCRMWeb.GetAvailableDatesRequest, PlatformCRMWeb.GetAvailableDatesResponse>(
        '/PlatformCRMWeb.MetaService/GetAvailableDates',
        'unary',
        PlatformCRMWeb.GetAvailableDatesRequest,
        PlatformCRMWeb.GetAvailableDatesResponse,
        (req: PlatformCRMWeb.GetAvailableDatesRequest) => PlatformCRMWeb.GetAvailableDatesRequest.encode(req).finish(),
        PlatformCRMWeb.GetAvailableDatesResponse.decode,
      ),
      GetAvailableDates: (params: PlatformCRMWeb.IGetAvailableDatesRequest, options: MethodOptions = {}): Promise<PlatformCRMWeb.GetAvailableDatesResponse> => {
        return this.makeInterceptedUnaryCall('/PlatformCRMWeb.MetaService/GetAvailableDates', params, this.PlatformCRMWeb.MetaService.methodDescriptor_GetAvailableDates, options);
      },
      methodDescriptor_GetAvailableTimeSlots: new MethodDescriptor<PlatformCRMWeb.GetAvailableTimeSlotsRequest, PlatformCRMWeb.GetAvailableTimeSlotsResponse>(
        '/PlatformCRMWeb.MetaService/GetAvailableTimeSlots',
        'unary',
        PlatformCRMWeb.GetAvailableTimeSlotsRequest,
        PlatformCRMWeb.GetAvailableTimeSlotsResponse,
        (req: PlatformCRMWeb.GetAvailableTimeSlotsRequest) => PlatformCRMWeb.GetAvailableTimeSlotsRequest.encode(req).finish(),
        PlatformCRMWeb.GetAvailableTimeSlotsResponse.decode,
      ),
      GetAvailableTimeSlots: (params: PlatformCRMWeb.IGetAvailableTimeSlotsRequest, options: MethodOptions = {}): Promise<PlatformCRMWeb.GetAvailableTimeSlotsResponse> => {
        return this.makeInterceptedUnaryCall('/PlatformCRMWeb.MetaService/GetAvailableTimeSlots', params, this.PlatformCRMWeb.MetaService.methodDescriptor_GetAvailableTimeSlots, options);
      },
      methodDescriptor_GetAvailableTimeSlotsFromLmsByAddressId: new MethodDescriptor<PlatformCRMWeb.GetAvailableTimeSlotsFromLmsRequest, PlatformCRMWeb.GetAvailableTimeSlotsResponse>(
        '/PlatformCRMWeb.MetaService/GetAvailableTimeSlotsFromLmsByAddressId',
        'unary',
        PlatformCRMWeb.GetAvailableTimeSlotsFromLmsRequest,
        PlatformCRMWeb.GetAvailableTimeSlotsResponse,
        (req: PlatformCRMWeb.GetAvailableTimeSlotsFromLmsRequest) => PlatformCRMWeb.GetAvailableTimeSlotsFromLmsRequest.encode(req).finish(),
        PlatformCRMWeb.GetAvailableTimeSlotsResponse.decode,
      ),
      GetAvailableTimeSlotsFromLmsByAddressId: (params: PlatformCRMWeb.IGetAvailableTimeSlotsFromLmsRequest, options: MethodOptions = {}): Promise<PlatformCRMWeb.GetAvailableTimeSlotsResponse> => {
        return this.makeInterceptedUnaryCall('/PlatformCRMWeb.MetaService/GetAvailableTimeSlotsFromLmsByAddressId', params, this.PlatformCRMWeb.MetaService.methodDescriptor_GetAvailableTimeSlotsFromLmsByAddressId, options);
      },
      methodDescriptor_GetAvailableTimeSlotsFromLmsByCoordinates: new MethodDescriptor<PlatformCRMWeb.GetAvailableTimeSlotsFromLmsRequest, PlatformCRMWeb.GetAvailableTimeSlotsResponse>(
        '/PlatformCRMWeb.MetaService/GetAvailableTimeSlotsFromLmsByCoordinates',
        'unary',
        PlatformCRMWeb.GetAvailableTimeSlotsFromLmsRequest,
        PlatformCRMWeb.GetAvailableTimeSlotsResponse,
        (req: PlatformCRMWeb.GetAvailableTimeSlotsFromLmsRequest) => PlatformCRMWeb.GetAvailableTimeSlotsFromLmsRequest.encode(req).finish(),
        PlatformCRMWeb.GetAvailableTimeSlotsResponse.decode,
      ),
      GetAvailableTimeSlotsFromLmsByCoordinates: (params: PlatformCRMWeb.IGetAvailableTimeSlotsFromLmsRequest, options: MethodOptions = {}): Promise<PlatformCRMWeb.GetAvailableTimeSlotsResponse> => {
        return this.makeInterceptedUnaryCall('/PlatformCRMWeb.MetaService/GetAvailableTimeSlotsFromLmsByCoordinates', params, this.PlatformCRMWeb.MetaService.methodDescriptor_GetAvailableTimeSlotsFromLmsByCoordinates, options);
      },
      methodDescriptor_GetClientAddresses: new MethodDescriptor<PlatformCRMWeb.GetClientAddressesRequest, PlatformCRMWeb.GetClientAddressResponse>(
        '/PlatformCRMWeb.MetaService/GetClientAddresses',
        'unary',
        PlatformCRMWeb.GetClientAddressesRequest,
        PlatformCRMWeb.GetClientAddressResponse,
        (req: PlatformCRMWeb.GetClientAddressesRequest) => PlatformCRMWeb.GetClientAddressesRequest.encode(req).finish(),
        PlatformCRMWeb.GetClientAddressResponse.decode,
      ),
      GetClientAddresses: (params: PlatformCRMWeb.IGetClientAddressesRequest, options: MethodOptions = {}): Promise<PlatformCRMWeb.GetClientAddressResponse> => {
        return this.makeInterceptedUnaryCall('/PlatformCRMWeb.MetaService/GetClientAddresses', params, this.PlatformCRMWeb.MetaService.methodDescriptor_GetClientAddresses, options);
      },
      methodDescriptor_GetCrmWebURL: new MethodDescriptor<PlatformCRMWeb.EmptyRequest, PlatformCRMWeb.GetCrmWebURLResponse>(
        '/PlatformCRMWeb.MetaService/GetCrmWebURL',
        'unary',
        PlatformCRMWeb.EmptyRequest,
        PlatformCRMWeb.GetCrmWebURLResponse,
        (req: PlatformCRMWeb.EmptyRequest) => PlatformCRMWeb.EmptyRequest.encode(req).finish(),
        PlatformCRMWeb.GetCrmWebURLResponse.decode,
      ),
      GetCrmWebURL: (params: PlatformCRMWeb.IEmptyRequest, options: MethodOptions = {}): Promise<PlatformCRMWeb.GetCrmWebURLResponse> => {
        return this.makeInterceptedUnaryCall('/PlatformCRMWeb.MetaService/GetCrmWebURL', params, this.PlatformCRMWeb.MetaService.methodDescriptor_GetCrmWebURL, options);
      },
      methodDescriptor_GetDrycleaningCards: new MethodDescriptor<PlatformCRMWeb.GetDrycleaningCardsRequest, PlatformCRMWeb.GetDrycleaningCardsResponse>(
        '/PlatformCRMWeb.MetaService/GetDrycleaningCards',
        'unary',
        PlatformCRMWeb.GetDrycleaningCardsRequest,
        PlatformCRMWeb.GetDrycleaningCardsResponse,
        (req: PlatformCRMWeb.GetDrycleaningCardsRequest) => PlatformCRMWeb.GetDrycleaningCardsRequest.encode(req).finish(),
        PlatformCRMWeb.GetDrycleaningCardsResponse.decode,
      ),
      GetDrycleaningCards: (params: PlatformCRMWeb.IGetDrycleaningCardsRequest, options: MethodOptions = {}): Promise<PlatformCRMWeb.GetDrycleaningCardsResponse> => {
        return this.makeInterceptedUnaryCall('/PlatformCRMWeb.MetaService/GetDrycleaningCards', params, this.PlatformCRMWeb.MetaService.methodDescriptor_GetDrycleaningCards, options);
      },
      methodDescriptor_GetDrycleaningTimeslots: new MethodDescriptor<PlatformCRMWeb.GetDrycleaningTimeslotsRequest, PlatformCRMWeb.GetDrycleaningTimeslotsResponse>(
        '/PlatformCRMWeb.MetaService/GetDrycleaningTimeslots',
        'unary',
        PlatformCRMWeb.GetDrycleaningTimeslotsRequest,
        PlatformCRMWeb.GetDrycleaningTimeslotsResponse,
        (req: PlatformCRMWeb.GetDrycleaningTimeslotsRequest) => PlatformCRMWeb.GetDrycleaningTimeslotsRequest.encode(req).finish(),
        PlatformCRMWeb.GetDrycleaningTimeslotsResponse.decode,
      ),
      GetDrycleaningTimeslots: (params: PlatformCRMWeb.IGetDrycleaningTimeslotsRequest, options: MethodOptions = {}): Promise<PlatformCRMWeb.GetDrycleaningTimeslotsResponse> => {
        return this.makeInterceptedUnaryCall('/PlatformCRMWeb.MetaService/GetDrycleaningTimeslots', params, this.PlatformCRMWeb.MetaService.methodDescriptor_GetDrycleaningTimeslots, options);
      },
      methodDescriptor_GetOffers: new MethodDescriptor<PlatformCRMWeb.GetOffersRequest, PlatformCRMWeb.GetOffersResponse>(
        '/PlatformCRMWeb.MetaService/GetOffers',
        'unary',
        PlatformCRMWeb.GetOffersRequest,
        PlatformCRMWeb.GetOffersResponse,
        (req: PlatformCRMWeb.GetOffersRequest) => PlatformCRMWeb.GetOffersRequest.encode(req).finish(),
        PlatformCRMWeb.GetOffersResponse.decode,
      ),
      GetOffers: (params: PlatformCRMWeb.IGetOffersRequest, options: MethodOptions = {}): Promise<PlatformCRMWeb.GetOffersResponse> => {
        return this.makeInterceptedUnaryCall('/PlatformCRMWeb.MetaService/GetOffers', params, this.PlatformCRMWeb.MetaService.methodDescriptor_GetOffers, options);
      },
      methodDescriptor_GetPlatformCards: new MethodDescriptor<PlatformCRMWeb.GetPlatformCardsRequest, PlatformCRMWeb.GetPlatformCardsResponse>(
        '/PlatformCRMWeb.MetaService/GetPlatformCards',
        'unary',
        PlatformCRMWeb.GetPlatformCardsRequest,
        PlatformCRMWeb.GetPlatformCardsResponse,
        (req: PlatformCRMWeb.GetPlatformCardsRequest) => PlatformCRMWeb.GetPlatformCardsRequest.encode(req).finish(),
        PlatformCRMWeb.GetPlatformCardsResponse.decode,
      ),
      GetPlatformCards: (params: PlatformCRMWeb.IGetPlatformCardsRequest, options: MethodOptions = {}): Promise<PlatformCRMWeb.GetPlatformCardsResponse> => {
        return this.makeInterceptedUnaryCall('/PlatformCRMWeb.MetaService/GetPlatformCards', params, this.PlatformCRMWeb.MetaService.methodDescriptor_GetPlatformCards, options);
      },
      methodDescriptor_GetPriceElement: new MethodDescriptor<PlatformCRMWeb.GetPriceElementRequest, PlatformCRMWeb.GetPriceElementResponse>(
        '/PlatformCRMWeb.MetaService/GetPriceElement',
        'unary',
        PlatformCRMWeb.GetPriceElementRequest,
        PlatformCRMWeb.GetPriceElementResponse,
        (req: PlatformCRMWeb.GetPriceElementRequest) => PlatformCRMWeb.GetPriceElementRequest.encode(req).finish(),
        PlatformCRMWeb.GetPriceElementResponse.decode,
      ),
      GetPriceElement: (params: PlatformCRMWeb.IGetPriceElementRequest, options: MethodOptions = {}): Promise<PlatformCRMWeb.GetPriceElementResponse> => {
        return this.makeInterceptedUnaryCall('/PlatformCRMWeb.MetaService/GetPriceElement', params, this.PlatformCRMWeb.MetaService.methodDescriptor_GetPriceElement, options);
      },
      methodDescriptor_SearchPromocode: new MethodDescriptor<PlatformCRMWeb.SearchPromocodeRequest, PlatformCRMWeb.SearchPromocodeResponse>(
        '/PlatformCRMWeb.MetaService/SearchPromocode',
        'unary',
        PlatformCRMWeb.SearchPromocodeRequest,
        PlatformCRMWeb.SearchPromocodeResponse,
        (req: PlatformCRMWeb.SearchPromocodeRequest) => PlatformCRMWeb.SearchPromocodeRequest.encode(req).finish(),
        PlatformCRMWeb.SearchPromocodeResponse.decode,
      ),
      SearchPromocode: (params: PlatformCRMWeb.ISearchPromocodeRequest, options: MethodOptions = {}): Promise<PlatformCRMWeb.SearchPromocodeResponse> => {
        return this.makeInterceptedUnaryCall('/PlatformCRMWeb.MetaService/SearchPromocode', params, this.PlatformCRMWeb.MetaService.methodDescriptor_SearchPromocode, options);
      },
    },
        OrderService: {
      methodDescriptor_AssignExecutor: new MethodDescriptor<PlatformCRMWeb.AssignExecutorRequest, PlatformCRMWeb.AssignExecutorResponse>(
        '/PlatformCRMWeb.OrderService/AssignExecutor',
        'unary',
        PlatformCRMWeb.AssignExecutorRequest,
        PlatformCRMWeb.AssignExecutorResponse,
        (req: PlatformCRMWeb.AssignExecutorRequest) => PlatformCRMWeb.AssignExecutorRequest.encode(req).finish(),
        PlatformCRMWeb.AssignExecutorResponse.decode,
      ),
      AssignExecutor: (params: PlatformCRMWeb.IAssignExecutorRequest, options: MethodOptions = {}): Promise<PlatformCRMWeb.AssignExecutorResponse> => {
        return this.makeInterceptedUnaryCall('/PlatformCRMWeb.OrderService/AssignExecutor', params, this.PlatformCRMWeb.OrderService.methodDescriptor_AssignExecutor, options);
      },
      methodDescriptor_ChangeStatus: new MethodDescriptor<PlatformCRMWeb.OrderChangeStatusRequest, PlatformCRMWeb.OrderChangeStatusResponse>(
        '/PlatformCRMWeb.OrderService/ChangeStatus',
        'unary',
        PlatformCRMWeb.OrderChangeStatusRequest,
        PlatformCRMWeb.OrderChangeStatusResponse,
        (req: PlatformCRMWeb.OrderChangeStatusRequest) => PlatformCRMWeb.OrderChangeStatusRequest.encode(req).finish(),
        PlatformCRMWeb.OrderChangeStatusResponse.decode,
      ),
      ChangeStatus: (params: PlatformCRMWeb.IOrderChangeStatusRequest, options: MethodOptions = {}): Promise<PlatformCRMWeb.OrderChangeStatusResponse> => {
        return this.makeInterceptedUnaryCall('/PlatformCRMWeb.OrderService/ChangeStatus', params, this.PlatformCRMWeb.OrderService.methodDescriptor_ChangeStatus, options);
      },
      methodDescriptor_ExecutorsList: new MethodDescriptor<PlatformCRMWeb.GetExecutorsListRequest, PlatformCRMWeb.GetExecutorsListResponse>(
        '/PlatformCRMWeb.OrderService/ExecutorsList',
        'unary',
        PlatformCRMWeb.GetExecutorsListRequest,
        PlatformCRMWeb.GetExecutorsListResponse,
        (req: PlatformCRMWeb.GetExecutorsListRequest) => PlatformCRMWeb.GetExecutorsListRequest.encode(req).finish(),
        PlatformCRMWeb.GetExecutorsListResponse.decode,
      ),
      ExecutorsList: (params: PlatformCRMWeb.IGetExecutorsListRequest, options: MethodOptions = {}): Promise<PlatformCRMWeb.GetExecutorsListResponse> => {
        return this.makeInterceptedUnaryCall('/PlatformCRMWeb.OrderService/ExecutorsList', params, this.PlatformCRMWeb.OrderService.methodDescriptor_ExecutorsList, options);
      },
      methodDescriptor_GetOrderById: new MethodDescriptor<PlatformCRMWeb.GetOrderByIdRequest, PlatformCRMWeb.GetOrderByIdResponse>(
        '/PlatformCRMWeb.OrderService/GetOrderById',
        'unary',
        PlatformCRMWeb.GetOrderByIdRequest,
        PlatformCRMWeb.GetOrderByIdResponse,
        (req: PlatformCRMWeb.GetOrderByIdRequest) => PlatformCRMWeb.GetOrderByIdRequest.encode(req).finish(),
        PlatformCRMWeb.GetOrderByIdResponse.decode,
      ),
      GetOrderById: (params: PlatformCRMWeb.IGetOrderByIdRequest, options: MethodOptions = {}): Promise<PlatformCRMWeb.GetOrderByIdResponse> => {
        return this.makeInterceptedUnaryCall('/PlatformCRMWeb.OrderService/GetOrderById', params, this.PlatformCRMWeb.OrderService.methodDescriptor_GetOrderById, options);
      },
      methodDescriptor_SearchOrders: new MethodDescriptor<PlatformCRMWeb.SearchOrdersRequest, PlatformCRMWeb.SearchOrdersResponse>(
        '/PlatformCRMWeb.OrderService/SearchOrders',
        'unary',
        PlatformCRMWeb.SearchOrdersRequest,
        PlatformCRMWeb.SearchOrdersResponse,
        (req: PlatformCRMWeb.SearchOrdersRequest) => PlatformCRMWeb.SearchOrdersRequest.encode(req).finish(),
        PlatformCRMWeb.SearchOrdersResponse.decode,
      ),
      SearchOrders: (params: PlatformCRMWeb.ISearchOrdersRequest, options: MethodOptions = {}): Promise<PlatformCRMWeb.SearchOrdersResponse> => {
        return this.makeInterceptedUnaryCall('/PlatformCRMWeb.OrderService/SearchOrders', params, this.PlatformCRMWeb.OrderService.methodDescriptor_SearchOrders, options);
      },
      methodDescriptor_SearchRejectionGroups: new MethodDescriptor<PlatformCRMWeb.RejectionGroupsRequest, PlatformCRMWeb.RejectionGroupsResponse>(
        '/PlatformCRMWeb.OrderService/SearchRejectionGroups',
        'unary',
        PlatformCRMWeb.RejectionGroupsRequest,
        PlatformCRMWeb.RejectionGroupsResponse,
        (req: PlatformCRMWeb.RejectionGroupsRequest) => PlatformCRMWeb.RejectionGroupsRequest.encode(req).finish(),
        PlatformCRMWeb.RejectionGroupsResponse.decode,
      ),
      SearchRejectionGroups: (params: PlatformCRMWeb.IRejectionGroupsRequest, options: MethodOptions = {}): Promise<PlatformCRMWeb.RejectionGroupsResponse> => {
        return this.makeInterceptedUnaryCall('/PlatformCRMWeb.OrderService/SearchRejectionGroups', params, this.PlatformCRMWeb.OrderService.methodDescriptor_SearchRejectionGroups, options);
      },
      methodDescriptor_SendInvoice: new MethodDescriptor<PlatformCRMWeb.SendInvoiceRequest, PlatformCRMWeb.SendInvoiceResponse>(
        '/PlatformCRMWeb.OrderService/SendInvoice',
        'unary',
        PlatformCRMWeb.SendInvoiceRequest,
        PlatformCRMWeb.SendInvoiceResponse,
        (req: PlatformCRMWeb.SendInvoiceRequest) => PlatformCRMWeb.SendInvoiceRequest.encode(req).finish(),
        PlatformCRMWeb.SendInvoiceResponse.decode,
      ),
      SendInvoice: (params: PlatformCRMWeb.ISendInvoiceRequest, options: MethodOptions = {}): Promise<PlatformCRMWeb.SendInvoiceResponse> => {
        return this.makeInterceptedUnaryCall('/PlatformCRMWeb.OrderService/SendInvoice', params, this.PlatformCRMWeb.OrderService.methodDescriptor_SendInvoice, options);
      },
      methodDescriptor_SendPaymentLink: new MethodDescriptor<PlatformCRMWeb.SendPaymentLinkRequest, PlatformCRMWeb.SendPaymentLinkResponse>(
        '/PlatformCRMWeb.OrderService/SendPaymentLink',
        'unary',
        PlatformCRMWeb.SendPaymentLinkRequest,
        PlatformCRMWeb.SendPaymentLinkResponse,
        (req: PlatformCRMWeb.SendPaymentLinkRequest) => PlatformCRMWeb.SendPaymentLinkRequest.encode(req).finish(),
        PlatformCRMWeb.SendPaymentLinkResponse.decode,
      ),
      SendPaymentLink: (params: PlatformCRMWeb.ISendPaymentLinkRequest, options: MethodOptions = {}): Promise<PlatformCRMWeb.SendPaymentLinkResponse> => {
        return this.makeInterceptedUnaryCall('/PlatformCRMWeb.OrderService/SendPaymentLink', params, this.PlatformCRMWeb.OrderService.methodDescriptor_SendPaymentLink, options);
      },
      methodDescriptor_UnassignExecutor: new MethodDescriptor<PlatformCRMWeb.UnassignExecutorRequest, PlatformCRMWeb.UnassignExecutorResponse>(
        '/PlatformCRMWeb.OrderService/UnassignExecutor',
        'unary',
        PlatformCRMWeb.UnassignExecutorRequest,
        PlatformCRMWeb.UnassignExecutorResponse,
        (req: PlatformCRMWeb.UnassignExecutorRequest) => PlatformCRMWeb.UnassignExecutorRequest.encode(req).finish(),
        PlatformCRMWeb.UnassignExecutorResponse.decode,
      ),
      UnassignExecutor: (params: PlatformCRMWeb.IUnassignExecutorRequest, options: MethodOptions = {}): Promise<PlatformCRMWeb.UnassignExecutorResponse> => {
        return this.makeInterceptedUnaryCall('/PlatformCRMWeb.OrderService/UnassignExecutor', params, this.PlatformCRMWeb.OrderService.methodDescriptor_UnassignExecutor, options);
      },
      methodDescriptor_Update: new MethodDescriptor<PlatformCRMWeb.UpdateOrderRequest, PlatformCRMWeb.UpdateOrderResponse>(
        '/PlatformCRMWeb.OrderService/Update',
        'unary',
        PlatformCRMWeb.UpdateOrderRequest,
        PlatformCRMWeb.UpdateOrderResponse,
        (req: PlatformCRMWeb.UpdateOrderRequest) => PlatformCRMWeb.UpdateOrderRequest.encode(req).finish(),
        PlatformCRMWeb.UpdateOrderResponse.decode,
      ),
      Update: (params: PlatformCRMWeb.IUpdateOrderRequest, options: MethodOptions = {}): Promise<PlatformCRMWeb.UpdateOrderResponse> => {
        return this.makeInterceptedUnaryCall('/PlatformCRMWeb.OrderService/Update', params, this.PlatformCRMWeb.OrderService.methodDescriptor_Update, options);
      },
      methodDescriptor_UpdatePaymentTypeInOmsOrder: new MethodDescriptor<PlatformCRMWeb.UpdatePaymentTypeInOmsOrderRequest, PlatformCRMWeb.UpdatePaymentTypeInOmsOrderResponse>(
        '/PlatformCRMWeb.OrderService/UpdatePaymentTypeInOmsOrder',
        'unary',
        PlatformCRMWeb.UpdatePaymentTypeInOmsOrderRequest,
        PlatformCRMWeb.UpdatePaymentTypeInOmsOrderResponse,
        (req: PlatformCRMWeb.UpdatePaymentTypeInOmsOrderRequest) => PlatformCRMWeb.UpdatePaymentTypeInOmsOrderRequest.encode(req).finish(),
        PlatformCRMWeb.UpdatePaymentTypeInOmsOrderResponse.decode,
      ),
      UpdatePaymentTypeInOmsOrder: (params: PlatformCRMWeb.IUpdatePaymentTypeInOmsOrderRequest, options: MethodOptions = {}): Promise<PlatformCRMWeb.UpdatePaymentTypeInOmsOrderResponse> => {
        return this.makeInterceptedUnaryCall('/PlatformCRMWeb.OrderService/UpdatePaymentTypeInOmsOrder', params, this.PlatformCRMWeb.OrderService.methodDescriptor_UpdatePaymentTypeInOmsOrder, options);
      },
    },
        ProspectService: {
      methodDescriptor_GetProspectById: new MethodDescriptor<PlatformCRMWeb.GetProspectByIdRequest, PlatformCRMWeb.GetProspectByIdResponse>(
        '/PlatformCRMWeb.ProspectService/GetProspectById',
        'unary',
        PlatformCRMWeb.GetProspectByIdRequest,
        PlatformCRMWeb.GetProspectByIdResponse,
        (req: PlatformCRMWeb.GetProspectByIdRequest) => PlatformCRMWeb.GetProspectByIdRequest.encode(req).finish(),
        PlatformCRMWeb.GetProspectByIdResponse.decode,
      ),
      GetProspectById: (params: PlatformCRMWeb.IGetProspectByIdRequest, options: MethodOptions = {}): Promise<PlatformCRMWeb.GetProspectByIdResponse> => {
        return this.makeInterceptedUnaryCall('/PlatformCRMWeb.ProspectService/GetProspectById', params, this.PlatformCRMWeb.ProspectService.methodDescriptor_GetProspectById, options);
      },
      methodDescriptor_Upsert: new MethodDescriptor<PlatformCRMWeb.UpsertProspectRequest, PlatformCRMWeb.UpsertProspectResponse>(
        '/PlatformCRMWeb.ProspectService/Upsert',
        'unary',
        PlatformCRMWeb.UpsertProspectRequest,
        PlatformCRMWeb.UpsertProspectResponse,
        (req: PlatformCRMWeb.UpsertProspectRequest) => PlatformCRMWeb.UpsertProspectRequest.encode(req).finish(),
        PlatformCRMWeb.UpsertProspectResponse.decode,
      ),
      Upsert: (params: PlatformCRMWeb.IUpsertProspectRequest, options: MethodOptions = {}): Promise<PlatformCRMWeb.UpsertProspectResponse> => {
        return this.makeInterceptedUnaryCall('/PlatformCRMWeb.ProspectService/Upsert', params, this.PlatformCRMWeb.ProspectService.methodDescriptor_Upsert, options);
      },
    },
  };
  public sso = {
  
  };
};