import React, { useState, useEffect } from 'react';

import { Col, Form, Button } from 'react-bootstrap';
import Select from 'react-select';
import { Field } from 'react-final-form';
import { FieldLabel } from 'src/components/Fields/FieldLabel';
import { FieldError } from 'src/components/Fields/FieldError';
import { TFieldProp } from 'src/components/Fields/field.interface';

import { ERROR_MESSAGES, isValidPaymentType } from 'src/utils/validation';

import 'src/vendor/libs/react-select/react-select.scss';

interface AnyObject {
  [key: string]: any;
}

export interface ISelectFieldProp {
  buttonTitle: string;
  onButtonClick: () => void;
  options: AnyObject[];
}

export type TSelectFieldProp = ISelectFieldProp & TFieldProp<AnyObject>;

export const PaymentTypeField = (props: TSelectFieldProp) => {
  const {
    isReadonly,
    placeholder = 'Выбор',
    name,
    isRequired,
    label,
    initialValue,
    buttonTitle,
    onButtonClick,
    options,
    errors,
  } = props;

  const [showError, setShowError] = useState(false);
  const [ value, setValue ] = useState(initialValue);

  useEffect(() => {
    setShowError(Boolean(errors));
  }, [ errors ]);

  const onFocus = () => {
    setShowError(false);
  };

  const onBlur = (input) => {
    setShowError(!isValidPaymentType(input?.value?.id));
  };

  useEffect(() => {
    setValue(initialValue);
  }, [ initialValue ]);

  return (
    <Field
      name={name}
      initialValue={initialValue}
      render={({ input }) => {
        return (
          <>
            <Form.Row>
              <Form.Group controlId={name} as={Col} md={12}>
                <FieldLabel {...label} isRequired={isRequired} />
                <Form.Control
                  readOnly={true}
                  hidden={true}
                  value={initialValue?.value || ''}
                  isInvalid={showError}
                />
                <Select
                  {...input}
                  className={`react-select ${showError ? 'is-invalid' : ''}`}
                  classNamePrefix="react-select"
                  options={options}
                  getOptionLabel={(option) => option.value}
                  getOptionValue={(option) => option.id}
                  onFocus={onFocus}
                  defaultValue={options.find(
                    ({ id }) => value === props.initialValue?.id,
                  )}
                  onBlur={() => onBlur(input)}
                  placeholder={placeholder}
                  isSearchable={true}
                  isDisabled={isReadonly}
                  value={value}
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  onChange={(res) => {
                    setValue(res);
                    input.onChange(res);
                  }}
                />
                <FieldError error={errors ? `${errors}` : ERROR_MESSAGES.PAYMENT_TYPE} />
              </Form.Group>
            </Form.Row>
            {buttonTitle && (
              <Form.Row>
                <Form.Group controlId={name} as={Col} md={12}>
                  <Button variant="link" className="p-0" onClick={onButtonClick}>
                    {buttonTitle || 'Привязать карту'}
                  </Button>
                </Form.Group>
              </Form.Row>
            )}
          </>
        );
      }}
    />
  );
};
