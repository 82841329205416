import { Type } from 'class-transformer';
import { PlatformCRMWeb } from 'src/services/GrpcService';
import { AddressDto, FioDto, PersonDto } from '../CustomerStore';

export class GetProspectByIdResponseDto implements PlatformCRMWeb.IGetProspectByIdResponse {
  @Type(() => ProspectDto)
  prospect!: ProspectDto;
}

export class ProspectDto implements PlatformCRMWeb.IProspect {
  id!: string;
  taskId?: string;
  prospectNumber!: number;
  isShortForm!: boolean;
  phone!: string;
  name!: string;
  @Type(() => FioDto)
  fio!: FioDto;
  email!: string;
  @Type(() => CalculatePriceDto)
  price!: CalculatePriceDto;
  status!: string;
  usp!: string;
  strategy!: string;
  cancelReason!: string;
  platform!: string;
  @Type(() => PersonDto)
  person!: PersonDto;
  startDate?: string;
  orderId!: string;
  isManualProcessing!: boolean;
  @Type(() => AddressDto)
  address!: AddressDto;
  regionId!: number;
  promocode!: string;
  utmCampaign!: string;
  utmContent!: string;
  utmData!: string;
  utmMedium!: string;
  utmSource!: string;
  utmTerm!: string;
  serviceType!: string;
  paymentType!: string;
  paymentTypeMeta!: string;
  regularity!: string;
  cardNumber!: string;
  qleanClientId!: string;
  qleanOrderId!: string;
  isOvertime!: boolean;
  totalPrice!: number;
  totalDuration!: number;
  commentForExecutor!: string;
  commentForSupport!: string;
  commentFromCustomer!: string;
  createdAt!: string;
  updatedAt!: string;
  discount!: number;
  @Type(() => AddressDto)
  keysDelivery?: AddressDto;
  @Type(() => AddressDto)
  keysPickup?: AddressDto;
  externalId?: string;
  adminPanelLink?: string;
  qleanAddress?: string;
  @Type(() => DrycleaningMetaDto)
  drycleaningMeta!: DrycleaningMetaDto;
  numberOfPayments?: number;
}

export class CalculatePriceDto implements PlatformCRMWeb.ICalculatePrice {
  @Type(() => CalculateProductDto)
  products!: CalculateProductDto[];
  totalPrice!: number;
  totalDuration!: number;
  subTotalPrice!: number;
  relevantUntil!: string;
  priceListTitle!: string;
  priceListId!: string;
  createdAt!: string;
  updatedAt!: string;
  bonusesUsed?: number;
  minPaymentPercent?: number;
}

export class CalculateProductDto implements PlatformCRMWeb.ICalculateProduct {
  productSlug!: string;
  @Type(() => CalculateOptionDto)
  options!: CalculateOptionDto[];
  totalPrice!: number;
  totalDuration!: number;
  subTotalPrice!: number;
  baseCost!: number;
  baseDuration!: number;
  id!: string;
  createdAt!: string;
}

export class CalculateOptionDto implements PlatformCRMWeb.ICalculateOption {
  slug!: string;
  value!: number;
  totalPrice!: number;
  totalDuration!: number;
  id!: string;
  createdAt!: string;
  updatedAt!: string;
  isFree!: boolean;
  factor?: number;
}

export interface IError {
  type: string;
  message: string;
}

export class DrycleaningMetaDto implements PlatformCRMWeb.IDrycleaningMeta {
  id?: string;
  planId!: string;
  @Type(() => DrycleaningSlotDto)
  pickupTimeslot!: DrycleaningSlotDto;
  @Type(() => DrycleaningSlotDto)
  returnTimeslot!: DrycleaningSlotDto;
  creditCardId?: string;
}

export class DrycleaningSlotDto implements PlatformCRMWeb.IDrycleaningSlot {
  id!: string;
  timeRange!: string;
  date!: string;
  startAt!: string;
  duration!: number;
  pickup!: boolean;
  'return'!: boolean;
  price!: number;
}
