import React from 'react';

import { Form, InputGroup } from 'react-bootstrap';
import { Field } from 'react-final-form';
import { FieldLabel } from 'src/components/Fields/FieldLabel';
import { FieldError } from 'src/components/Fields/FieldError';

import { TFieldProp } from 'src/components/Fields/field.interface';

export enum Sign {
  RUR = '₽',
  PER = '%',
}

export interface IMoneyFieldProp {
  variants?: Sign;
}

export type TMoneyFieldProp = IMoneyFieldProp & TFieldProp<number>;

export class MoneyField extends React.Component<TMoneyFieldProp> {
  static defaultProps: Partial<TMoneyFieldProp> = {
    isReadonly: false,
    isRequired: false,
  };

  private renderPrepend = () => {
    const { variants } = this.props;

    if (variants) {
      return (
        <InputGroup.Prepend>
          <InputGroup.Text>{Sign[variants]}</InputGroup.Text>
        </InputGroup.Prepend>
      );
    }

    return null;
  };

  render() {
    const { name, placeholder, initialValue, isRequired, isReadonly, label } = this.props;

    return (
      <Field
        name={name}
        initialValue={initialValue}
        render={({ input, meta }) => (
          <Form.Group controlId={name}>
            <FieldLabel {...label} isRequired={isRequired} />
            <InputGroup>
              {this.renderPrepend()}
              <Form.Control
                type="number"
                {...input}
                value={input.value?.toString()}
                readOnly={isReadonly}
                placeholder={placeholder}
                isInvalid={FieldError.isInvalid(meta)}
              />
            </InputGroup>
            <FieldError error={meta.error} />
          </Form.Group>
        )}
      />
    );
  }
}
