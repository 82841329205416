import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { CommunicationDto } from 'src/store/CommunicationStore';
import { Spinner } from 'react-bootstrap';

interface IProps {
  isShowModal: boolean;
  closeModal: () => void;
  recentCommunication?: CommunicationDto;
  onAccept: () => void;
  onDecline: () => void;
  onMove: () => void;
  isLoading?: boolean;
}

export const ApplyCommunicationModal = ({
  isShowModal,
  closeModal,
  recentCommunication,
  onAccept,
  isLoading,
  onDecline, onMove,
}: IProps) => {
  console.log('!!!', recentCommunication, isLoading);
  if (isLoading) {
    return (
      <Modal show={isShowModal} size="sm">
        <Spinner animation="border" size="sm" variant="primary" />
      </Modal>
    )
  }

  return (
    <Modal show={isShowModal} size="sm" onHide={closeModal}>
      {recentCommunication ? (
        <>
          <Modal.Header closeButton>
            <Modal.Title as="h5">Перейти к последней коммуникации</Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <Button variant="success" onClick={onMove}>
              Перейти
            </Button>
          </Modal.Footer>
        </>
      ) : (
        <>
          <Modal.Header closeButton>
            <Modal.Title as="h5">Взять задачу в работу</Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <Button variant="success" onClick={onAccept}>
              Да
            </Button>
            <Button variant="danger" onClick={onDecline}>
              Нет
            </Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};
