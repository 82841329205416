import React, { FC, useCallback, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import QRCode from 'react-qr-code'
import OrderStore from '../../../store/OrderStore/OrderStore';
import { ButtonTitle } from '../../../components/ButtonTitle';

interface Props {
  show: boolean;
  onClose(): void | Promise<void>;
  store: OrderStore;
}

export const SoftPostQR: FC<Props> = (props) => {
  const { payBySoftPos, isLoading } = props.store
  const [link, setLink] = useState<string>('');

  const generateQr = useCallback(async () => {
    const res = await payBySoftPos()
    if (res) {
      setLink(res)
    }
  }, [payBySoftPos])

  useEffect(() => {
    if (props.show) {
      generateQr();
    }
  }, [props.show]);

  const renderBody = useCallback(() => {
    if (isLoading || !link) {
      return null;
    }

    return <QRCode value={link} />
  }, [isLoading, link])

  return (
    <Modal show={props.show} centered hidden={!props.show} onHide={props.onClose}>
      <Modal.Header>
        <Modal.Title>Оплата по QR коду</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex row justify-content-center">{renderBody()}</Modal.Body>
      <Modal.Footer className="d-flex row justify-content-between">
        <Button disabled={isLoading} onClick={props.onClose}><ButtonTitle isLoading={isLoading} title="Закрыть" /></Button>
        <Button disabled={isLoading} onClick={generateQr}><ButtonTitle isLoading={isLoading} title="Пересоздать QR" /></Button>
      </Modal.Footer>
    </Modal>
  )
}
