import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';

export interface IDropdownListOption {
  id: string | number;
  value: string;
  options?: IDropdownListOption[];
  getDate?: () => Date;
}

interface IProps {
  options: IDropdownListOption[];
  onClick: (option: IDropdownListOption) => void;
  previousOptionsValue?: string;
  align?: 'leftTop' | 'rightTop' | 'leftBottom' | 'rightBottom';
}

const styles = {
  leftTop: { top: 0, right: '100%', bottom: 'auto', left: 'auto' },
  rightTop: {},
  leftBottom: { top: 'auto', right: '100%', bottom: 0, left: 'auto' },
  rightBottom: { top: 'auto', bottom: 0 },
};

export const DropdownList = ({ options, onClick, previousOptionsValue, align = 'rightTop' }: IProps) => {
  const [onLeft, setOnLeft] = useState(false);
  const onMouse = () => setOnLeft(document.body.scrollWidth > document.body.clientWidth);

  return (
    <>
      {options.map((option) => {
        if (option.options && option.options.length) {
          return (
            <div key={option.id} className="dropdown-toggle" onMouseOver={onMouse} onMouseOut={onMouse}>
              <Dropdown.Item>{option.value}</Dropdown.Item>
              <div className="dropdown-menu dropdown-menu-right" style={onLeft ? styles.leftTop : styles[align]}>
                <DropdownList
                  options={option.options}
                  onClick={onClick}
                  previousOptionsValue={previousOptionsValue ? `${previousOptionsValue} - ${option.value}` : option.value}
                />
              </div>
            </div>
          );
        }
        return (
          <Dropdown.Item
            key={option.id}
            onClick={() => {
              if (previousOptionsValue) {
                onClick({ ...option, value: `${previousOptionsValue} - ${option.value}` });
              } else {
                onClick(option);
              }
            }}
          >
            {option.value}
          </Dropdown.Item>
        );
      })}
    </>
  );
};
