import React, { useEffect } from 'react';

import ProspectStore, { ProspectDto } from 'src/store/ProspectStore';

import 'src/vendor/styles/pages/users.scss';
import { PlatformCRMWeb } from 'src/services/GrpcService';
import { Calculator } from 'src/components/Calculator/Calculator';
import { logger } from '@qlean/front-logger';
import { AddressDto } from 'src/store/CustomerStore';
import { observer } from 'mobx-react';

interface IProps {
  prospect: ProspectDto;
  isReadonly?: boolean;
}

const ProspectCalculator = observer((props: IProps) => {
  const { prospect, isReadonly } = props;
  const { upsertProspect, isLoading, errors } = ProspectStore;
  const product = prospect.price?.products?.[0];

  const onClickSubmit = (
    products: PlatformCRMWeb.Product[],
    keysDelivery?: AddressDto,
    keysPickup?: AddressDto
  ) => {
    const {
      serviceType,
      id,
      phone,
      regionId,
      address,
      startDate,
      paymentType,
      regularity,
      externalId,
      drycleaningMeta,
    } = prospect;
    logger.debug('[ProspectCalculator] upsertProspect', {
      id,
      phone: prospect.person.phone ?? phone,
      products,
      keysPickup,
      keysDelivery,
      serviceType,
      regionId,
      address,
      startDate,
      paymentType,
      regularity,
      drycleaningMeta,
    });
    upsertProspect({
      id,
      phone: prospect.person.phone ?? phone,
      products,
      keysPickup: keysPickup || {},
      keysDelivery: keysDelivery || {},
      serviceType,
      regionId,
      address,
      startDate,
      paymentType,
      regularity,
      externalId,
      drycleaningMeta,
    });
  };

  useEffect(() => {
    const {
      serviceType,
      id,
      phone,
      regionId,
      address,
      startDate,
      paymentType,
      regularity,
      price,
    } = prospect;
    /**
     * Добавляем базовый продукт в prospect.
     * При этом будут созданы опции по умолчанию, рассчитана цена и продолжительность
     */
    if (!price?.products && regionId) {
      const args = {
        id,
        phone,
        products: [{ productSlug: serviceType }],
        regionId,
        address,
        startDate,
        paymentType,
        regularity,
        serviceType,
      };
      logger.debug('[ProspectCalculator] upsertProspect', args);
      upsertProspect(args);
    }
  }, [prospect.regionId, prospect.id]);

  return prospect.regionId && prospect.serviceType ? (
    <Calculator
      regionId={prospect.regionId}
      productSlug={prospect.serviceType}
      product={product}
      onSubmit={onClickSubmit}
      keysDelivery={prospect.keysDelivery}
      keysPickup={prospect.keysPickup}
      isLoading={isLoading}
      isReadonly={isReadonly}
      error={errors.find((error) => error.type === 'calculator')?.message}
    />
  ) : null;
});

export default ProspectCalculator;
