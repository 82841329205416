import React from 'react';
import { observer } from 'mobx-react';
import { Card, Collapse } from 'react-bootstrap';

interface IProps {
  panel: bffPanels | componentsPanels;
  panelExpanded: bffPanels | componentsPanels | undefined;
  onClick: (e, panel: bffPanels | componentsPanels) => void;
  children?: JSX.Element | JSX.Element[];
  title?: string;
}

export type bffPanels =
  | 'manager'
  | 'task'
  | 'prospect'
  | 'order'
  | 'customer'
  | 'addresses'
  | 'promocode'
  | 'bonuses'
  | 'searchCustomer'
  | 'reasons';

export type componentsPanels =
  | 'form'
  | 'calculator'
  | 'toast'
  | 'crosssell'
  | 'customerHistory'
  | 'searchCustomer';

export const DemoCard = observer(({ panel, panelExpanded, onClick, children, title }: IProps) => {
  return (
    <Card className="mb-2">
      <Card.Header
        as="h6"
        className="cursor-pointer"
        onClick={(e) => onClick(e, panel)}
        aria-expanded={panelExpanded === panel}
      >
        {title ? title : panel}
      </Card.Header>
      <Collapse in={panelExpanded === panel}>
        <div>
          <Card.Body>{children}</Card.Body>
        </div>
      </Collapse>
    </Card>
  );
});
