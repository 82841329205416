import moment from 'moment';
import 'moment/locale/ru';
import { CalculateOptionDto } from 'src/store/ProspectStore';
import { PlatformCRMWeb } from 'src/services/GrpcService';

import {
  PriceElementDto,
  PriceElementMatrixDto,
  PriceElementOptionDto,
} from 'src/store/MetaStore/MetaStore.dto';

export function parseDate(date?: string, type?: 'dateInTime') {
  if (!date) return '';
  moment.locale('ru');

  if (type === 'dateInTime') {
    return moment(date).format('DD.MM.YY в HH:mm');
  }
  return moment(date).format('HH:mm DD.MM.YY');
}

export const parsePrice = (price: number) => {
  if (!price) {
    return '0 р.';
  }
  return `${Math.ceil(price / 100)} р.`;
};

export const parseMinimumPartOfOrderPayment = (
  minPaymentPercent: number = 70,
  price: number = 0
) => {
  return `${Math.ceil(((minPaymentPercent / 100) * price) / 100)} р. (${minPaymentPercent}%)`;
};

export const parseDiscount = (
  totalPrice: number = 0,
  subTotalPrice: number = 0,
  bonusesUsed: number = 0
) => {
  return `${(subTotalPrice - totalPrice - bonusesUsed) / 100} р.`;
};

export const parseBonuses = (bonuses: number = 0) => {
  return `${bonuses / 100}`;
};

export const parseDuration = (duration: number) => {
  if (!duration || duration === 0) {
    return '0 мин.';
  }
  return `${Math.trunc(duration / 60) ? ` ${Math.trunc(duration / 60)} ч.` : ''}${
    Math.trunc(duration % 60) ? ` ${Math.trunc(duration % 60)} мин.` : ''
  }`;
};

export const getFullName = (
  firstName?: string | null,
  lastName?: string | null,
  middleName?: string | null
): string => {
  const firstNameInFull = firstName ? `${firstName} ` : '';
  const lastNameInFull = lastName ? `${lastName} ` : '';

  return `${firstNameInFull}${lastNameInFull}${middleName || ''}`;
};

export interface ITargetOption {
  slug: string;
  value?: number;
  totalPrice?: number;
  totalDuration?: number;
  id?: string;
  createdAt?: string;
  updatedAt?: string;
  priceElement: PriceElementDto;
  costLinear: number;
  durationLinear: number;
  durationMatrix: PriceElementMatrixDto;
  isFree: boolean;
  defaultValue: number;
  tags: string[];
  title: string;
  shortDesc: string;
  iconName: string;
  type: PlatformCRMWeb.PriceElementOptionType;
}

export const parseChosedOptions = (
  priceElementOptions: PriceElementOptionDto[],
  calculateOptions: CalculateOptionDto[]
): ITargetOption[] => {
  return calculateOptions.reduce((result: ITargetOption[], calculateOption) => {
    const foundedPriceElementOption = priceElementOptions.find(
      (priceElementOption) =>
        priceElementOption.slug === calculateOption.slug && !priceElementOption.isFree
    );
    if (foundedPriceElementOption) {
      result.push({
        ...calculateOption,
        ...foundedPriceElementOption,
      });
    }
    return result;
  }, []);
};

export const formatPhone = (phone: string, isSimple = false) => {
  phone = phone.replace(/\D/g, '');

  if (isSimple) {
    return phone.replace(
      /(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/,
      ''.concat(phone.startsWith('8') ? '' : '', '$1$2$3$4$5'),
    );
  } else {
    return phone.replace(
      /(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/,
      ''.concat(phone.startsWith('8') ? '' : '+', '$1\xA0($2)\xA0$3-$4-$5'),
    );
  }
};
