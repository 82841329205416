import React from 'react';
import { Spinner } from 'react-bootstrap';

interface IProps {
  isLoading?: boolean;
  title?: React.ReactNode;
}

export const ButtonTitle = ({ isLoading, title }: IProps) => {
  return isLoading ? (
    <>
      <Spinner as="span" animation="border" role="status" aria-hidden="true" /> Загрузка...
    </>
  ) : (
    <>{title}</>
  );
};
