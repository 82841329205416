import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { Card, Col, Row } from 'react-bootstrap';

interface IProps {
  setTitle: () => void;
}

const Dashboard = observer(({ setTitle }: IProps) => {
  useEffect(() => {
    setTitle();
  }, []);
  return (
    <div className="px-4 container-p-y">
      <Card className="mt-5">
        <Card.Header as="h5" className="py-4 px-5">
          Основные ссылки
        </Card.Header>
        <Row noGutters className="row-bordered">
          <Col md={12} className="p-5">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://app.periscopedata.com/app/qlean/722208/%D0%9B%D0%B0%D0%B1%D1%81%D1%8B-%D0%BE%D1%82%D1%87%D0%B5%D1%82%D1%8B"
              className="d-block mb-3"
            >
              <i className="ion ion-ios-arrow-forward"></i>&nbsp; Отчёты в перископ
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://docs.google.com/spreadsheets/d/10OpgYT-ImwcBHOvqWrAcVX4D10bu4MdUmMSV5h-PBtQ/edit#gid=2040958095"
              className="d-block mb-3"
            >
              <i className="ion ion-ios-arrow-forward"></i>&nbsp; Тайм слоты
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://docs.google.com/spreadsheets/d/1gdmJAj52T24zo72putsUKVu_j1lo9l5u6qiWTdM6GOQ/edit?pli=1#gid=1886725152"
              className="d-block mb-3"
            >
              <i className="ion ion-ios-arrow-forward"></i>&nbsp; Информация о составе команд и
              распределении заказов
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://docs.google.com/spreadsheets/d/1hqEnfDp67kWXGeTxhHlWDy4_Bpg-hBKJkVrvHUCzRm8/edit#gid=620580849"
              className="d-block mb-3"
            >
              <i className="ion ion-ios-arrow-forward"></i>&nbsp; Выходные наставников и курьеров
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://qleanru.atlassian.net/wiki/spaces/LABSCC/pages/1894547701/CRM-core"
              className="d-block mb-3"
            >
              <i className="ion ion-ios-arrow-forward"></i>&nbsp; Информация о работе с CRM системой
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://app.periscopedata.com/app/qlean/737794/B2C-Deep-–-Client-Product---Main-Dashboard-Of-Orders"
              className="d-block mb-3"
            >
              <i className="ion ion-ios-arrow-forward"></i>&nbsp; Отчёты по заказам
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://app.periscopedata.com/app/qlean/737789/B2C-Deep-%E2%80%93-Client-Product---Main-Dashboard-Of-Lead"
              className="d-block mb-3"
            >
              <i className="ion ion-ios-arrow-forward"></i>&nbsp; Отчёты по лидам
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://app.periscopedata.com/app/qlean/737782/B2C-Deep-%E2%80%93-Sales-%E2%80%93-Checking-Orders"
              className="d-block mb-3"
            >
              <i className="ion ion-ios-arrow-forward"></i>&nbsp; Отчёты по оформленным заказам (для
              сейлзов)
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://app.periscopedata.com/app/qlean/737739/B2C-Deep---Support---Mentor-Reports"
              className="d-block mb-3"
            >
              <i className="ion ion-ios-arrow-forward"></i>&nbsp; Отчёты наставников
            </a>
          </Col>
        </Row>
      </Card>
    </div>
  );
});

export default Dashboard;
