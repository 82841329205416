import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Card } from 'react-bootstrap';

import OrderStore from 'src/store/OrderStore';
import DynamicForm from 'src/components/Forms/DynamicForm';
import { FIELDS } from 'src/components/Forms/form.interface';
import { IOption } from 'src/components/Fields/Option/OptionField';
import { ButtonTitle } from 'src/components/ButtonTitle';
import { dictionary } from 'src/utils/dictionary';

interface IProps {
  orderId: string;
  startDate: string;
}

const OrderExecutors = observer(({ orderId, startDate }: IProps) => {
  const {
    orderExecutors = [],
    allExecutors = [],
    getExecutorsList,
    assignExecutor,
    unassignExecutor,
    isLoading,
    order,
  } = OrderStore;

  const [newExecutorRole, setNewExecutorRole] = useState<number | undefined>();
  const [newExecutor, setNewExecutor] = useState<string | undefined>();
  const [change, setChange] = useState<boolean>(false);
  const [roles, setRoles] = useState<Array<{label: string, value: number}>>([]);
  const isNewExecutorReady = Boolean(newExecutorRole !== undefined && newExecutor);

  useEffect(() => {
    if (newExecutorRole !== undefined) {
      getExecutorsList({ role: String(newExecutorRole), product: order?.serviceType });
    }
  }, [newExecutorRole]);

  useEffect(() => {
    let newRoles = dictionary.selectOptions.executorRoles.map((role) => ({
      label: role.plural,
      value: role.id,
    }));

    // если есть отвественный, то нужно удалить из списка доступных ролей
    if (orderExecutors && orderExecutors.find(item => item.role === 0)) {
      newRoles = newRoles.filter(item => item.value !== 0);
    }

    setRoles(newRoles);
  }, [orderExecutors]);

  useEffect(() => {
    if (isNewExecutorReady && change) {
      assignExecutor({
        orderId,
        executorId: newExecutor,
        type: 0,
        startDate,
        role: newExecutorRole,
      });
      setNewExecutorRole(undefined);
      setNewExecutor(undefined);
      setChange(false);
    }
  }, [change, isNewExecutorReady]);

  const activeExecutors = () => {
    let selectedExecutorsId: any[] = [];

    if (orderExecutors) {
      selectedExecutorsId = orderExecutors.map(item => item.id);
    }
    return allExecutors
      .filter(item => !selectedExecutorsId.includes(item.ssoId))
      .map(item => ({ label: item.name, value: item.id }));
  };

  const form = {
    name: 'new-executor',
    rows: [
      [
        {
          type: FIELDS.SELECT,
          name: `new-executor-role`,
          label: {
            label: 'Роль',
          },
          placeholder: 'Выберите роль',
          optionValueKey: 'value',
          optionLabelKey: 'label',
          options: roles,
          initialValue: newExecutorRole,
          variant: 'dropdown',
        },
      ],
      [
        {
          type: FIELDS.SELECT,
          name: `new-executor`,
          label: {
            label: 'Исполнитель',
          },
          placeholder: 'Выберите исполнителя',
          optionValueKey: 'value',
          optionLabelKey: 'label',
          initialValue: newExecutor,
          options: activeExecutors(),
          variant: 'dropdown',
          isReadonly: !activeExecutors().length || isLoading,
        },
      ],
    ],
  };

  const onRemoveExecutor = (option: IOption) => {
    unassignExecutor({ orderId, executorId: option.value.toString() });
  };

  const chosenExecutorsForm = {
    name: 'chosen-executors',
    rows: [
      ...orderExecutors
        .sort((a, b) => a.role - b.role)
        .map((executor) => {
          const option = { label: executor.name, value: executor.id };
          return [
            {
              type: FIELDS.SELECT,
              name: `chosen-executor-${executor.id}`,
              label: {
                label:
                  dictionary.selectOptions.executorRoles.find((role) => role.id === executor.role)
                    ?.singular || 'Исполнитель',
              },
              optionValueKey: 'value',
              optionLabelKey: 'label',
              initialValue: option,
              options: [],
              variant: 'dropdown',
              isReadonly: true,
              onClearClick: () => onRemoveExecutor(option),
            },
          ];
        }),
    ],
  };

  const changedNewExecutor = (values) => {
    const role = values['new-executor-role']?.value;

    let executor: string | undefined
    if (newExecutorRole === role) {
      executor = values['new-executor']?.value;
    }

    setNewExecutorRole(role);
    setNewExecutor(executor);
  };

  if (!allExecutors) {
    return null
  }

  return (
    <>
      <Card className="mb-2">
        <Card.Header as="h6">Назначенные исполнители</Card.Header>
        <div className="m-4">
          <DynamicForm isAutoSubmit onSubmit={() => {}} form={chosenExecutorsForm} />
        </div>
      </Card>
      <Card>
        <Card.Header as="h6">Назначить исполнителя</Card.Header>
        <div className="m-4">
          <DynamicForm
            key={orderExecutors.length}
            isAutoSubmit
            onSubmit={changedNewExecutor}
            timeout={0}
            form={form}
          />
          <Button
            className="w-100"
            disabled={isLoading}
            onClick={() => setChange(true)}
            variant="success"
          >
            <ButtonTitle isLoading={isLoading} title="Добавить" />
          </Button>
        </div>
      </Card>
    </>
  )
});

export default OrderExecutors;
