import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router';
import { Col, Row, Spinner } from 'react-bootstrap';

import CustomerStore from 'src/store/CustomerStore';
import PersonInfo from '../Person/PersonInfo';
import PersonHistory from '../Person/PersonHistory';

interface IProps {
  setTitle: () => void;
}

interface IRouteParams {
  customerId: string;
}

const Customer = observer((props: IProps) => {
  const { getCustomerInfo, customer } = CustomerStore;
  const { customerId } = useParams<IRouteParams>();
  const { setTitle } = props;

  useEffect(() => {
    setTitle();
  }, []);

  useEffect(() => {
    if (customerId) {
      getCustomerInfo({ uid: customerId });
    }
  }, [customerId]);

  return (
    <div className="pl-4 pr-4 container-p-y">
      {customer && customer.id === customerId ? (
        <Row>
          <Col sm={8}>
            <PersonInfo person={customer} />
          </Col>
          <Col sm={4}>
            <PersonHistory person={customer} />
          </Col>
        </Row>
      ) : (
        <Spinner animation="border" variant="success" />
      )}
    </div>
  );
});

export default Customer;
