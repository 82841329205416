import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { logger } from '@qlean/front-logger';
import CommunicationStore from 'src/store/CommunicationStore';

const CHECK_TIMEOUT = 60000;

const TaskPinger = observer(() => {
  const { checkTask } = CommunicationStore;
  useEffect(() => {
    check();
  }, []);

  const check = async () => {
    const isActive = !document.hidden;
    if (isActive) {
      const res = await checkTask();
      if (res) {
        setTimeout(check, CHECK_TIMEOUT);
      }
    } else {
      logger.debug('tab_inactive_ignore_check')
      setTimeout(check, CHECK_TIMEOUT);
    }
  };
  return null;
});

export default TaskPinger;
