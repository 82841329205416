import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import formatString from 'format-string-by-pattern';
import { AsyncTypeahead, Menu, MenuItem } from 'react-bootstrap-typeahead';
import { dictionary } from '../../utils/dictionary';

import CustomerStore, { SearchPersonDto, FioDto } from 'src/store/CustomerStore';

import { CreateProspectModal } from 'src/components/CreateProspectModal';
import { ReactComponent as SearchIcon } from './searchIcon.svg';
import { ReactComponent as ClearIcon } from './clearIcon.svg';
import { formatPhone, getFullName } from 'src/utils/formatters';

interface IProps {
  placeholder?: string;
  onChoose: (customer?: ISearchResult) => void;
  showAddCommunication?: boolean;
}

export enum SearchResultType {
  PROSPECT,
  PERSON
}

export interface ISearchResult {
  type: SearchResultType,
  fio: FioDto,
  id?: string,
  phone: string,
  title?: string,
}

export const SearchCustomer = (props: IProps) => {
  const { placeholder, onChoose, showAddCommunication } = props;
  const [foundedCustomers, setFoundedCustomers] = useState<ISearchResult[]>();
  const [chosenCustomer, setChosenCustomer] = useState<ISearchResult | undefined>();
  const [value, setValue] = useState<string>('');
  const [isFocused, setIsFocused] = useState(false);
  const [showCreateProspectModal, setShowCreateProspectModal] = useState(false);
  const mask = '(999) 999-99-99';

  const onSearch = async (query: string) => {
    const newValue = parser(query);
    setValue(newValue);
    if (newValue.length > 3) {
      const { searchCustomer } = CustomerStore;
      const { data = [], prospects = []} = await searchCustomer({
        page: 1,
        limit: 0,
        where: [{ phone: { like: newValue } }],
      });


      const result = data.map(item => ({
        id: item.id,
        fio: item.fio,
        phone: item.phone,
        type: SearchResultType.PERSON,
      })).concat(prospects.filter(item => !!item.taskId).map(item => ({
        id: item.taskId ?? item.id,
        fio: item.fio,
        phone: item.phone,
        type: SearchResultType.PROSPECT,
        title: dictionary.selectOptions.serviceTypes.find(opts => opts.id === item.serviceType)?.value ?? item.serviceType,
      })))

      setFoundedCustomers(result);
    }
  };

  const parser = (value?: string, mask?: string): string => {
    const country = '+7';
    if (typeof value === 'string') {
      const countryRegExp = new RegExp('^\\' + country.replace('+', '+?(') + '|8)|([^\\d])', 'g');
      value = value.replace(countryRegExp, '');
    }
    return mask ? `+7 ${formatString(mask)(value)}` : `7${value}` || '';
  };

  const onBlur = () => {
    setIsFocused(false);
  };

  return (
    <div className="d-flex align-items-center flex-grow-1">
      <SearchIcon className="mr-1" width="20px" height="20px" />
      <AsyncTypeahead
        id="search-customer-value"
        options={foundedCustomers}
        hidden={true}
        placeholder={isFocused ? `+7 ${mask}` : placeholder}
        align="justify"
        delay={300}
        value={chosenCustomer}
        useCache={false}
        isLoading={false}
        labelKey={(option: SearchPersonDto) => formatPhone(option.phone)}
        filterBy={(option: SearchPersonDto) => option}
        onSearch={onSearch}
        onFocus={() => setIsFocused(true)}
        onBlur={onBlur}
        onChange={(query: ISearchResult[]) => {
          if (query[0] && query[0].phone) {
            onChoose(query[0]);
            setChosenCustomer(query[0]);
          } else {
            onChoose(undefined);
            setChosenCustomer(undefined);
          }
        }}
        className="no-border mr-1 flex-grow-1"
        promptText="Поиск..."
        renderInput={({ inputRef, referenceElementRef, ...inputProps }) => {
          return (
            <input
              {...inputProps}
              style={{ width: '250px' }}
              value={inputProps.value ? parser(inputProps.value, mask) : ''}
              ref={(input) => {
                inputRef(input);
                referenceElementRef(input);
              }}
            />
          );
        }}
        renderMenu={(results: ISearchResult[], menuProps) => {
          return (
            <Menu {...menuProps} style={{ width: 'auto' }}>
              {results.length === 0 && (
                <MenuItem
                  option={{ phone: '' }}
                  className="d-flex align-items-center"
                  style={{ cursor: 'default' }}
                >
                  <span>Нет совпадений</span>
                  {showAddCommunication && (
                    <Button
                      className="text-success"
                      variant="link"
                      onClick={() => setShowCreateProspectModal(true)}
                    >
                      Создать заявку
                    </Button>
                  )}
                </MenuItem>
              )}
              {results.map((result: ISearchResult, index) => (
                <MenuItem key={result.id} option={result} position={index}>
                  <div>{formatPhone(result.phone)}</div>
                  {result.type === SearchResultType.PROSPECT && (
                    <div className="text-muted" style={{ fontSize: '12px' }}>
                      Заявка: {result.title}
                    </div>
                  )}
                  <div className="text-muted" style={{ fontSize: '12px' }}>
                    {result?.fio?.fullName
                      ? result?.fio?.fullName
                      : getFullName(
                          result?.fio?.firstName,
                          result?.fio?.lastName,
                          result?.fio?.middleName
                        )}
                  </div>
                </MenuItem>
              ))}
            </Menu>
          );
        }}
      >
        {(state) => (
          <>
            {state.text && (
              <ClearIcon
                className="flex-grow-0"
                onClick={state.onClear}
                width="16px"
                height="16px"
              />
            )}
          </>
        )}
      </AsyncTypeahead>
      <CreateProspectModal
        isShowModal={showCreateProspectModal}
        closeModal={() => setShowCreateProspectModal(false)}
        phoneNumber={value}
      />
    </div>
  );
};
