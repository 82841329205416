import React from 'react';

import { Card, ListGroup } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { CustomerCallDto } from 'src/store/CustomerStore';
import { parseDate } from 'src/utils/formatters';

interface IProps {
  call: CustomerCallDto;
  isLast?: boolean;
}

const CallInHistory = observer(({ call, isLast }: IProps) => {
  return (
    <Card
      style={{ borderRadius: 0, borderWidth: '8px' }}
      className={`border-top-0 border-right-0 border-left-0 ${isLast ? 'border-bottom-0' : ''}`}
    >
      <ListGroup variant="flush">
        {call.callTypes && (
          <ListGroup.Item key="callTypes">
            <div className="text-muted small">Метка</div>
            <div className="d-flex flex-wrap">{call.callTypes}</div>
          </ListGroup.Item>
        )}
        {call.comment && (
          <ListGroup.Item key="comment">
            <div className="text-muted small">Комментарий</div>
            <div className="d-flex flex-wrap">{call.comment}</div>
          </ListGroup.Item>
        )}
        {(call.fullName || call.calledAt) && (
          <ListGroup.Item className="d-flex justify-content-between" key="comment">
            {call.fullName && (
              <div>
                <div className="text-muted small">Оператор</div>
                <div className="d-flex flex-wrap">{call.fullName}</div>
              </div>
            )}
            {call.calledAt && (
              <div>
                <div className="text-muted small">Дата</div>
                <div className="d-flex flex-wrap">{parseDate(call.calledAt, 'dateInTime')}</div>
              </div>
            )}
          </ListGroup.Item>
        )}
      </ListGroup>
    </Card>
  );
});

export default CallInHistory;
