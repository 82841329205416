import React from 'react';
import { observer } from 'mobx-react';

import { Badge } from 'react-bootstrap';

import 'src/vendor/styles/pages/users.scss';

interface Props {
  title?: string;
  link: string;
  onClick: (link: string) => void;
  disabled?: boolean;
  className?: string;
}

@observer
export class BadgeLink extends React.Component<Props> {
  private onClick = () => {
    const { link, onClick, disabled } = this.props;

    if (!disabled) {
      onClick(link);
    }
  };

  render() {
    const { title, disabled, className } = this.props;

    return (
      <Badge
        onClick={this.onClick}
        variant="info"
        className={className}
        style={{ cursor: disabled ? 'normal' : 'pointer', whiteSpace: 'normal' }}
      >
        {title || ''}
      </Badge>
    );
  }
}
