import React from 'react';
import * as Sentry from '@sentry/react';
import { Redirect, Route } from 'react-router-dom';
import { observer } from 'mobx-react';

import LoginStore from 'src/store/LoginStore';
import { DefaultLayout, routes, titleTemplate } from 'src/routes';
import { ErrorFallback } from '../../components/ErrorFallback/ErrorFallback';

@observer
export default class PrivateRoute extends Route {
  private fillingRoutes = () => {
    const { accessToken } = LoginStore;

    return routes(Boolean(accessToken)).map((route) => {
      route.layout = route.layout || DefaultLayout;
      route.exact = typeof route.exact === 'undefined' ? true : route.exact;
      return route;
    });
  };

  render() {
    const { accessToken } = LoginStore;

    if (accessToken) {
      return this.fillingRoutes().map((route) => (
        <Route
          path={route.path}
          exact={route.exact}
          render={(props) => (
            <Sentry.ErrorBoundary fallback={ErrorFallback}>
              <route.layout {...props}>
                <route.pureComponent setTitle={() => (document.title = titleTemplate.replace('%s', route.title ? route.title : 'Page'))} {...props} />
              </route.layout>
            </Sentry.ErrorBoundary>
          )}
          key={route.path}
        />
      ));
    }

    return <Redirect to={{ pathname: '/login', state: { from: this.props.location?.pathname } }} />;
  }
}
