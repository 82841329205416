import React from 'react';

import ReactQuill from 'react-quill';
import { Form } from 'react-bootstrap';
import { Field } from 'react-final-form';
import { FieldLabel } from 'src/components/Fields/FieldLabel';
import { FieldError } from 'src/components/Fields/FieldError';

import { TFieldProp } from 'src/components/Fields/field.interface';

import 'src/vendor/libs/react-quill/typography.scss';
import 'src/vendor/libs/react-quill/editor.scss';

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline'],
    [{ color: [] }, { background: [] }],
    [{ script: 'sub' }, { script: 'super' }],
    [{ header: 1 }, { header: 2 }],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    [{ align: [] }],
    ['clean'],
  ],
};

export class HTMLField extends React.Component<TFieldProp<string>> {
  static defaultProps: Partial<TFieldProp<string>> = {
    isReadonly: false,
    isRequired: false,
  };

  render() {
    const { name, placeholder, initialValue, isRequired, isReadonly, label } = this.props;

    return (
      <Field
        name={name}
        initialValue={initialValue}
        render={({ input: { value, onChange }, meta }) => (
          <Form.Group controlId={name}>
            <FieldLabel {...label} isRequired={isRequired} />
            <ReactQuill
              modules={modules}
              value={value}
              onChange={onChange}
              placeholder={placeholder}
              readOnly={isReadonly}
            />
            <FieldError error={meta.error} />
          </Form.Group>
        )}
      />
    );
  }
}
