import SidenavComponent from './SidenavComponent'
import SidenavBlock from './SidenavBlock'
import SidenavDivider from './SidenavDivider'
import SidenavHeader from './SidenavHeader'
import SidenavMenu from './SidenavMenu'
import SidenavLink from './SidenavLink'
import SidenavRouterLink from './SidenavRouterLink'

export const Block = SidenavBlock;
export const Divider = SidenavDivider;
export const Header = SidenavHeader;
export const Menu = SidenavMenu;
export const Link = SidenavLink;
export const RouterLink = SidenavRouterLink;
export const Sidenav = SidenavComponent;

export default SidenavComponent
