import React from 'react';
import { Nav } from 'react-bootstrap';

import TaskNotifier from 'src/features/Communication/TaskNotifier';

const Footer = () => {
  return (
    <Nav
      style={{ top: 'auto', padding: 0 }}
      className={'layout-navbar navbar fixed-bottom bg-footer-theme shadow-lg'}
    >
      <TaskNotifier />
    </Nav>
  );
};

export default Footer;
