import { FIELDS } from './form.interface';
import { StringField } from '../Fields/String/StringField';
import { BooleanField } from '../Fields/Boolean/BooleanField';
import { PasswordField } from '../Fields/Password/PasswordField';
import { MaskedField } from '../Fields/Masked/MaskedField';
import { AddressField } from '../Fields/Address/AddressField';
import { MobilePhoneField } from '../Fields/MobilePhone/MobilePhoneField';
import { NumberField } from '../Fields/Number/NumberField';
import { MoneyField } from '../Fields/Money/MoneyField';
import { SelectField } from '../Fields/Select/SelectField';
import { SuggestField } from '../Fields/Suggest/SuggestField';
import { MultiSelectField } from '../Fields/MultiSelect/MultiSelectField';
import { MultiSuggestField } from '../Fields/MultiSuggest/MultiSuggestField';
import { TableField } from '../Fields/Table/TableField';
import { DataTimeField } from '../Fields/DataTime/DataTimeField';
import { TimeSlotField } from '../Fields/TimeSlot/TimeSlotField';
import { HTMLField } from '../Fields/HTML/HTMLField';
import { ColorField } from '../Fields/Color/ColorField';
import { PaymentTypeField } from '../Fields/PaymentType/PaymentTypeField';
import { OptionField } from '../Fields/Option/OptionField';
import { FioField } from '../Fields/Fio/FioField';
import { BadgeField } from '../Fields/Badge/BadgeField';
import { DiscountWithPromocodeField } from '../Fields/DiscountWithPromocode/DiscountWithPromocodeField';
import { DrycleaningSlots } from '../Fields/DrycleaningSlots';
import {NumberOfPayments} from '../Fields/NumberOfPayments/NumberOfPayments';

export const FIELD_COMPONENTS = {
  [FIELDS.FIO]: FioField,
  [FIELDS.STRING]: StringField,
  [FIELDS.MASKED]: MaskedField,
  [FIELDS.MOBILE_PHONE]: MobilePhoneField,
  [FIELDS.PASSWORD]: PasswordField,
  [FIELDS.ADDRESS]: AddressField,
  [FIELDS.BOOLEAN]: BooleanField,
  [FIELDS.NUMBER]: NumberField,
  [FIELDS.MONEY]: MoneyField,
  [FIELDS.SELECT]: SelectField,
  [FIELDS.SUGGEST]: SuggestField,
  [FIELDS.MULTI_SELECT]: MultiSelectField,
  [FIELDS.MULTI_SUGGEST]: MultiSuggestField,
  [FIELDS.TABLE]: TableField,
  [FIELDS.DATATIME]: DataTimeField,
  [FIELDS.HTML]: HTMLField,
  [FIELDS.COLOR]: ColorField,
  [FIELDS.TIMESLOT]: TimeSlotField,
  [FIELDS.PAYMENT_TYPE]: PaymentTypeField,
  [FIELDS.NUMBER_OF_PAYMENTS]: NumberOfPayments,
  [FIELDS.OPTION]: OptionField,
  [FIELDS.BADGE]: BadgeField,
  [FIELDS.DISCOUNT_WITH_PROMOCODE]: DiscountWithPromocodeField,
  [FIELDS.DRYCLEANING_SLOTS]: DrycleaningSlots,
};
