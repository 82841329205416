import React from 'react';
import { Modal } from 'react-bootstrap';

import DynamicForm from 'src/components/Forms/DynamicForm';
import { FIELDS } from 'src/components/Forms/form.interface';

interface IProps {
  isShowModal: boolean;
  closeModal: () => void;
  onSubmit: (values: object) => void;
  initialDate?: Date;
  isLoading?: boolean;
  comment?: string;
}

const CallbackModal = ({ isShowModal, closeModal, onSubmit, initialDate, isLoading, comment = '' }: IProps) => {
  const dynamicForm = {
    name: 'prospect-edit',
    rows: [
      [
        {
          type: FIELDS.DATATIME,
          name: 'datetime',
          label: {
            label: 'Дата / время',
          },
          placeholder: 'Введите дату и время для перезвона',
          initialValue: initialDate,
        },
      ],
      [
        {
          type: FIELDS.STRING,
          name: 'commentForCall',
          label: {
            label: 'Комментарий к звонку',
          },
          placeholder: 'Введите комментарий к звонку',
          initialValue: comment,
          variant: 'textarea',
        },
      ],
    ],
  };
  return (
    <Modal show={isShowModal} size="sm" onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title as="h5">Перезвон</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DynamicForm
          form={dynamicForm}
          onCancel={closeModal}
          onSubmit={onSubmit}
          submitText="Перезвонить"
          isLoading={isLoading}
        />
      </Modal.Body>
    </Modal>
  );
};

export default CallbackModal;
