import { Type } from 'class-transformer';
import { PlatformCRMWeb } from 'src/services/GrpcService';

import { ManagerSkillDto } from '../ManagerStore/ManagerStore.dto';

export class CheckResponseDto implements PlatformCRMWeb.CheckCommunicationResponse {
  activeCommunicationId!: string;
  hasAvailableTask!: boolean;
}

export class SkillDto implements PlatformCRMWeb.ISkill {
  id!: string;
  name!: string;
  priority!: number;
  createdAt!: string;
  updatedAt!: string;
  isDeleted!: boolean;
  deletedAt!: string;
}

export class LinkedTaskEntityDto implements PlatformCRMWeb.ILinkedTaskEntity {
  entityId!: string;
  entityType!: PlatformCRMWeb.EntityType;
}

export class ReasonDto implements PlatformCRMWeb.IReason {
  id!: string;
  createdAt!: string;
  updatedAt!: string;
  title!: string;
  category!: string;
}

export class TaskDto implements PlatformCRMWeb.ITask {
  id!: string;
  title!: string;
  clientId!: string;
  state!: PlatformCRMWeb.TaskState;
  comment?: string;
  postponedTo?: string;
  ownerId?: string;
  regionId?: string;
  strategyId!: string;
  createdAt!: string;
  updatedAt!: string;
  isDeleted!: boolean;
  @Type(() => SkillDto)
  skill!: SkillDto;
  @Type(() => LinkedTaskEntityDto)
  entity?: LinkedTaskEntityDto;
  deletedAt?: string;
  @Type(() => ReasonDto)
  cancellationReason?: ReasonDto;
  lastMissedCallDate?: string;
}

export class TaskListItemDto implements PlatformCRMWeb.ITaskListItem {
  order!: number;
  @Type(() => TaskDto)
  task!: TaskDto;
}

export class CommunicationManagerDto implements PlatformCRMWeb.Communication.IManager {
  id!: string;
  name!: string;
  ssoUid!: string;
  state!: PlatformCRMWeb.ManagerState;
  @Type(() => ManagerSkillDto)
  skills!: ManagerSkillDto[];
}

export class CommunicationDto implements PlatformCRMWeb.ICommunication {
  id!: string;
  managerSsoId!: string;
  clientId!: string;
  channel!: PlatformCRMWeb.CommunicationChannel;
  method!: PlatformCRMWeb.CommunicationMethod;
  type!: PlatformCRMWeb.CommunicationType;
  process!: PlatformCRMWeb.CommunicationProcess;
  state!: PlatformCRMWeb.CommunicationState;
  @Type(() => TaskListItemDto)
  taskList!: TaskListItemDto[];
  chainPrototypeId?: string;
  resolvedAt?: string;
  @Type(() => ReasonDto)
  failureReason?: ReasonDto;
  updatedAt!: string;
  createdAt!: string;
  registrationNumber!: number;
  @Type(() => CommunicationManagerDto)
  owner?: CommunicationManagerDto;
  missCallsCount?: number;
}

export class FindClientCommunicationsRequestDto implements PlatformCRMWeb.IFindCommunicationsRequest {
  clientId!: string;
}

export class FindCommunicationsResponseDto implements PlatformCRMWeb.IFindCommunicationsResponse {
  @Type(() => CommunicationDto)
  data!: CommunicationDto[];

  hasNext!: boolean;
}

export class GetManyReasonsResponseDto implements PlatformCRMWeb.IGetManyReasonsResponse {
  @Type(() => ReasonDto)
  data!: ReasonDto[];
}

export class ProductReasonDto implements PlatformCRMWeb.IProductReason {
  id!: string;
  createdAt!: string;
  updatedAt!: string;
  key!: string;
  @Type(() => ReasonDto)
  cancellationReasons!: ReasonDto[];
}

export class GetManyProductReasonsResponseDto implements PlatformCRMWeb.IGetManyProductReasonsResponse {
  @Type(() => ProductReasonDto)
  data!: ProductReasonDto[];
}

export interface ITaskCompletedResponse {
  isOk: boolean;
  newTaskId?: string;
  paymentStatus?: string | null;
  orderId?: string;
}
