import React from 'react';

import { Form } from 'react-bootstrap';
import { Field } from 'react-final-form';
import AsyncSuggest from 'react-select/async';
import { FieldLabel } from 'src/components/Fields/FieldLabel';
import { FieldError } from 'src/components/Fields/FieldError';

import { TFieldProp } from 'src/components/Fields/field.interface';
import { ValueType, components } from 'react-select';

interface AnyObject {
  [key: string]: any;
}

export interface ISuggestFieldProp {
  optionValueKey: Partial<keyof AnyObject>;
  optionLabelKey: Partial<keyof AnyObject>;
  search?: (searchValue: string) => Promise<AnyObject[]>;
  delay?: number;
  customOption?: (label, option) => JSX.Element;
}

export type TSuggestFieldProp = ISuggestFieldProp & TFieldProp<AnyObject>;

export class SuggestField extends React.Component<TSuggestFieldProp> {
  static defaultProps: Partial<TSuggestFieldProp> = {
    isReadonly: false,
    isRequired: false,
  };

  private onSelect = (
    onChange: (arg?: Partial<AnyObject>) => void,
    option: ValueType<AnyObject>
  ) => {
    onChange(option ? option : undefined);
  };

  private loadOptions = (value: string) => {
    const { search } = this.props;
    /**
     * TODO: добавить обработку `delay` из-вне
     */
    if (search) {
      return search(value);
    }

    return new Promise((resolve) => resolve());
  };

  private componentOption = (props) => {
    const { customOption } = this.props;
    const { data, children, ...propsOption } = props;

    return (
      <components.Option {...propsOption}>
        {customOption ? customOption(children, data) : children}
      </components.Option>
    );
  };

  render() {
    const {
      name,
      isRequired,
      isReadonly,
      label,
      initialValue,
      placeholder = 'Выбор',
      optionValueKey,
      optionLabelKey,
    } = this.props;

    return (
      <Field
        name={name}
        type="radio"
        initialValue={initialValue}
        render={({ input: { onChange, ...inputRender }, meta }) => (
          <Form.Group controlId={name}>
            <FieldLabel {...label} isRequired={isRequired} />
            <Form.Control
              hidden
              readOnly={true}
              value={initialValue?.name}
              isInvalid={FieldError.isInvalid(meta)}
            />
            <AsyncSuggest
              {...inputRender}
              defaultOptions
              isDisabled={isReadonly}
              placeholder={placeholder}
              getOptionLabel={(option) => option[optionLabelKey]}
              getOptionValue={(option) => option[optionValueKey]}
              defaultValue={initialValue}
              loadOptions={this.loadOptions}
              loadingMessage={() => 'Загрузка...'}
              noOptionsMessage={() => 'Список пуст'}
              isClearable={true}
              onChange={(selectedOption) => this.onSelect(onChange, selectedOption)}
              components={{ Option: this.componentOption }}
            />

            <FieldError error={meta.error} />
          </Form.Group>
        )}
      />
    );
  }
}
