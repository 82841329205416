import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import CommunicationStore from 'src/store/CommunicationStore';

type IProps = {
  buttonText?: string;
  onClick?: VoidFunction;
  children?: string;
  isLoading?: boolean;
  isError?: boolean;
};

const TaskModal: React.FC<IProps> = (props) => {
  const { buttonText, onClick, children, isLoading, isError } = props;
  const { checkTask } = CommunicationStore;

  return (
    <div className="container-fluid d-flex flex-wrap justify-content-between text-center container-p-x pb-3">
      {isLoading ? (
        <>
          <div className="pt-3">
            <span className="footer-text font-weight-bold">Загрузка...</span>
          </div>
          <div className="pt-3">
            <Spinner animation="border" />
          </div>
        </>
      ) : (
        <>
          {isError ? (
            <>
              <div className="pt-3">
                <span className="footer-text font-weight-bold">
                  Произошла ошибка при проверке доступных задач
                </span>
              </div>
              <div className="pt-3">
                <Button onClick={checkTask}>Попробовать ещё</Button>
              </div>
            </>
          ) : (
            <>
              {children && (
                <div className="pt-3">
                  <span className="footer-text font-weight-bold">{children}</span>
                </div>
              )}
              {buttonText && (
                <div className="pt-3">
                  <Button onClick={onClick}>{buttonText}</Button>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default TaskModal;
