import _ from 'lodash';
import { PriceElementDto, PriceElementOptionDto } from '../../store/MetaStore';
import { ICalculatorCard, ICalculatorValue } from './calcultor.interfaces';
import { FIELDS } from 'src/components/Forms/form.interface';
import { PlatformCRMWeb } from 'src/services/GrpcService';
import { DisplayType, TOptionFieldProp, IOption } from 'src/components/Fields/Option/OptionField';
import { logger } from '@qlean/front-logger';
import { AddressDto } from 'src/store/CustomerStore';
import { calculatorCardsSort } from '../../utils/dictionary';

const BASIC_TITLE = 'Базовый';

function createOption(
  option: PriceElementOptionDto,
  value: PlatformCRMWeb.ICalculateOption = {},
  isReadonly?: boolean,
  values?: { [key: string]: ICalculatorValue },
): TOptionFieldProp {
  let options: IOption[] = [];
  if (option.display === 'list') {
    if (option?.costMatrix?.items?.length === 1 && option?.costMatrix?.items?.[0].range) {
      const range = option?.costMatrix?.items?.[0].range;
      const indexOfHyphen = range.indexOf('-');
      const from = +range.substr(0, indexOfHyphen);
      const to = +range.substr(indexOfHyphen + 1, range.length);
      for (let i = from; i <= to; i++) {
        options.push({
          label: i.toString(),
          value: i.toString(),
        });
      }
    } else {
      for (const [index, item] of option?.costMatrix?.items?.entries()) {
        const lastIndex = _.findLastIndex(option?.costMatrix?.items, (i) => i.range === item.range);
        if (lastIndex === index) {
          options.push({
            label: item.range.toString(),
            value: item.range.toString(),
          });
        } else {
          logger.warn(
            `[Calculator] createOption Dublicate costMatrixItem: ${JSON.stringify(item)}`
          );
        }
      }
    }
  }

  options = options?.filter(
    (opt, index, self) => index === self.findIndex((o) => o.value === opt.value.toString())
  );

  let formData: ICalculatorValue | null = null;

  if (values && values[option.slug]) {
    formData = values[option.slug];
  }

  const defaultValue = {
    value: 0,
    isFree: Boolean(value.isFree),
    factor: formData?.factor || value.factor || option.defaultFactor,
  };

  if (formData) {
    defaultValue.value = formData.value;
  } else if (value && option.slug === value.slug) {
    defaultValue.value = value.value!;
  }

  return {
    type: FIELDS.OPTION,
    name: option.slug,
    display: DisplayType[option?.display?.toUpperCase()] || DisplayType.NUMBER,
    label: {
      label: option.slug === 'executors' ? `${option.title} (часы | исполнители)` : option.title,
    },
    totalDuration: value.totalDuration || 0,
    totalPrice: value.totalPrice || 0,
    defaultValue,
    placeholder: 'Кол-во',
    options,
    withFactor: Boolean(option.defaultFactor),
    isReadonly,
  };
}

function getAddressField(slug: string, regionId: number, keysDelivery?: AddressDto, keysPickup?: AddressDto, isActiveOrder?: boolean) {
  const initialValue = {
    value: slug === 'keys_pickup' ? { address: keysPickup, regionId } : { address: keysDelivery, regionId },
    isShortForm: false,
    isActiveOrder,
  };
  return {
    type: FIELDS.ADDRESS,
    name: `${slug}_address`,
    label: {
      label: 'Адрес',
    },
    placeholder: 'Введите адрес',
    initialValue,
  };
}

export function priceToCards(
  element: PriceElementDto,
  product: PlatformCRMWeb.ICalculateProduct = {},
  regionId: number,
  keysDelivery?: AddressDto,
  keysPickup?: AddressDto,
  isLoading?: boolean,
  isReadonly?: boolean,
  isActiveOrder?: boolean,
  values?: { [key: string]: ICalculatorValue },
): ICalculatorCard[] {
  const cards: { [key: string]: ICalculatorCard } = {};

  const basicSlugs = element.options
    .filter((option) => option.tags.length === 0)
    .map((option) => option.slug);
  const slugsWithValue = product?.options
    ?.filter((option) => typeof option.value === 'number')
    .map((option) => option.slug);

  const basicSlugsWithValue = basicSlugs.filter((slug) => slugsWithValue?.includes(slug));

  for (const [index, option] of element.options.entries()) {
    let isDisabled =
      (basicSlugsWithValue.length > 0 &&
        option.tags.length === 0 &&
        !basicSlugsWithValue.includes(option.slug)) ||
      isReadonly;
    const lastIndex = _.findLastIndex(element.options, (o) => o.slug === option.slug);
    if (lastIndex !== index) {
      logger.warn(`[Calculator] priceToCards Dublicate slug in priceElement = ${option.slug}`);
      continue;
    }

    for (const title of option.tags.length ? option.tags : [BASIC_TITLE]) {
      const card = findCardByTag(cards, title);
      const value = product.options?.find((item) => item.slug === option.slug);
      if (option.display === DisplayType.READONLY && (!value?.value || value?.value === option.defaultValue)) {
        continue;
      } else if (option.display === DisplayType.READONLY) {
        option.display = DisplayType.NUMBER;
        isDisabled = true;
      }

      if (!card) {
        cards[title] = {
          isMain: title === BASIC_TITLE,
          key: `${product.productSlug}-${title}`,
          form: {
            name: title,
            rows: [[createOption(option, value, isDisabled, values)]],
          },
          quantityOfSlugsWithValue: value?.value ? 1 : 0,
        };
        if (['keys_pickup', 'keys_delivery'].includes(option.slug) && value?.value) {
          cards[title].form.rows?.push([
            getAddressField(option.slug, regionId, keysDelivery, keysPickup, isActiveOrder),
          ]);
        }
      } else {
        if (value?.value) {
          card.quantityOfSlugsWithValue = ++card.quantityOfSlugsWithValue;
        }
        card.form.rows?.push([createOption(option, value, isDisabled, values)]);
        if (['keys_pickup', 'keys_delivery'].includes(option.slug) && value?.value) {
          card.form.rows?.push([getAddressField(option.slug, regionId, keysDelivery, keysPickup, isActiveOrder)]);
        }
      }
    }
  }

  let keys = Object.keys(cards);
  const slug = product.productSlug || '';

  if (slug && calculatorCardsSort[slug]) {
    const sortDict = calculatorCardsSort[slug] || {};

    keys = keys.sort((a, b) => {
      const aIndex = sortDict[a];
      const bIndex = sortDict[b];

      if (aIndex === bIndex) {
        return 0;
      }

      if (aIndex < bIndex) {
        return -1;
      }

      return 1;
    })
  }

  logger.debug('[Calculator] priceToCards', cards, keys, slug);
  return keys.map((key) => cards[key]);
}

function findCardByTag(cards: Record<string, ICalculatorCard>, tag: string) {
  const entry = Object.entries(cards).find(([key]) => {
    const keyWithSameWhiteSpaces = key.replace(/\s+/g, ' ').trim();
    const tagWithSameWhiteSpaces = tag.replace(/\s+/g, ' ').trim();

    return keyWithSameWhiteSpaces === tagWithSameWhiteSpaces;
  });

  return entry ? entry[1] : null;
}
