import React, { FC, Fragment, useEffect, useState } from 'react';
import { ManagerDto } from '../store/ManagerStore';
import { Button, DropdownButton, Nav } from 'react-bootstrap';
import { PlatformCRMWeb } from '../services/GrpcService';
import { CommunicationDto, ITaskCompletedResponse, ReasonDto, TaskDto } from '../store/CommunicationStore';
import { ButtonTitle } from '../components/ButtonTitle';
import { DropdownList, IDropdownListOption } from '../components/DropdownList';
import { dictionary } from '../utils/dictionary';
import CallbackModal from '../features/Prospect/ProspectSummary/CallbackModal';
import ProspectStore from 'src/store/ProspectStore';
import CommunicationStore from 'src/store/CommunicationStore';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import DialOutModal from '../features/Prospect/ProspectSummary/DialOutModal';
import { observer } from 'mobx-react';
import { REASONS_CATEGORIES } from '../constants';

interface ITicketSummaryProps {
  manager: ManagerDto;
  communication: CommunicationDto;
  task: TaskDto;
  isLoading: boolean;
}

export const TicketSummary: FC<ITicketSummaryProps> = observer(({ manager, communication, isLoading, task }) => {
  const canBeTaken =
    [PlatformCRMWeb.CommunicationState.POSTPONED, PlatformCRMWeb.CommunicationState.RESCHEDULED, PlatformCRMWeb.CommunicationState.CLOSED].includes(
      communication.state
    ) &&
    communication.taskList.some(({ task: taskItem }) =>
      [PlatformCRMWeb.TaskState.POSTPONED, PlatformCRMWeb.TaskState.RESCHEDULED].includes(taskItem.state)
    );

  const isRTL = document.documentElement.getAttribute('dir') === 'rtl';
  const history = useHistory();
  const { setErrors } = ProspectStore;
  const {
    reschedule,
    isActionProcessing,
    productReasons,
    cancelTask,
    postpone,
    failReasons,
    fail,
    getManyReasons,
    applyCommunication,
    proceedTicketTask,
  } = CommunicationStore;

  const [callbackDate, setCallbackDate] = useState<Date | undefined>(undefined);
  const [isShowCallbackModal, setIsShowCallbackModal] = useState(false);
  const [dialOutReason, setDialOutReason] = useState<PlatformCRMWeb.CancellationReason | undefined>(undefined);
  const [isShowDialOutModal, setIsShowDialOutModal] = useState(false);

  const onApply = async () => {
    proceedTicketTask({ communicationId: communication.id, taskId: task.id }).then(onTaskStatusChanged);
  };

  const onTaskCallBack = (res) => {
    if (res) {
      history.push('/');
      toast('Задача отложена', { type: 'success', autoClose: 1500 });
      setIsShowCallbackModal(false);
    }
  };

  const onTaskStatusChanged = ({ isOk, newTaskId }: ITaskCompletedResponse) => {
    if (isOk) {
      if (newTaskId) {
        history.push(`/communication/${communication.id}/${newTaskId}`);
      } else {
        toast('Задача обработана', { type: 'success', autoClose: 1500 });
        history.push('/');
      }
    }
  };

  const onTakeTask = () => {
    applyCommunication(task.id).then((newCommunication) => {
      if (newCommunication?.hasAvailableTask && newCommunication?.activeCommunicationId) {
        history.push(`/communication/${newCommunication.activeCommunicationId}`);
      }
    });
  };

  const callback = (date?: Date) => {
    setCallbackDate(date);
    setIsShowCallbackModal(true);
  };

  const onSubmitCallback = (values: any) => {
    const { datetime, commentForCall } = values;
    if (datetime && datetime.toUTCString()) {
      setErrors([]);
      reschedule({
        communicationId: communication.id,
        to: datetime.toUTCString(),
        comment: commentForCall,
      }).then(onTaskCallBack);
    }
  };

  const cancelReasonCategories = productReasons?.[0]?.cancellationReasons
    .filter((reason: ReasonDto) => reason.category !== REASONS_CATEGORIES.CLOSE_ALL_CATEGORY)
    .reduce((result: string[], reason) => {
      return result.includes(reason.category) ? result : [...result, reason.category];
    }, []);

  const cancelReasonOptions = cancelReasonCategories?.map((category) => ({
    id: category,
    value: category,
    options: productReasons?.[0].cancellationReasons
      ?.filter((reason) => reason.category === category)
      .map((reason) => ({ id: reason.id, value: reason.title })),
  }));

  const failReasonOptions: IDropdownListOption[] = failReasons?.map((reason) => ({
    id: reason.id,
    value: reason.title,
  }));

  const onRejectClick = (option: IDropdownListOption) => {
    setErrors([]);
    cancelTask({
      communicationId: communication.id,
      taskId: task.id,
      cancelReason: {
        cancelReasonId: option.id.toString(),
        productId: productReasons?.[0]?.id,
      },
    }).then(onTaskStatusChanged);
  };

  const onFailClick = (option: IDropdownListOption) => {
    setErrors([]);
    fail({ communicationId: communication.id, failureReasonId: option.id.toString() }).then(onTaskStatusChanged);
  };

  const onDialOut = (option) => {
    setDialOutReason(option.id);
    setIsShowDialOutModal(true);
  };

  const onSubmitDialOut = (values: { commentForDialOut: string }) => {
    const { commentForDialOut } = values;
    if (dialOutReason) {
      setErrors([]);
      postpone({
        communicationId: communication.id,
        comment: commentForDialOut,
        cancellationReason: dialOutReason,
      }).then(onTaskCallBack);
    }
  };

  useEffect(() => {
    getManyReasons({ categories: ['Закрыть всё'], all: false });
  }, []);

  return (
    <Fragment>
      <Nav style={{ top: 'auto', padding: 0 }} className="layout-navbar navbar fixed-bottom bg-footer-theme shadow-lg">
        <div className="container-fluid d-flex flex-wrap justify-content-between text-center container-p-x pb-3">
          <div className="mt-3 d-flex ml-auto">
            {communication.managerSsoId === manager?.ssoUid && communication.state === PlatformCRMWeb.CommunicationState.ASSIGNED && (
              <div className="pt-3 d-flex">
                <div className="px-1">
                  <Button variant="success" disabled={isLoading} onClick={onApply}>
                    <ButtonTitle isLoading={isLoading} title="Готово" />
                  </Button>
                </div>
                <div className="px-1">
                  <DropdownButton
                    id="callback"
                    variant="primary"
                    title={<ButtonTitle isLoading={isLoading} title="Перезвон" />}
                    className="d-inline-block"
                    disabled={isLoading}
                    alignRight={isRTL}
                    align="leftBottom"
                  >
                    <DropdownList
                      options={dictionary.selectOptions.callbackPresets}
                      onClick={(option) => callback(option?.getDate && option.getDate())}
                    />
                  </DropdownButton>
                </div>
                {cancelReasonOptions && (
                  <div className="px-1">
                    <DropdownButton
                      id="rejection"
                      title={<ButtonTitle isLoading={isLoading} title="Отказ" />}
                      className="d-inline-block btn-rejection"
                      disabled={isLoading}
                      alignRight={isRTL}
                    >
                      <DropdownList options={cancelReasonOptions} onClick={onRejectClick} align="leftBottom" />
                    </DropdownButton>
                  </div>
                )}
                <div className="px-1">
                  <DropdownButton
                    id="postpone"
                    variant="secondary"
                    title={<ButtonTitle isLoading={isLoading} title="Недозвон" />}
                    className="d-inline-block"
                    disabled={isLoading}
                    alignRight={isRTL}
                  >
                    <DropdownList options={dictionary.selectOptions.communicationCancellationReasons} onClick={onDialOut} align="leftBottom" />
                  </DropdownButton>
                </div>
                {failReasonOptions && (
                  <div className="px-1">
                    <DropdownButton
                      id="fail"
                      variant="danger"
                      title={<ButtonTitle isLoading={isLoading} title="Закрыть все" />}
                      className="d-inline-block"
                      disabled={isLoading}
                      alignRight={isRTL}
                    >
                      <DropdownList options={failReasonOptions} onClick={onFailClick} align="leftBottom" />
                    </DropdownButton>
                  </div>
                )}
              </div>
            )}
            {canBeTaken && (
              <div className="pt-3 d-flex">
                <div className="px-1">
                  <Button variant="success" disabled={isLoading} onClick={onTakeTask}>
                    <ButtonTitle isLoading={isLoading} title="Взять в работу" />
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
        <CallbackModal
          isShowModal={isShowCallbackModal}
          closeModal={() => setIsShowCallbackModal(false)}
          onSubmit={onSubmitCallback}
          initialDate={callbackDate}
          isLoading={isActionProcessing}
          comment={communication.taskList.find(({ task: taskItem }) => task.id === taskItem.id)?.task?.comment}
        />
        <DialOutModal
          isShowModal={isShowDialOutModal}
          closeModal={() => setIsShowDialOutModal(false)}
          onSubmit={onSubmitDialOut}
          isLoading={isActionProcessing}
          initialComment={communication.taskList.find(({ task: taskItem }) => task.id === taskItem.id)?.task?.comment}
        />
      </Nav>
    </Fragment>
  );
});
