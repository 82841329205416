import { PlatformCRMWeb } from 'src/services/GrpcService';
import { Type } from 'class-transformer';

export class FioDto implements PlatformCRMWeb.IFIO {
  fullName?: string; // Полное, правильно отформатированное ФИО возвращаемый из Dadata
  lastName?: string; // Фамилия
  middleName?: string; // Отчество
  firstName?: string; //  Имя
  isValid?: boolean; // Признка того, что ФИО разобрано или через DATA или в ручную менджером
  gender?: number;
}

export class PriceElementDto implements PlatformCRMWeb.IPriceElement {
  id!: string;
  productSlug!: string;
  @Type(() => PriceElementOptionDto)
  options!: PriceElementOptionDto[];
  cost!: number;
  duration!: number;
}

export class PriceElementOptionDto implements PlatformCRMWeb.IPriceElementOption {
  @Type(() => PriceElementDto)
  priceElement!: PriceElementDto;
  slug!: string;
  costLinear!: number;
  @Type(() => PriceElementMatrixDto)
  costMatrix!: PriceElementMatrixDto;
  durationLinear!: number;
  @Type(() => PriceElementMatrixDto)
  durationMatrix!: PriceElementMatrixDto;
  isFree!: boolean;
  defaultValue!: number;
  tags!: string[];
  title!: string;
  shortDesc!: string;
  iconName!: string;
  type!: PlatformCRMWeb.PriceElementOptionType;
  display!: 'readonly' | 'number' | 'checkbox' | 'list';
  defaultFactor!: number;
}

export class PriceElementMatrixDto implements PlatformCRMWeb.IPriceElementMatrix {
  per!: PlatformCRMWeb.MatrixUnit;
  @Type(() => PriceElementMatrixItemDto)
  items!: PriceElementMatrixItemDto[];
}

export class PriceElementMatrixItemDto implements PlatformCRMWeb.IPriceElementMatrixItem {
  range!: string;
  value!: number;
}
