import { action, observable, runInAction } from 'mobx';
import { plainToClass } from 'class-transformer';
import { logger } from '@qlean/front-logger';

import GrpcService from 'src/services/GrpcService';
import { ManagerDto } from './ManagerStore.dto';

export default class ManagerStore {
  @observable isLoading: boolean = false;
  @observable manager?: ManagerDto;

  @action getCurrentManager = () => {
    this.isLoading = true;

    GrpcService.PlatformCRMWeb.ManagerService.GetCurrentManager({})
      .then((res) => {
        const { manager, activeTask } = res;

        runInAction(() => {
          this.manager = plainToClass(ManagerDto, manager);
          this.isLoading = false;
          logger.debug(`[ManagerStore::getCurrentManager] ok`, { manager, activeTask });
        });
      })
      .catch((e) => {
        logger.error(`[ManagerStore::getCurrentManager]`, e);
        this.isLoading = false;
      });
  };

  @action changeStatusManager = () => {
    this.isLoading = true;

    GrpcService.PlatformCRMWeb.ManagerService.ChangeManagerStatus({})
      .then((res) => {
        const { manager } = res;

        runInAction(() => {
          this.manager = plainToClass(ManagerDto, manager);
          this.isLoading = false;
          logger.debug(`[ManagerStore::changeStatusManager] ok`);
        });
      })
      .catch((e) => {
        logger.error(`[ManagerStore::changeStatusManager] error`, e);
        this.isLoading = false;
      });
  };
}
