import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { Col, Row, Spinner } from 'react-bootstrap';

import OrderStore from 'src/store/OrderStore';
import ManagerStore from 'src/store/ManagerStore';
import { PlatformCRMWeb } from 'src/services/GrpcService';
import { labsServiceTypes } from 'src/utils/dictionary';
import OrderData from './OrderData';
import OrderCalculator from './OrderCalculator';
import OrderSummary from './OrderSummary';
import OrderExecutors from './OrderExecutors';
import Person from '../Person';

interface IProps {
  setTitle: () => void;
}

interface IRouteParams {
  orderId: string;
}

const Order = observer((props: IProps) => {
  const params = useParams<IRouteParams>();
  const history = useHistory();
  const { setTitle } = props;
  const { orderId } = params;
  const { getOrderById, order, updatePaymentTypeInOmsOrder, updateOrder, isOMSOrder } = OrderStore;
  const { manager } = ManagerStore;
  const person = order?.person;

  useEffect(() => {
    setTitle();
    if (manager) {
      if (manager.state === PlatformCRMWeb.ManagerState.ONLINE) {
        if (orderId) {
          getOrderById({ id: orderId });
        }
      } else {
        toast('Необходимо быть онлайн чтобы попасть на данную страницу', {
          type: 'warning',
          autoClose: 1500,
        });
        history.push('/');
      }
    }
  }, [orderId, manager]);

  const onOrderFormSubmit = (values): void => {
    if (isOMSOrder) {
      updatePaymentTypeInOmsOrder(values as any);
    } else {
      updateOrder(values);
    }
  }

  return manager && order && order.id === orderId ? (
    <div className="pl-4 pr-4 container-p-y">
      <Row className="mb-4">
        <Col>
          <OrderSummary order={order} />
        </Col>
      </Row>
      <Row>
        <Col sm={8}>
          <OrderData order={order} onOrderDataSubmit={onOrderFormSubmit}/>
          <OrderCalculator order={order} onCalculatorSubmit={onOrderFormSubmit}/>
        </Col>
        <Col sm={4}>
          {person && order ? (
            <Person person={person} />
          ) : (
            <Spinner animation="border" variant="success" />
          )}
          {labsServiceTypes.includes(order.serviceType) && (
            <OrderExecutors orderId={order.id} startDate={order.startDate} />
          )}
        </Col>
      </Row>
    </div>
  ) : (
    <Spinner animation="border" variant="success" />
  );
});

export default Order;
