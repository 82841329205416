import { action, observable, runInAction } from 'mobx';
import { plainToClass } from 'class-transformer';
import { logger } from '@qlean/front-logger';

import GrpcService, { PlatformCRMWeb } from 'src/services/GrpcService';
import {
  PersonDto,
  // CustomerTasksDto,
  CustomerOrdersDto,
  GetCustomerAddressesResponseDto,
  SsoAddressDto,
  GetBonusesAccountResponseDto,
  SearchCustomerResponseDto,
  CustomerCallDto,
  CustomerCallsDto,
} from './CustomerStore.dto';
import { OrderDto } from '../OrderStore';
import { formatPhone } from '../../utils/formatters';

export default class CustomerStore {
  @observable isLoading: boolean = false;
  @observable customer?: PersonDto;
  @observable customerOrders: { isLoading: boolean; orders: OrderDto[] } = {
    isLoading: false,
    orders: [],
  };
  @observable customerCalls: { isLoading: boolean; calls: CustomerCallDto[] } = {
    isLoading: false,
    calls: [],
  };
  @observable clientAddresses?: SsoAddressDto[];
  @observable clientIdForAddresses?: string;

  @action getCustomerOrders = (args?: PlatformCRMWeb.IGetCustomerOrdersRequest) => {
    this.customerOrders.isLoading = true;
    const req = { ...args };

    GrpcService.PlatformCRMWeb.CustomerService.GetCustomerOrders(req)
      .then((res) => {
        const { orders } = plainToClass(CustomerOrdersDto, res);
        this.customerOrders = {
          isLoading: false,
          orders,
        };
        logger.debug('[CustomerStore::getCustomerOrders] ok', { orders });
      })
      .catch((e) => {
        logger.error('[CustomerStore::getCustomerOrders]', e);
        this.customerOrders.isLoading = false;
      });
  };

  @action getCustomerCalls = (args?: PlatformCRMWeb.IGetCustomerCallsRequest) => {
    this.customerCalls.isLoading = true;
    const req = { ...args };

    GrpcService.PlatformCRMWeb.CustomerService.GetCustomerCalls(req)
      .then((res) => {
        const { calls } = plainToClass(CustomerCallsDto, res);
        this.customerCalls = {
          isLoading: false,
          calls,
        };
        logger.debug('[CustomerStore::getCustomerCalls] ok', { calls });
      })
      .catch((e) => {
        logger.error('[CustomerStore::getCustomerCalls]', e);
        this.customerCalls.isLoading = false;
      });
  };

  @action getCustomerInfo = (args?: PlatformCRMWeb.GetCustomerInfoRequest) => {
    this.isLoading = true;
    const req = { ...args };

    GrpcService.PlatformCRMWeb.CustomerService.CustomerInfo(req)
      .then((res) => {
        const { customer } = res;

        runInAction(() => {
          this.customer = plainToClass(PersonDto, customer);
          this.isLoading = false;
          logger.debug('[CustomerStore::getCustomerInfo] ok', { customer });
        });
      })
      .catch((e) => {
        logger.error('[CustomerStore::getCustomerInfo]', e);
        this.isLoading = false;
      });
  };

  @action getClientAddresses = (args?: PlatformCRMWeb.GetCustomerAddressesRequest) => {
    this.isLoading = true;
    const req = { ...args };

    GrpcService.PlatformCRMWeb.CustomerService.GetClientAddresses(req)
      .then((res) => {
        const { addresses } = plainToClass(GetCustomerAddressesResponseDto, res);
        logger.debug('[CustomerStore::getClientAddresses] ok', { res });

        runInAction(() => {
          this.clientIdForAddresses = args?.uid;
          this.clientAddresses = addresses;
          this.isLoading = false;
        });
      })
      .catch((e) => {
        logger.error('[CustomerStore::getClientAddresses]', e);
        runInAction(() => {
          this.isLoading = false;
          this.clientIdForAddresses = undefined;
        });
      });
  };

  @action updateCustomer = (args: PlatformCRMWeb.IUpdateRequest) => {
    const formattedPhone = `7${formatPhone(args.person?.phone ?? '')}`;
    const req = { ...args };

    if (req.person) {
      req.person.phone = formattedPhone;
    }

    this.isLoading = true;

    GrpcService.PlatformCRMWeb.CustomerService.Update(req)
      .then((res) => {
        const { person } = res;
        this.isLoading = false;

        logger.debug('[CustomerStore::updateCustomer] ok', { args, person });

        runInAction(() => {
          this.customer = plainToClass(PersonDto, person);
        });
      })
      .catch((e) => {
        logger.error('[CustomerStore::updateCustomer]', e);
        this.isLoading = false;
      });
  };

  @action attachCard = (args: PlatformCRMWeb.IAttachCardRequest) => {
    this.isLoading = true;
    const req = { ...args };

    GrpcService.PlatformCRMWeb.CustomerService.AttachCard(req)
      .then((res) => {
        this.isLoading = false;
        logger.debug('[CustomerStore::AttachCard] ok', { args });
      })
      .catch((e) => {
        logger.error('[CustomerStore::attachCard]', e);
        this.isLoading = false;
      });
  };

  @action getBonusesAccount = (args?: PlatformCRMWeb.IGetBonusesAccountRequest) => {
    const req = { ...args };
    logger.debug('[MetaStore::getBonusesAccount request]', args);
    return GrpcService.PlatformCRMWeb.CustomerService.GetBonusesAccount(req)
      .then((res) => {
        logger.debug('[MetaStore::getBonusesAccount response]', res);
        return plainToClass(GetBonusesAccountResponseDto, res);
      })
      .catch((e) => {
        logger.error(`[MetaStore::getBonusesAccount]`, e);
        return undefined;
      });
  };

  @action searchCustomer = (args?: PlatformCRMWeb.ISearchCustomerRequest) => {
    const req = { ...args };
    logger.debug('[MetaStore::searchCustomer request]', args);
    return GrpcService.PlatformCRMWeb.CustomerService.SearchCustomer(req)
      .then((res) => {
        logger.debug('[MetaStore::searchCustomer response]', res);
        return plainToClass(SearchCustomerResponseDto, res);
      })
      .catch((e) => {
        logger.error(`[MetaStore::searchCustomer]`, e);
        return { data: [], prospects: [] };
      });
  };
}
