import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { toast } from 'react-toastify';
import { logger } from '@qlean/front-logger';
import ReactJson from 'react-json-view';

import { PlatformCRMWeb } from 'src/services/GrpcService';

import ManagerStore from 'src/store/ManagerStore';
import CommunicationStore from 'src/store/CommunicationStore';
import ProspectStore from 'src/store/ProspectStore';
import OrderStore from 'src/store/OrderStore';
import CustomerStore, {
  GetBonusesAccountResponseDto,
  SearchPersonDto,
  PersonDto,
} from 'src/store/CustomerStore';
import MetaStore from 'src/store/MetaStore';

import DynamicForm from 'src/components/Forms/DynamicForm';
import { FIELDS } from 'src/components/Forms/form.interface';
import { Calculator } from 'src/components/Calculator/Calculator';
import PersonHistory from 'src/features/Person/PersonHistory';
import { DemoCard, bffPanels, componentsPanels } from './DemoCard';
import { SearchCustomer } from 'src/components/SearchCustomer';
import { dictionary } from 'src/utils/dictionary';
import {
  ERROR_MESSAGES,
  isValidExternalId,
  isValidRegularity,
  isValidServiceType,
  validateCreateTask,
} from 'src/utils/validation';

import { favourites } from './favourites';

interface IProps {
  setTitle: () => void;
}

const Demo = observer((props: IProps) => {
  const { manager } = ManagerStore;
  const {
    checkTask,
    clientCommunications,
    hasAvailableTask,
    activeCommunicationId,
    failReasons,
  } = CommunicationStore;
  const { prospect, setErrors, errors } = ProspectStore;
  const { customer, clientAddresses } = CustomerStore;
  const { order, shortOrders } = OrderStore;

  const [sectionBffExpanded, setSectionBffExpanded] = useState<
    bffPanels | componentsPanels | undefined
  >();
  const [sectionComponentsExpanded, setSectionComponentsExpanded] = useState<
    componentsPanels | bffPanels | undefined
  >('form');
  const [promocodes, setPromocodes] = useState<PlatformCRMWeb.IPromocode[]>([]);
  const [bonusesAccount, setBonusesAccount] = useState<GetBonusesAccountResponseDto>();
  const [foundedCustomers, setFoundedCustomers] = useState<SearchPersonDto[]>();

  const getManyReasons = () => {
    CommunicationStore.getManyReasons({ categories: ['Негатив'], all: true });
  };
  const getCurrentManager = () => {
    ManagerStore.getCurrentManager();
  };

  const changeStatusManager = () => {
    ManagerStore.changeStatusManager();
  };

  const getProspectById = () => {
    ProspectStore.getProspectById({ id: '1d4070e8-591d-489a-aeec-e517d67c1660' });
  };

  const getOrderById = () => {
    OrderStore.getOrderById({ id: '1d4070e8-591d-489a-aeec-e517d67c1660' });
  };

  const getCustomerInfo = () => {
    CustomerStore.getCustomerInfo({ uid: 'customerUID' });
  };

  const getCustomerHistory = () => {
    const { searchOrders } = OrderStore;
    const { findClientCommunications } = CommunicationStore;
    findClientCommunications({ clientId: person.id });
    searchOrders({
      page: 1,
      limit: 5,
      where: [{ person: { eq: '96534aa4-fd69-4e8f-83cd-f5064bec4c0c' } }],
    });
  };

  const getClientAddresses = () => {
    CustomerStore.getClientAddresses({ uid: 'customerUID' });
  };

  const searchPromocode = async () => {
    const result = await MetaStore.searchPromocode({
      page: 1,
      limit: 10,
      // where: [{ title: { in: ['windows cross sell'] } }],
    });
    setPromocodes(result);
  };

  const getBonuses = async () => {
    const { getBonusesAccount } = CustomerStore;
    const result = await getBonusesAccount({
      userId: 'dc9d8f80-24eb-11eb-8eb6-c56a85ae94e4',
      ticker: PlatformCRMWeb.AccountTicker.BONUS,
    });
    setBonusesAccount(result);
  };

  const searchCustomer = async () => {
    const { data } = await CustomerStore.searchCustomer({ where: [{ phone: { like: '7968' } }] });
    setFoundedCustomers(data);
  };

  const dynamicForm = {
    name: 'demo-form',
    rows: [
      [
        {
          type: FIELDS.TIMESLOT,
          name: 'startDate',
          // prospectId: 'prospect.id',
          serviceType: 'cleaning_flat_deep',
          errors: errors.find((error) => error.type === 'startDate')?.message,
        },
      ],
      [
        {
          type: FIELDS.ADDRESS,
          name: 'addressWithRegion',
          label: {
            label: 'Адрес',
          },
          placeholder: 'Введите адрес',
          optionValueKey: 'id',
          optionLabelKey: 'value',
          initialValue: {
            isShortForm: false,
            favourites,
          },
          withRegion: true,
          errors: {
            regionId: errors.find((error) => error.type === 'regionId')?.message,
            address: errors.find((error) => error.type === 'address')?.message,
          },
        },
      ],
      [
        {
          type: FIELDS.SELECT,
          name: 'regularity',
          label: {
            label: 'Регулярность',
          },
          placeholder: 'Выберите регулярность',
          optionValueKey: 'id',
          optionLabelKey: 'value',
          options: dictionary.selectOptions.regularity,
          variant: 'dropdown',
          isReadonly: true,
          defaultError: ERROR_MESSAGES.REGULARITY,
          isValid: isValidRegularity,
          errors: errors.find((error) => error.type === 'regularity')?.message,
        },
      ],
      [
        {
          type: FIELDS.PAYMENT_TYPE,
          name: 'paymentType',
          label: {
            label: 'Тип оплаты',
          },
          placeholder: 'Выберите тип оплаты',
          options: [dictionary.selectOptions.paymentType],
          errors: errors.find((error) => error.type === 'paymentType')?.message,
        },
      ],
      [
        {
          type: FIELDS.NUMBER_OF_PAYMENTS,
          name: 'numberOfPayments',
          label: {
            label: 'Кол-во платежей',
          },
          placeholder: 'Выберите кол-во платежей',
          options: dictionary.selectOptions.numberOfPayments,
          errors: errors.find((error) => error.type === 'numberOfPayments')?.message,
        },
      ],
      [
        {
          type: FIELDS.STRING,
          name: 'externalId',
          label: {
            label: 'ID заказа',
          },
          placeholder: 'Введите ID заказа',
          defaultError: ERROR_MESSAGES.EXTERNAL_ID,
          isValid: isValidExternalId,
          errors: errors.find((error) => error.type === 'externalId')?.message,
        },
      ],
      [
        {
          type: FIELDS.SELECT,
          name: 'serviceType',
          label: {
            label: 'Продукт',
          },
          placeholder: 'Выберите продукт',
          optionValueKey: 'id',
          optionLabelKey: 'value',
          options: dictionary.selectOptions.serviceTypes,
          variant: 'dropdown',
          defaultError: ERROR_MESSAGES.SERVICE_TYPE,
          isValid: isValidServiceType,
          errors: errors.find((error) => error.type === 'serviceType')?.message,
        },
      ],
      [
        {
          type: FIELDS.MOBILE_PHONE,
          name: 'phone',
          label: {
            label: 'Телефон клиента',
          },
          placeholder: 'Введите телефон клиента',
          errors: errors.find((error) => error.type === 'phone')?.message,
        },
      ],
    ],
  };

  const expandBffAccordion = (e, pane: bffPanels | componentsPanels) => {
    e.preventDefault();
    const value = sectionBffExpanded === pane ? undefined : pane;
    setSectionBffExpanded(value);
  };

  const expandComponentsAccordion = (e, pane: componentsPanels | bffPanels) => {
    e.preventDefault();
    const value = sectionComponentsExpanded === pane ? undefined : pane;
    setSectionComponentsExpanded(value);
  };

  const person: PersonDto = {
    id: 'a142ed43-b93f-423a-8d3d-2e30d9bc5a57',
    email: '',
    phone: '79686421141',
    status: 'contact',
    ssoId: 'adc717b0-1228-11eb-ac0e-7d9a51a69f41',
    commentForExecutor: '',
    commentForSupport: '',
    fio: {
      fullName: 'Роман лучков ',
      lastName: 'лучков',
      firstName: 'Роман',
      middleName: '',
      isValid: true,
      gender: 0,
      object: {
        surname: 'лучков',
        name: 'Роман',
        source: '',
        result: '',
        resultAblative: '',
        resultDative: '',
        resultGenitive: '',
        patronymic: '',
        gender: '',
        qc: 0,
      },
    },
    createdAt: '',
    updatedAt: '',
    qleanAdminLink: '',
    qleanCreditCardNumber: '',
  };

  return (
    <div className="px-4 container-p-y">
      <Row>
        <Col md={4}>
          <h2 className="display-4">BFF</h2>
          <DemoCard panel="reasons" onClick={expandBffAccordion} panelExpanded={sectionBffExpanded}>
            <Button className="mr-2" variant="primary" onClick={getManyReasons}>
              getManyReasons
            </Button>
            <div className="mt-2">
              <ReactJson src={{ failReasons }} />
            </div>
          </DemoCard>
          <DemoCard panel="manager" onClick={expandBffAccordion} panelExpanded={sectionBffExpanded}>
            <Button className="mr-2" variant="primary" onClick={getCurrentManager}>
              getCurrentManager
            </Button>
            <Button className="mr-2" variant="secondary" onClick={changeStatusManager}>
              changeStatusManager
            </Button>
            <div className="mt-2">
              <ReactJson src={{ manager }} />
            </div>
          </DemoCard>

          <DemoCard panel="task" onClick={expandBffAccordion} panelExpanded={sectionBffExpanded}>
            <Button className="mr-2" variant="primary" onClick={checkTask}>
              checkTask
            </Button>
            <div className="mt-2">
              <ReactJson src={{ activeCommunicationId }} />
            </div>
            <div className="mt-2">
              <ReactJson src={{ hasAvailableTask }} />
            </div>
          </DemoCard>

          <DemoCard
            panel="prospect"
            onClick={expandBffAccordion}
            panelExpanded={sectionBffExpanded}
          >
            <Button className="mr-2" variant="primary" onClick={getProspectById}>
              getProspectById
            </Button>
            <div className="mt-2">
              <ReactJson src={{ prospect }} />
            </div>
          </DemoCard>

          <DemoCard panel="order" onClick={expandBffAccordion} panelExpanded={sectionBffExpanded}>
            <Button className="mr-2" variant="primary" onClick={getOrderById}>
              getOrderById
            </Button>
            <div className="mt-2">
              <ReactJson src={{ order }} />
            </div>
          </DemoCard>

          <DemoCard
            panel="customer"
            onClick={expandBffAccordion}
            panelExpanded={sectionBffExpanded}
          >
            <Button className="mr-2" variant="primary" onClick={getCustomerInfo}>
              getCustomerInfo
            </Button>
            <Button className="mr-2" variant="secondary" onClick={getCustomerHistory}>
              getCustomerHistory
            </Button>
            <div className="mt-2">
              <ReactJson src={{ customer }} />
            </div>
            <div className="mt-2">
              <ReactJson src={{ shortOrders }} />
            </div>
            <div className="mt-2">
              <ReactJson src={{ clientCommunications }} />
            </div>
          </DemoCard>

          <DemoCard
            panel="addresses"
            onClick={expandBffAccordion}
            panelExpanded={sectionBffExpanded}
          >
            <Button className="mr-2" variant="primary" onClick={getClientAddresses}>
              getClientAddresses
            </Button>
            <div className="mt-2">
              <ReactJson src={{ clientAddresses }} />
            </div>
          </DemoCard>

          <DemoCard
            panel="promocode"
            onClick={expandBffAccordion}
            panelExpanded={sectionBffExpanded}
          >
            <Button className="mr-2" variant="primary" onClick={searchPromocode}>
              searchPromocode
            </Button>
            <div className="mt-2">
              <ReactJson src={{ promocodes }} />
            </div>
          </DemoCard>

          <DemoCard panel="bonuses" onClick={expandBffAccordion} panelExpanded={sectionBffExpanded}>
            <Button className="mr-2" variant="primary" onClick={getBonuses}>
              getBonusesAccount
            </Button>
            <div className="mt-2">
              <ReactJson src={{ bonusesAccount }} />
            </div>
          </DemoCard>

          <DemoCard
            panel="searchCustomer"
            onClick={expandBffAccordion}
            panelExpanded={sectionBffExpanded}
          >
            <Button className="mr-2" variant="primary" onClick={searchCustomer}>
              searchCustomer
            </Button>
            <div className="mt-2">
              <ReactJson src={{ foundedCustomers }} />
            </div>
          </DemoCard>
        </Col>
        <Col md={8}>
          <h2 className="display-4">Components</h2>

          <DemoCard
            panel="form"
            onClick={expandComponentsAccordion}
            panelExpanded={sectionComponentsExpanded}
            title="Форма с валидацией"
          >
            <DynamicForm
              /* @ts-ignore */
              form={dynamicForm}
              onSubmit={(values) => {
                setErrors(
                  validateCreateTask({
                    serviceType: values?.serviceType,
                  })
                );
              }}
            />
            <Button
              onClick={() => {
                setErrors([
                  { type: 'startDate', message: 'startDate ошибка с бека' },
                  { type: 'regionId', message: 'regionId ошибка с бека' },
                  { type: 'address', message: 'address ошибка с бека' },
                  { type: 'regularity', message: 'regularity ошибка с бека' },
                  { type: 'paymentType', message: 'paymentType ошибка с бека' },
                  { type: 'externalId', message: 'externalId ошибка с бека' },
                  { type: 'serviceType', message: 'serviceType ошибка с бека' },
                  { type: 'phone', message: 'phone ошибка с бека' },
                ]);
              }}
            >
              setErrors
            </Button>
          </DemoCard>

          <DemoCard
            panel="calculator"
            onClick={expandComponentsAccordion}
            panelExpanded={sectionComponentsExpanded}
            title="Калькулятор"
          >
            <Calculator
              productSlug={'cleaning_flat_standard'}
              regionId={1}
              onSubmit={(product) => logger.info('Calculate')}
            />
          </DemoCard>

          <DemoCard
            panel="toast"
            onClick={expandComponentsAccordion}
            panelExpanded={sectionComponentsExpanded}
            title="Toast"
          >
            <Button
              variant="primary"
              onClick={() => {
                toast('Сообщение о чем-то', {
                  type: 'default',
                  position: 'top-center',
                  hideProgressBar: false,
                  closeOnClick: false,
                  pauseOnHover: true,
                  draggable: true,
                  autoClose: 1500,
                });
              }}
            >
              TOAST
            </Button>
          </DemoCard>

          <DemoCard
            panel="customerHistory"
            onClick={expandComponentsAccordion}
            panelExpanded={sectionComponentsExpanded}
            title="История клиента"
          >
            <PersonHistory communicationId="80566c13-9921-46b1-a0de-9c89cb7d2d30" person={person} />
          </DemoCard>

          <DemoCard
            panel="searchCustomer"
            onClick={expandComponentsAccordion}
            panelExpanded={sectionComponentsExpanded}
            title="Поиск клиента"
          >
            <SearchCustomer
              placeholder="Поиск по номеру телефона"
              onChoose={(client?) =>
                toast(`Выбран клиент: ${client?.fio?.fullName}`, { autoClose: 1500 })
              }
            />
          </DemoCard>
        </Col>
      </Row>
    </div>
  );
});

export default Demo;
