import React from 'react';
import * as ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import * as serviceWorker from 'src/serviceWorker';

import App from './App';
import { HttpClient } from '@sentry/integrations';

const rootElement = document.getElementById('root');

const { REACT_APP_SENTRY_DSN_CLIENT, REACT_APP_SENTRY_ENVIRONMENT, NODE_ENV } = process.env;

Sentry.init({
  dsn: REACT_APP_SENTRY_DSN_CLIENT,
  environment: REACT_APP_SENTRY_ENVIRONMENT,
  enabled: NODE_ENV === 'production',
  enableTracing: NODE_ENV === 'production',
  integrations: [new Sentry.BrowserTracing(), new HttpClient()],
  tracesSampleRate: 1.0,
});

ReactDOM.render(<App />, rootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
