import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { observer } from 'mobx-react';

import MetaStore from 'src/store/MetaStore';
import DynamicForm from 'src/components/Forms/DynamicForm';
import { ICalculatorCard, ICalculatorProps, ICalculatorValue } from './calcultor.interfaces';
import { priceToCards } from './calcultor.utils';
import { Card, Tabs, Tab, Spinner, Badge, Row, Col } from 'react-bootstrap';
import { logger } from '@qlean/front-logger';
import { PlatformCRMWeb } from 'src/services/GrpcService';
import { TForm } from '../Forms/form.interface';
import { isValidPrice, ERROR_MESSAGES, isCleaningServiceType } from 'src/utils/validation';
import {lmsServiceTypes} from '../../utils/dictionary';

export const Calculator = observer((props: ICalculatorProps) => {
  const {
    productSlug,
    regionId,
    product,
    isBordered = true,
    isAutoSubmit = true,
    onSubmit,
    keysDelivery,
    keysPickup,
    isLoading,
    isReadonly,
    isActiveOrder,
    error,
  } = props;
  const { getPriceElement, priceElement } = MetaStore;
  const [fields, setFields] = useState<ICalculatorCard[]>();
  const [currentValues, setCurrentValues] = useState<{ [key: string]: ICalculatorValue }>({});

  const [showError, setShowError] = useState(false);
  useEffect(() => {
    setShowError(Boolean(error));
  }, [error]);

  const onFocus = () => {
    setShowError(false);
  };

  const onBlur = () => {
    if (isCleaningServiceType(productSlug)) {
      setShowError(!isValidPrice(product));
    }
  };

  const wait = 1000;

  const onChangeForm = (values: { [key: string]: ICalculatorValue }) => {
    logger.debug('[Calculator] onChangeForm', values);
    const oldOptions = product?.options.map((option) => ({
      slug: option.slug,
      value: +option.value,
      isFree: option.isFree,
      factor: option.factor,
    }));
    const keys = Object.keys(values).filter((key) => key.indexOf('_address') < 0);
    const newOptions = keys
      .map((slug) => {
        let value = values[slug].value;
        if (value?.value) value = value.value;
        if (value === true) value = 1;
        return {
          slug,
          value: +value,
          isFree: values[slug].isFree,
          factor: Math.round(+values[slug].factor * 2) / 2,
        };
      })
      .filter((option) => option?.value === 0 || Boolean(option?.value));

    const products: PlatformCRMWeb.Product[] = [
      {
        productSlug,
        options: [..._.differenceBy(oldOptions, newOptions, 'slug'), ...newOptions]
            .filter(
          (option) => option.value,
        ),
      },
    ];

    const newKeysDelivery = values['keys_delivery_address']?.value
      ? values['keys_delivery_address']?.value?.address
      : keysDelivery;
    const newKeysPickup = values['keys_pickup_address']?.value
      ? values['keys_pickup_address']?.value?.address
      : keysPickup;

    logger.debug('[Calculator] upsertOrUpdate', products, newKeysDelivery, newKeysPickup);
    onSubmit(products, newKeysDelivery, newKeysPickup);
    setCurrentValues(values);
  };

  useEffect(() => {
    if (!productSlug) {
      throw new Error('[CalculatorField] productSlug is empty');
    }
    getPriceElement({
      regionId: regionId || 1,
      productSlug,
    });
  }, [regionId, productSlug]);
  logger.debug({ priceElement, product });

  useEffect(() => {
    if (!priceElement) {
      return;
    }

    const isLms = lmsServiceTypes.includes(productSlug) && isActiveOrder;

    setFields(
      priceToCards(
        priceElement,
        product,
        regionId || 1,
        keysDelivery,
        keysPickup,
        isLoading,
        isReadonly || isLms,
        isActiveOrder,
        currentValues,
      ),
    );

  }, [priceElement, product, isLoading, isReadonly, regionId, currentValues]);

  const getHeader = (form?: TForm) => {
    const withFactor = form?.rows.some((row) => row[0].withFactor);
    return (
      <Row className="d-flex list-group-item pl-3 pt-0 pr-2 pb-0 mx-0 border-left-0 border-right-0 border-top-0 form-row">
        <Col md="12">
          <Row>
            <Col
              className="d-flex align-items-center justify-content-between py-3"
              md={withFactor ? 6 : 8}
            >
              <h6 className="mb-0">Опция</h6>
              <div className="d-flex">
                <h6 className="mb-0 mr-2">Цена</h6>
                <h6 className="mb-0">Время</h6>
              </div>
            </Col>
            {withFactor && (
              <Col
                className="d-flex justify-content-center align-items-center border-left py-3"
                md={2}
              >
                <h6 className="mb-0">Кол-во часов</h6>
              </Col>
            )}
            <Col
              className="d-flex justify-content-center align-items-center border-left py-3"
              md="2"
            >
              <h6 className="mb-0">Кол-во</h6>
            </Col>
            <Col
              className="d-flex justify-content-center align-items-center border-left py-3"
              md="2"
            >
              <h6 className="mb-0">Бесплатно</h6>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  return (
    <div>
      {fields
        ?.filter((item) => !!item.isMain)
        .map((item: ICalculatorCard) => (
          <div className="mb-4" onFocus={onFocus} onBlur={onBlur}>
            <Card key={item.form.name} className={`${showError ? 'border-danger' : ''}`}>
              <Card.Header as="h6" className="d-flex justify-content-between">
                {item.form.name}
                {isLoading && <Spinner animation="border" size="sm" variant="primary" />}
              </Card.Header>
              <DynamicForm
                isBordered={isBordered}
                isAutoSubmit={isAutoSubmit}
                form={item.form}
                onSubmit={onChangeForm}
                header={getHeader(item.form)}
                wait={wait}
                key={item.key}
              />
            </Card>
            {showError && (
              <span className="invalid-feedback d-block">{error || ERROR_MESSAGES.PRICE}</span>
            )}
          </div>
        ))}
      {!!fields?.filter((item) => !item.isMain).length && (
        <div className="nav-tabs-top mb-4 bg-white" style={{ borderRadius: '3px' }}>
          <Tabs className="m-0 pl-3 pt-3" id="other-options-tabs">
            {fields
              ?.filter((item) => !item.isMain)
              .map((item: ICalculatorCard, i) => (
                <Tab
                  key={item.form.name}
                  eventKey={`other-options-tab-${i}`}
                  title={
                    <>
                      <span className="mr-1">{item.form.name}</span>
                      {item?.quantityOfSlugsWithValue > 0 && (
                        <Badge pill variant="info">
                          {item.quantityOfSlugsWithValue}
                        </Badge>
                      )}
                    </>
                  }
                >
                  <DynamicForm
                    isBordered={isBordered}
                    isAutoSubmit={isAutoSubmit}
                    form={item.form}
                    onSubmit={onChangeForm}
                    header={getHeader()}
                    wait={wait}
                    key={item.key}
                  />
                </Tab>
              ))}
          </Tabs>
        </div>
      )}
    </div>
  );
});
