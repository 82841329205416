import React, { useEffect, useState } from 'react';

import { Col, Form, Badge, Row } from 'react-bootstrap';
import Select from 'react-select';
import { Field } from 'react-final-form';
import { FieldError } from 'src/components/Fields/FieldError';
import usePrevious from 'src/utils/usePrevious';

import { TFieldProp } from 'src/components/Fields/field.interface';

import { parsePrice, parseDuration } from 'src/utils/formatters';
import { FIELDS } from '../../Forms/form.interface';
import { logger } from '@qlean/front-logger';

interface AnyObject {
  [key: string]: any;
}

export enum DisplayType {
  NUMBER = 'number',
  LIST = 'list',
  CHECKBOX = 'checkbox',
  READONLY = 'readonly',
}

export interface IOption {
  label: string;
  value: number | string;
}

export interface IDefaultValue {
  value: IOption | number | boolean | string | undefined | null;
  isFree: boolean;
  factor: number;
}

export interface IInitialValue {
  value: IOption | number | boolean | string | undefined | null;
  isFree: boolean;
  factor: number | string;
}

export interface IOptionFieldProp {
  type: FIELDS;
  display: DisplayType;
  options?: IOption[];
  totalDuration: number;
  totalPrice: number;
  defaultValue: IDefaultValue;
  withFactor: boolean;
}

export type TOptionFieldProp = IOptionFieldProp & TFieldProp<AnyObject>;

export const OptionField = (props: TOptionFieldProp) => {
  const {
    placeholder = 'Выбор',
    name,
    label,
    defaultValue,
    display,
    options,
    totalDuration,
    totalPrice,
    withFactor,
    isReadonly,
  } = props;

  let initialValue: IInitialValue = defaultValue;
  const [ currentValue, setCurrentValue ] = useState(defaultValue.value);

  const [factor, setFactor] = useState<number | string>(initialValue.factor);
  const prevValue = usePrevious(initialValue);

  useEffect(() => {
    if (defaultValue?.value !== prevValue?.value) {
      setCurrentValue(defaultValue.value);
    }
  }, [ defaultValue ]);

  useEffect(() => {
    if (isReadonly === false) {
      setFactor(defaultValue.factor);
    }
  }, [isReadonly]);

  const numberValue = (value: any): string | undefined => {
    if (typeof value === 'number') {
      if (value === 0) {
        return undefined;
      }
      return value.toString();
    }
    if (typeof value === 'string') {
      return value.replace(/[^\d-.]/g, '');
    }
    return undefined;
  };

  const renderField = (input, meta) => {
    switch (display) {
      case DisplayType.LIST:
        const value = options?.find((option) => option.value === `${initialValue.value}`);
        initialValue = {
          ...initialValue,
          value: {
            label: `${value?.label || 0}`,
            value: `${value?.value || 0}`,
          },
        };
        return (
          <Select
            {...input}
            options={options}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            defaultValue={initialValue.value}
            placeholder={placeholder}
            value={input.label}
            isDisabled={isReadonly}
            className="react-select w-100"
            classNamePrefix="react-select"
            onChange={(evt) => {
              logger.debug('CHANGE', name, evt);
              if (evt) {
                input.onChange({
                  ...input.value,
                  // @ts-ignore
                  value: evt.value,
                });
              }
            }}
            menuPortalTarget={document.body}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          />
        );
      case DisplayType.NUMBER:
        return (
          <Form.Control
            id={`${name}-value`}
            value={numberValue(currentValue)}
            placeholder={placeholder}
            disabled={isReadonly}
            isInvalid={FieldError.isInvalid(meta)}
            onChange={(evt) => {
              const inputValue = evt.target.value.replace(/[^\d-.]/g, '');
              logger.debug('CHANGE', name, evt.target.value);
              setCurrentValue(inputValue || '0');
              input.onChange({
                ...input.value,
                value: inputValue || '0',
              });
            }}
          />
        );
      case DisplayType.CHECKBOX:
        return (
          <div>
            <label className="switcher">
              <input
                type="checkbox"
                className="switcher-input"
                disabled={isReadonly}
                checked={!!currentValue}
                defaultChecked={Boolean(initialValue.value)}
                onChange={(evt) => {
                  logger.debug('CHANGE', name, evt.target.checked);
                  input.onChange({
                    ...input.value,
                    value: evt.target.checked ? 1 : 0,
                  });
                }}
              />
              <span className="switcher-indicator">
                <span className="switcher-yes">
                  <span className="ion ion-md-checkmark"></span>
                </span>
                <span className="switcher-no">
                  <span className="ion ion-md-close"></span>
                </span>
              </span>
            </label>
          </div>
        );
    }
  };
  return (
    <Field
      name={name}
      initialValue={initialValue}
      render={({ input, meta }) => {
        return (
          <Form.Group className="m-0">
            <Row style={{ minHeight: '36px' }}>
              <Col
                className="d-flex align-items-center justify-content-between py-3"
                md={withFactor ? 6 : 8}
              >
                <span>{label?.label}</span>
                <div className="d-flex flex-shrink-0">
                  <span className="text-light mr-2">
                    {input.value.isFree ? (
                      <del>{`+ ${parsePrice(totalPrice)}`}</del>
                    ) : (
                      `+ ${parsePrice(totalPrice)}`
                    )}
                  </span>
                  <div className="d-flex align-items-center">
                    <Badge className="font-weight-normal border-bottom-0 card-header badge-outline-default">
                      {parseDuration(totalDuration) || '0 мин.'}
                    </Badge>
                  </div>
                </div>
              </Col>
              {withFactor && (
                <Col
                  className="d-flex justify-content-center align-items-center border-left py-3"
                  md={2}
                >
                  <Form.Control
                    id={`${name}-factor`}
                    value={factor}
                    placeholder={'Кол-во часов'}
                    defaultValue={initialValue.factor}
                    disabled={isReadonly}
                    isInvalid={FieldError.isInvalid(meta)}
                    onChange={(evt) => {
                      const parsedFactor = evt.target.value.replace(/[^\d-.]/g, '');
                      setFactor(parsedFactor);
                      logger.debug('CHANGE FACTOR', name, parsedFactor);
                      input.onChange({
                        ...input.value,
                        factor: parsedFactor,
                      });
                    }}
                  />
                </Col>
              )}
              <Col
                className="d-flex justify-content-center align-items-center border-left py-3"
                md={2}
              >
                <Form.Control
                  hidden
                  readOnly={true}
                  value={defaultValue.value ? defaultValue.value.toString() : 0}
                  // isInvalid={FieldError.isInvalid(meta)}
                />
                {renderField(input, meta)}
              </Col>
              <Col
                className="d-flex justify-content-center align-items-center border-left py-3"
                md={2}
              >
                <div>
                  <label className="switcher">
                    <input
                      type="checkbox"
                      className="switcher-input"
                      defaultChecked={initialValue.isFree}
                      checked={input.value.isFree}
                      disabled={isReadonly}
                      onChange={(evt) => {
                        logger.debug('CHANGE', name, evt.target.checked);
                        input.onChange({
                          ...input.value,
                          isFree: evt.target.checked ? 1 : 0,
                        });
                      }}
                    />
                    <span className="switcher-indicator">
                      <span className="switcher-yes">
                        <span className="ion ion-md-checkmark"></span>
                      </span>
                      <span className="switcher-no">
                        <span className="ion ion-md-close"></span>
                      </span>
                    </span>
                  </label>
                </div>
              </Col>
            </Row>
            <FieldError error={meta.error} />
          </Form.Group>
        );
      }}
    />
  );
};
