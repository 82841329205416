import React, { useEffect, useMemo, useState } from 'react';
import { Alert, Button, Card, DropdownButton } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react';

import OrderStore, { OrderDto, RejectionGroupDto } from 'src/store/OrderStore';

import 'src/vendor/styles/pages/users.scss';
import { parsePrice, parseDuration, parseMinimumPartOfOrderPayment, parseDiscount, parseBonuses } from 'src/utils/formatters';
import { dictionary, OrderPaymentType, OrderStatus, ServiceType } from 'src/utils/dictionary';
import { DropdownList, IDropdownListOption } from 'src/components/DropdownList';
import { ButtonTitle } from 'src/components/ButtonTitle';
import { PlatformCRMWeb } from 'src/services/GrpcService';
import { SoftPostQR } from './modals/SoftPostQR';

interface IProps {
  order: OrderDto;
}

const OrderSummary = observer((props: IProps) => {
  const { order } = props;
  const { isLoading, changeStatus, sendInvoice, sendPaymentLink, getOrderRejectionGroups, rejectionReasons, isOMSOrder, repeatPaymentInOmsOrder } = OrderStore;
  const isRTL = document.documentElement.getAttribute('dir') === 'rtl';
  const [isSoftPostModalShown, setIsSoftPostModalShown] = useState<boolean>(false)

  useEffect(() => {
    getOrderRejectionGroups({ productSlug: order.serviceType });
  }, []);

  const onChangeStatus = ({ status, cancelReason }: { status: string; cancelReason?: string }) => {
    changeStatus({ id: order.id, status, cancelReason }).then((res) => {
      if (res?.success) {
        toast(res.message, { type: 'success', autoClose: 1500 });
      }
    });
  };

  const onRejectClick = (option: IDropdownListOption) => {
    changeStatus({ id: order.id, status: 'cancel', cancelReason: option.value }).then((res) => {
      if (res?.success) {
        toast(res.message, { type: 'success', autoClose: 1500 });
      }
    });
  };

  const onSendPaymentLinkClick = () => {
    sendPaymentLink({ orderId: order.id }).then((res) => {
      if (res) {
        toast('Платежная ссылка успешно отправлена', { type: 'success', autoClose: 2500 });
      }
    });
  };

  const onPayBySoftPosClick = () => {
    setIsSoftPostModalShown(true);
  }

  const rejectionOptions = useMemo<IDropdownListOption[]>(() => {
    function convert(groups: RejectionGroupDto[]): IDropdownListOption[] {
      const result: IDropdownListOption[] = [];

      for (const group of groups) {
        const groupOption: IDropdownListOption = {
          id: group.id,
          value: group.title,
          options: [],
        };

        if (group.groups?.length) {
          groupOption.options = convert(group.groups);
        }

        if (group.reasons?.length) {
          for (const reason of group.reasons) {
            groupOption.options?.push({ id: reason.id!, value: reason.title! });
          }
        }

        result.push(groupOption);
      }

      return result;
    }

    if (rejectionReasons.groups.length === 1 && rejectionReasons.groups[0].type === PlatformCRMWeb.REJECTION_GROUP_TYPE.DEFAULT) {
      return rejectionReasons.groups[0].reasons.map((r) => ({ id: r.id, value: r.title }));
    }
    return convert(rejectionReasons.groups);
  }, [rejectionReasons.isLoading]);

  const renderHeaderAdditionals = () => {
    const res: JSX.Element[] = [];

    if (order.paymentType === 'sms') {
      res.push(<div className="px-1">
        <Button
          variant="outline-secondary"
          // disabled={!['paid', 'finished'].includes(order.status)}
          onClick={() => {
            sendInvoice({ orderId: order.id });
          }}
        >
          <ButtonTitle isLoading={isLoading} title="Выставить счёт" />
        </Button>
      </div>)
    }

    if (order.paymentType === OrderPaymentType.Sms && order.status === OrderStatus.Finished && order.serviceType === ServiceType.SellGoodsNew) {
      res.push(
        <div className="px-1">
          <Button variant="outline-secondary" onClick={onSendPaymentLinkClick}>
            <ButtonTitle isLoading={isLoading} title="Отправить платежную ссылку" />
          </Button>
        </div>
      )
    }

    if (isOMSOrder && order.paymentStatus === 'failed') {
      res.push(
        <div>
          <Button variant="outline-secondary" onClick={repeatPaymentInOmsOrder}>
            <ButtonTitle isLoading={isLoading} title="Повторить оплату" />
          </Button>
        </div>
      )
    }

    if (order.paymentType === OrderPaymentType.SoftPos && order.status === OrderStatus.Finished && ['sell_goods_chemistry'].includes(order.serviceType)) {
      res.push(
        <div>
          <Button variant="info" onClick={onPayBySoftPosClick}>
            <ButtonTitle isLoading={isLoading} title="Оплатить по QR" />
          </Button>
        </div>
      )
    }

    return res;
  };

  return (
    <Card>
      <Card.Header as="h6">Итого</Card.Header>
      {order.paymentStatus === 'failed' && (
        <Alert
        id='payment_warning'
        variant='danger'
        >Ошибка с оплатой заказа. Повторите попытку или поменяйте способ оплаты</Alert>
          )}
      <div className="container-fluid d-flex flex-wrap justify-content-between text-center container-p-x pb-3">
        <div className="mt-3 d-flex">
          <div className="d-flex" style={{ borderRight: '1px solid #f1f1f2' }}>
            <div className="px-2">
              <div className="text-muted small text-left">Итого</div>
              <div className="d-flex justify-content-between align-items-center">
                <span style={{ fontSize: '16px' }} className="footer-text text-success font-weight-bold">
                  {parsePrice(order?.totalPrice || 0)}
                </span>
              </div>
            </div>
            <div className="px-2">
              <div className="text-muted small text-left">Без скидок</div>
              <div className="d-flex justify-content-between align-items-center">
                <span style={{ fontSize: '16px' }} className="footer-text">
                  {parsePrice(order?.price?.subTotalPrice)}
                </span>
              </div>
            </div>
            <div className="px-2">
              <div className="text-muted small text-left">Время</div>
              <div className="d-flex justify-content-between align-items-center">
                <span style={{ fontSize: '16px' }} className="footer-text">
                  {parseDuration(order?.price?.totalDuration)}
                </span>
              </div>
            </div>
          </div>
          <div className="px-2">
            <div className="text-muted small text-left">Скидка</div>
            <div className="d-flex justify-content-between align-items-center mt-1">
              <span className="footer-text small">
                {parseDiscount(order?.price?.totalPrice, order?.price?.subTotalPrice, order?.price?.bonusesUsed)}
              </span>
            </div>
          </div>
          <div className="px-2">
            <div className="text-muted small text-left">Баллы</div>
            <div className="d-flex justify-content-between align-items-center mt-1">
              <span className="footer-text small">{parseBonuses(order?.bonusesUsed)}</span>
            </div>
          </div>
          <div className="px-2">
            <div className="text-muted small text-left">Мин. оплата</div>
            <div className="d-flex justify-content-between align-items-center mt-1">
              <span className="footer-text small">
                {parseMinimumPartOfOrderPayment(order?.price?.minPaymentPercent, order?.price?.subTotalPrice)}
              </span>
            </div>
          </div>
        </div>
        <div className="pt-3 d-flex">
          <div className="px-1">
            <DropdownButton
              id="status"
              variant={dictionary.selectOptions.orderStatus.find((status) => status.id === order.status)?.type}
              title={
                <ButtonTitle
                  isLoading={isLoading}
                  title={`Статус: ${dictionary.selectOptions.orderStatus.find((status) => status.id === order.status)?.value || order.status}`}
                />
              }
              className="d-inline-block"
              disabled={isLoading}
            >
              <DropdownList
                options={dictionary.selectOptions.orderStatus.filter((status) => status.id !== 'cancel')}
                onClick={(option) => onChangeStatus({ status: option.id.toString() })}
              />
            </DropdownButton>
          </div>
          {renderHeaderAdditionals()}
          <div className="px-1">
            <DropdownButton
              id="rejection"
              variant="danger"
              title={<ButtonTitle isLoading={isLoading} title="Отказ" />}
              className="d-inline-block"
              disabled={isLoading}
              alignRight={isRTL}
            >
              <DropdownList options={rejectionOptions} onClick={onRejectClick} align="leftTop" />
            </DropdownButton>
          </div>
        </div>
      </div>
      <SoftPostQR show={isSoftPostModalShown} onClose={() => setIsSoftPostModalShown(false)} store={OrderStore} />
    </Card>
  );
});
export default OrderSummary;
