import { IValue } from 'src/components/Fields/Address/interfaces/address.interface';

const address1: IValue = {
  address: {
    address_id: '1234',
    value: 'г Москва, ул Арбат, д 1, кв 1',
    unrestrictedValue: '119019, г Москва, р-н Арбат, ул Арбат, д 1, кв 1',
    object: JSON.stringify({
      postal_code: '119019',
      country: 'Россия',
      country_iso_code: 'RU',
      federal_district: 'Центральный',
      region_fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
      region_kladr_id: '7700000000000',
      region_iso_code: 'RU-MOW',
      region_with_type: 'г Москва',
      region_type: 'г',
      region_type_full: 'город',
      region: 'Москва',
      area_fias_id: null,
      area_kladr_id: null,
      area_with_type: null,
      area_type: null,
      area_type_full: null,
      area: null,
      city_fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
      city_kladr_id: '7700000000000',
      city_with_type: 'г Москва',
      city_type: 'г',
      city_type_full: 'город',
      city: 'Москва',
      city_area: 'Центральный',
      city_district_fias_id: null,
      city_district_kladr_id: null,
      city_district_with_type: 'р-н Арбат',
      city_district_type: 'р-н',
      city_district_type_full: 'район',
      city_district: 'Арбат',
      settlement_fias_id: null,
      settlement_kladr_id: null,
      settlement_with_type: null,
      settlement_type: null,
      settlement_type_full: null,
      settlement: null,
      street_fias_id: 'b30b63a1-c2be-4efc-9d0c-c9b6d7438e15',
      street_kladr_id: '77000000000075800',
      street_with_type: 'ул Арбат',
      street_type: 'ул',
      street_type_full: 'улица',
      street: 'Арбат',
      house_fias_id: '4cbce9f3-6fd7-4162-962d-41268b75aadc',
      house_kladr_id: '7700000000007580080',
      house_type: 'д',
      house_type_full: 'дом',
      house: '1',
      block_type: null,
      block_type_full: null,
      block: null,
      flat_fias_id: null,
      flat_type: 'кв',
      flat_type_full: 'квартира',
      flat: '1',
      flat_area: null,
      square_meter_price: null,
      flat_price: null,
      postal_box: null,
      fias_id: '4cbce9f3-6fd7-4162-962d-41268b75aadc',
      fias_code: '77000000000000007580080',
      fias_level: '8',
      fias_actuality_state: '0',
      kladr_id: '7700000000007580080',
      geoname_id: '524901',
      capital_marker: '0',
      okato: '45286552000',
      oktmo: '45374000',
      tax_office: '7704',
      tax_office_legal: '7704',
      timezone: 'UTC+3',
      beltway_hit: 'IN_MKAD',
      beltway_distance: null,
      qc_geo: '0',
      qc_complete: null,
      qc_house: null,
      history_values: null,
      unparsed_parts: null,
      source: null,
      qc: null,
    }),
    metro: [
      {
        name: 'Арбатская',
        line: 'Филёвская',
        distance: 0.1,
      },
      {
        name: 'Арбатская',
        line: 'Арбатско-Покровская',
        distance: 0.3,
      },
      {
        name: 'Александровский сад',
        line: 'Филёвская',
        distance: 0.6,
      },
    ],
    coordinates: {
      lat: 55.7519074,
      lng: 37.5992175,
    },
  },
  regionId: 1,
};

const address2: IValue = {
  regionId: 2,
  address: {
    address_id: '1234',
    value: 'г Санкт-Петербург, ул Рубинштейна, д 10, кв 3',
    unrestrictedValue: '191025, г Санкт-Петербург, Центральный р-н, ул Рубинштейна, д 10, кв 3',
    object: JSON.stringify({
      postal_code: '191025',
      country: 'Россия',
      country_iso_code: 'RU',
      federal_district: 'Северо-Западный',
      region_fias_id: 'c2deb16a-0330-4f05-821f-1d09c93331e6',
      region_kladr_id: '7800000000000',
      region_iso_code: 'RU-SPE',
      region_with_type: 'г Санкт-Петербург',
      region_type: 'г',
      region_type_full: 'город',
      region: 'Санкт-Петербург',
      area_fias_id: null,
      area_kladr_id: null,
      area_with_type: null,
      area_type: null,
      area_type_full: null,
      area: null,
      city_fias_id: 'c2deb16a-0330-4f05-821f-1d09c93331e6',
      city_kladr_id: '7800000000000',
      city_with_type: 'г Санкт-Петербург',
      city_type: 'г',
      city_type_full: 'город',
      city: 'Санкт-Петербург',
      city_area: null,
      city_district_fias_id: null,
      city_district_kladr_id: null,
      city_district_with_type: 'Центральный р-н',
      city_district_type: 'р-н',
      city_district_type_full: 'район',
      city_district: 'Центральный',
      settlement_fias_id: null,
      settlement_kladr_id: null,
      settlement_with_type: null,
      settlement_type: null,
      settlement_type_full: null,
      settlement: null,
      street_fias_id: '7d472547-b0fc-4e8c-a2cd-ce4242c3c06e',
      street_kladr_id: '78000000000120400',
      street_with_type: 'ул Рубинштейна',
      street_type: 'ул',
      street_type_full: 'улица',
      street: 'Рубинштейна',
      house_fias_id: null,
      house_kladr_id: null,
      house_type: 'д',
      house_type_full: 'дом',
      house: '10',
      block_type: null,
      block_type_full: null,
      block: null,
      flat_fias_id: null,
      flat_type: 'кв',
      flat_type_full: 'квартира',
      flat: '3',
      flat_area: '51.1',
      square_meter_price: null,
      flat_price: null,
      postal_box: null,
      fias_id: '7d472547-b0fc-4e8c-a2cd-ce4242c3c06e',
      fias_code: '78000000000000012040000',
      fias_level: '7',
      fias_actuality_state: '0',
      kladr_id: '78000000000120400',
      geoname_id: '498817',
      capital_marker: '0',
      okato: '40298566000',
      oktmo: '40913000',
      tax_office: '7840',
      tax_office_legal: '7840',
      timezone: 'UTC+3',
      beltway_hit: 'IN_KAD',
      beltway_distance: null,
      qc_geo: '0',
      qc_complete: null,
      qc_house: null,
      history_values: null,
      unparsed_parts: null,
      source: null,
      qc: null,
    }),
    metro: [
      {
        name: 'Достоевская',
        line: 'Правобережная',
        distance: 0.4,
      },
      {
        name: 'Владимирская',
        line: 'Кировско-Выборгская',
        distance: 0.4,
      },
      {
        name: 'Маяковская',
        line: 'Невско-Василеостровская',
        distance: 0.5,
      },
    ],
    coordinates: {
      lat: 59.9315009,
      lng: 30.3457433,
    },
  },
};

export const favourites: IValue[] = [address1, address2];
