import React, { useState, useEffect } from 'react';
import { Button, DropdownButton, Nav } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { observer } from 'mobx-react';
import { toast } from 'react-toastify';
import { PlatformCRMWeb } from 'src/services/GrpcService';

import ProspectStore, { ProspectDto } from 'src/store/ProspectStore';
import ManagerStore from 'src/store/ManagerStore';

import CommunicationStore, { CommunicationDto, ITaskCompletedResponse, ReasonDto } from 'src/store/CommunicationStore';
import 'src/vendor/styles/pages/users.scss';
import { parseBonuses, parseDiscount, parseDuration, parseMinimumPartOfOrderPayment, parsePrice } from 'src/utils/formatters';
import { validateProspect } from 'src/utils/validation';
import { dictionary } from 'src/utils/dictionary';
import { DropdownList, IDropdownListOption } from 'src/components/DropdownList';
import { ButtonTitle } from 'src/components/ButtonTitle';
import CallbackModal from './CallbackModal';
import DialOutModal from './DialOutModal';
import { REASONS_CATEGORIES } from '../../../constants';

interface IProps {
  prospect: ProspectDto;
  communication: CommunicationDto;
  taskId: string;
  isReadonly?: boolean;
}

const ProspectSummary = observer((props: IProps) => {
  const { prospect, communication, taskId } = props;
  const {
    reschedule,
    postpone,
    proceedTask,
    cancelTask,
    fail,
    applyCommunication,
    isActionProcessing,
    getManyProductReasons,
    getManyReasons,
    productReasons,
    failReasons,
  } = CommunicationStore;
  const { setErrors, upsertProspect } = ProspectStore;
  const { manager } = ManagerStore;
  const [isShowCallbackModal, setIsShowCallbackModal] = useState(false);
  const [isShowDialOutModal, setIsShowDialOutModal] = useState(false);
  const [dialOutReason, setDialOutReason] = useState<PlatformCRMWeb.CancellationReason | undefined>(undefined);
  const [callbackDate, setCallbackDate] = useState<Date | undefined>(undefined);
  const history = useHistory();
  const isLoading = ProspectStore.isLoading || isActionProcessing;
  const isRTL = document.documentElement.getAttribute('dir') === 'rtl';

  const cancelReasonCategories = productReasons?.[0]?.cancellationReasons
    .filter((reason: ReasonDto) => reason.category !== REASONS_CATEGORIES.CLOSE_ALL_CATEGORY)
    .reduce((result: string[], reason) => {
      return result.includes(reason.category) ? result : [...result, reason.category];
    }, []);

  const cancelReasonOptions = cancelReasonCategories?.map((category) => ({
    id: category,
    value: category,
    options: productReasons?.[0].cancellationReasons
      ?.filter((reason) => reason.category === category)
      .map((reason) => ({ id: reason.id, value: reason.title })),
  }));

  const failReasonOptions: IDropdownListOption[] = failReasons?.map((reason) => ({
    id: reason.id,
    value: reason.title,
  }));

  useEffect(() => {
    getManyReasons({ categories: ['Закрыть всё'], all: false });
    getManyProductReasons({ keys: [prospect.serviceType] });
  }, [prospect.id, prospect.serviceType]);

  const onTaskStatusChanged = ({ isOk, newTaskId, paymentStatus, orderId }: ITaskCompletedResponse) => {
    if (isOk) {
      if (paymentStatus === 'failed') {
        history.push(`/order/${orderId}`)
        toast('Ошибка с оплатой заказа. Повторите попытку или поменяйте способ оплаты', {type: 'error', autoClose: 6000})
      } else if (newTaskId) {
        history.push(`/communication/${communication.id}/${newTaskId}`);
      } else {
        toast('Задача обработана', { type: 'success', autoClose: 1500 });
        history.push('/');
      }
    }
  };

  const onTaskCallBack = (res) => {
    if (res) {
      history.push('/');
      toast('Задача отложена', { type: 'success', autoClose: 1500 });
      setIsShowCallbackModal(false);
    }
  };

  const onApply = async () => {
    const errors = await upsertProspect({
      ...prospect,
    });
    if (errors) {
      const newErrors = validateProspect(errors, prospect);
      setErrors(newErrors);
      if (!newErrors.length) {
        proceedTask({ communicationId: communication.id, taskId, prospectId: prospect.id }).then(onTaskStatusChanged);
      }
    }
  };

  const onRejectClick = (option: IDropdownListOption) => {
    setErrors([]);
    cancelTask({
      communicationId: communication.id,
      taskId,
      cancelReason: {
        cancelReasonId: option.id.toString(),
        productId: productReasons?.[0]?.id,
      },
    }).then(onTaskStatusChanged);
  };

  const onFailClick = (option: IDropdownListOption) => {
    setErrors([]);
    fail({ communicationId: communication.id, failureReasonId: option.id.toString() }).then(onTaskStatusChanged);
  };

  const callback = (date?: Date) => {
    setCallbackDate(date);
    setIsShowCallbackModal(true);
  };

  const onSubmitCallback = (values: any) => {
    const { datetime, commentForCall } = values;
    if (datetime && datetime.toUTCString()) {
      setErrors([]);
      reschedule({
        communicationId: communication.id,
        to: datetime.toUTCString(),
        comment: commentForCall,
      }).then(onTaskCallBack);
    }
  };

  const onDialOut = (option) => {
    setDialOutReason(option.id);
    setIsShowDialOutModal(true);
  };

  const onSubmitDialOut = (values: { commentForDialOut: string }) => {
    const { commentForDialOut } = values;
    if (dialOutReason) {
      setErrors([]);
      postpone({
        communicationId: communication.id,
        comment: commentForDialOut,
        cancellationReason: dialOutReason,
      }).then(onTaskCallBack);
    }
  };

  const onTakeTask = () => {
    applyCommunication(taskId).then((newCommunication) => {
      if (newCommunication?.hasAvailableTask && newCommunication?.activeCommunicationId) {
        history.push(`/communication/${newCommunication.activeCommunicationId}`);
      }
    });
  };

  const canBeTaken =
    [PlatformCRMWeb.CommunicationState.POSTPONED, PlatformCRMWeb.CommunicationState.RESCHEDULED, PlatformCRMWeb.CommunicationState.CLOSED].includes(
      communication.state
    ) && communication.taskList.some(({ task }) => [PlatformCRMWeb.TaskState.POSTPONED, PlatformCRMWeb.TaskState.RESCHEDULED].includes(task.state));

  return (
    <Nav style={{ top: 'auto', padding: 0 }} className="layout-navbar navbar fixed-bottom bg-footer-theme shadow-lg">
      <div className="container-fluid d-flex flex-wrap justify-content-between text-center container-p-x pb-3">
        <div className="mt-3 d-flex">
          <div className="d-flex" style={{ borderRight: '1px solid #f1f1f2' }}>
            <div className="px-2">
              <div className="text-muted small text-left">Итого</div>
              <div className="d-flex justify-content-between align-items-center">
                <span style={{ fontSize: '16px' }} className="footer-text text-success font-weight-bold">
                  {parsePrice(prospect?.price?.totalPrice)}
                </span>
              </div>
            </div>
            <div className="px-2">
              <div className="text-muted small text-left">Без скидок</div>
              <div className="d-flex justify-content-between align-items-center">
                <span style={{ fontSize: '16px' }} className="footer-text">
                  {parsePrice(prospect?.price?.subTotalPrice)}
                </span>
              </div>
            </div>
            <div className="px-2">
              <div className="text-muted small text-left">Время</div>
              <div className="d-flex justify-content-between align-items-center">
                <span style={{ fontSize: '16px' }} className="footer-text">
                  {parseDuration(prospect?.price?.totalDuration)}
                </span>
              </div>
            </div>
          </div>
          <div className="px-2">
            <div className="text-muted small text-left">Скидка</div>
            <div className="d-flex justify-content-between align-items-center mt-1">
              <span className="footer-text small">
                {parseDiscount(prospect?.price?.totalPrice, prospect?.price?.subTotalPrice, prospect?.price?.bonusesUsed)}
              </span>
            </div>
          </div>
          <div className="px-2">
            <div className="text-muted small text-left">Баллы</div>
            <div className="d-flex justify-content-between align-items-center mt-1">
              <span className="footer-text small">{parseBonuses(prospect?.price?.bonusesUsed)}</span>
            </div>
          </div>
          <div className="px-2">
            <div className="text-muted small text-left">Мин. оплата</div>
            <div className="d-flex justify-content-between align-items-center mt-1">
              <span className="footer-text small">
                {parseMinimumPartOfOrderPayment(prospect?.price?.minPaymentPercent, prospect?.price?.subTotalPrice)}
              </span>
            </div>
          </div>
        </div>
        {communication.managerSsoId === manager?.ssoUid && communication.state === PlatformCRMWeb.CommunicationState.ASSIGNED && (
          <div className="pt-3 d-flex">
            <div className="px-1">
              <Button variant="success" disabled={isLoading} onClick={onApply}>
                <ButtonTitle isLoading={isLoading} title="Оформить заказ" />
              </Button>
            </div>
            <div className="px-1">
              <DropdownButton
                id="callback"
                variant="primary"
                title={<ButtonTitle isLoading={isLoading} title="Перезвон" />}
                className="d-inline-block"
                disabled={isLoading}
                alignRight={isRTL}
                align="leftBottom"
              >
                <DropdownList
                  options={dictionary.selectOptions.callbackPresets}
                  onClick={(option) => callback(option?.getDate && option.getDate())}
                />
              </DropdownButton>
            </div>
            {cancelReasonOptions && (
              <div className="px-1">
                <DropdownButton
                  id="rejection"
                  title={<ButtonTitle isLoading={isLoading} title="Отказ" />}
                  className="d-inline-block btn-rejection"
                  disabled={isLoading}
                  alignRight={isRTL}
                >
                  <DropdownList options={cancelReasonOptions} onClick={onRejectClick} align="leftBottom" />
                </DropdownButton>
              </div>
            )}
            <div className="px-1">
              <DropdownButton
                id="postpone"
                variant="secondary"
                title={<ButtonTitle isLoading={isLoading} title="Недозвон" />}
                className="d-inline-block"
                disabled={isLoading}
                alignRight={isRTL}
              >
                <DropdownList options={dictionary.selectOptions.communicationCancellationReasons} onClick={onDialOut} align="leftBottom" />
              </DropdownButton>
            </div>
            {failReasonOptions && (
              <div className="px-1">
                <DropdownButton
                  id="fail"
                  variant="danger"
                  title={<ButtonTitle isLoading={isLoading} title="Закрыть все" />}
                  className="d-inline-block"
                  disabled={isLoading}
                  alignRight={isRTL}
                >
                  <DropdownList options={failReasonOptions} onClick={onFailClick} align="leftBottom" />
                </DropdownButton>
              </div>
            )}
          </div>
        )}
        {canBeTaken && (
          <div className="pt-3 d-flex">
            <div className="px-1">
              <Button variant="success" disabled={isLoading} onClick={onTakeTask}>
                <ButtonTitle isLoading={isLoading} title="Взять в работу" />
              </Button>
            </div>
          </div>
        )}
      </div>
      <CallbackModal
        isShowModal={isShowCallbackModal}
        closeModal={() => setIsShowCallbackModal(false)}
        onSubmit={onSubmitCallback}
        initialDate={callbackDate}
        isLoading={isActionProcessing}
        comment={communication.taskList.find(({ task }) => task.id === taskId)?.task?.comment}
      />
      <DialOutModal
        isShowModal={isShowDialOutModal}
        closeModal={() => setIsShowDialOutModal(false)}
        onSubmit={onSubmitDialOut}
        isLoading={isActionProcessing}
        initialComment={communication.taskList.find(({ task }) => task.id === taskId)?.task?.comment}
      />
    </Nav>
  );
});

export default ProspectSummary;
