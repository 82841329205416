import React from 'react';

import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { Field, FieldInputProps } from 'react-final-form';
import { FieldLabel } from 'src/components/Fields/FieldLabel';
import { FieldError } from 'src/components/Fields/FieldError';

import { TFieldProp } from 'src/components/Fields/field.interface';

interface AnyObject {
  [key: string]: any;
}

export interface ISelectFieldProp {
  optionValueKey: Partial<keyof AnyObject>;
  optionLabelKey: Partial<keyof AnyObject>;
  options?: AnyObject[];
  variant?: 'dropdown' | 'checkbox' | 'switcher';
}

interface IState {
  options: AnyObject[];
}

export type TSelectFieldProp = ISelectFieldProp & TFieldProp<AnyObject[]>;

export class MultiSelectField extends React.Component<TSelectFieldProp, IState> {
  constructor(props: TSelectFieldProp) {
    super(props);
    const { initialValue } = props;

    this.state = { options: initialValue || [] };
  }

  static defaultProps: Partial<TSelectFieldProp> = {
    isReadonly: false,
    isRequired: false,
  };

  private onChange = (
    onChange: (arg?: Array<Partial<AnyObject>>) => void,
    options: AnyObject[] | null
  ) => {
    onChange(options && options.length ? options : undefined);
  };

  // для кастомных компонентов
  private onPrepareChange = (
    onChange: (arg?: Array<Partial<AnyObject>>) => void,
    option: AnyObject,
    checked: boolean
  ) => {
    const { optionValueKey } = this.props;
    const { options } = this.state;

    if (checked) {
      const changedOptions = [...options, option];

      this.setState({ options: changedOptions }, () => this.onChange(onChange, changedOptions));
    } else {
      const indexChangedOption = options
        .map((selectedOption) => selectedOption[optionValueKey])
        .indexOf(option[optionValueKey]);

      options.splice(indexChangedOption, 1);

      // если не выделять переменную, теряется ре-рендер
      // и значение передаётся только при onBlur
      const changedOptions = [...options];

      this.setState({ options: changedOptions }, () => this.onChange(onChange, changedOptions));
    }
  };

  // для кастомных компонентов
  private isDefaultChecked = (value: string) => {
    const { optionValueKey } = this.props;
    const { options } = this.state;

    return options.map((option) => option[optionValueKey]).indexOf(value) !== -1;
  };

  private renderDropDown = ({ onChange, ...inputRender }: FieldInputProps<any, HTMLElement>) => {
    const {
      isReadonly,
      placeholder = 'Выбор',
      initialValue = [],
      options = [],
      optionValueKey,
      optionLabelKey,
    } = this.props;

    return (
      <Select
        {...inputRender}
        isMulti
        getOptionLabel={(option) => option[optionLabelKey]}
        getOptionValue={(option) => option[optionValueKey]}
        options={options}
        defaultValue={initialValue}
        placeholder={placeholder}
        isSearchable={true}
        isDisabled={isReadonly}
        onChange={(selectedOptions) => this.onChange(onChange, selectedOptions)}
        menuPortalTarget={document.body}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      />
    );
  };

  private renderCheckbox = ({
    onChange,
    checked,
    ...inputRender
  }: FieldInputProps<any, HTMLElement>) => {
    const { isReadonly, options = [], optionValueKey, optionLabelKey } = this.props;

    return options.map((option, key) => (
      <Form.Check
        {...inputRender}
        type="checkbox"
        custom
        key={key}
        id={`${inputRender.name}-${option[optionValueKey]}`}
        label={option[optionLabelKey]}
        disabled={isReadonly}
        defaultChecked={this.isDefaultChecked(option[optionValueKey])}
        onChange={({ currentTarget }) =>
          this.onPrepareChange(onChange, option, currentTarget.checked)
        }
      />
    ));
  };

  private renderSwitcher = ({
    onChange,
    checked,
    ...inputRender
  }: FieldInputProps<any, HTMLElement>) => {
    const { isReadonly, options = [], optionValueKey, optionLabelKey } = this.props;

    return options.map((option, key) => (
      <div className="switchers-stacked" key={key}>
        <label className="switcher">
          <input
            {...inputRender}
            type="checkbox"
            className="switcher-input"
            id={`${inputRender.name}-${option[optionValueKey]}`}
            disabled={isReadonly}
            defaultChecked={this.isDefaultChecked(option[optionValueKey])}
            onChange={({ currentTarget }) =>
              this.onPrepareChange(onChange, option, currentTarget.checked)
            }
          />
          <span className="switcher-indicator">
            <span className="switcher-yes"></span>
            <span className="switcher-no"></span>
          </span>
          <span className="switcher-label">{option[optionLabelKey]}</span>
        </label>
      </div>
    ));
  };

  render() {
    const { name, isRequired, label, variant = 'dropdown' } = this.props;
    const { options } = this.state;

    return (
      <Field
        name={name}
        type="checkbox"
        // если не использовать прямую ссылку на state
        // будет inifinity loop
        initialValue={options.length ? options : undefined}
        render={({ input, meta }) => (
          <Form.Group controlId={name}>
            <FieldLabel {...label} isRequired={isRequired} />
            {/dropdown/.test(variant) && this.renderDropDown(input)}
            {/checkbox/.test(variant) && this.renderCheckbox(input)}
            {/switcher/.test(variant) && this.renderSwitcher(input)}
            <FieldError error={meta.error} />
          </Form.Group>
        )}
      />
    );
  }
}
