import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import DynamicForm from 'src/components/Forms/DynamicForm';
import { FIELDS, TForm } from 'src/components/Forms/form.interface';
import ProspectStore, { ProspectDto } from 'src/store/ProspectStore';
import { formatPhone } from 'src/utils/formatters';

interface IProps {
  prospect: ProspectDto;
  communicationId: string;
}

function normalizePhone(val: string) {
  val = val.replace(/[^\d]+/g, '');
  return `7${val}`;
}

function isValidPhone(phone: string) {
  return phone != null && phone.length === 11 && phone[0] === '7';
}

// данная форма используется, если клиент не известен (анонимный лид)
const ProspectLead = observer((props: IProps) => {
  const { prospect } = props;
  const { upsertProspect } = ProspectStore;
  const [form, setForm] = useState<TForm>({ name: 'person-contact-edit', rows: [] });

  useEffect(() => {
    const dynamicForm = {
      name: 'person-edit',
      rows: [
        [
          {
            type: FIELDS.FIO,
            name: 'fio',
            label: {
              label: 'ФИО',
            },
            initialValue: prospect.fio,
            isReadonly: false,
          },
        ],
        [
          {
            type: FIELDS.MOBILE_PHONE,
            name: 'phone',
            label: {
              label: 'Телефон',
            },
            initialValue: formatPhone(prospect.phone || '', true),
          },
        ],
        [
          {
            type: FIELDS.STRING,
            name: 'email',
            label: {
              label: 'Эл. почта',
            },
            initialValue: prospect.email,
          },
        ],
        [
          {
            type: FIELDS.STRING,
            name: 'leadCode',
            label: {
              label: 'Код лида',
            },
            initialValue: prospect.phone,
          },
        ],
      ],
    };
    setForm(dynamicForm);
  }, [prospect]);

  const onClickSubmit = (values) => {
    const normalizedPhoneValue = normalizePhone(values.phone);
    const isPhoneValid = isValidPhone(normalizedPhoneValue);

    upsertProspect({
      ...values,
      regionId: prospect.regionId,
      phone: isPhoneValid ? formatPhone(`7${values.phone}`) : values.leadCode,
      id: prospect.id,
    });
  };

  return (
    <Card className="mb-4">
      <Card.Header className="d-flex justify-content-between" as="h6">
        <span>Новый контакт</span>
      </Card.Header>
      <Card.Body>
        <DynamicForm form={form} onSubmit={onClickSubmit} isAutoSubmit />
        {!isValidPhone(prospect.phone) && (
          <CopyToClipboard text={prospect.phone}>
            <Button variant="primary">Копировать код</Button>
          </CopyToClipboard>
        )}
      </Card.Body>
    </Card>
  );
});

export default ProspectLead;
