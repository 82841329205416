import { action, observable, runInAction } from 'mobx';
import GrpcService, { PlatformCRMWeb } from 'src/services/GrpcService';
import { logger } from '@qlean/front-logger';
import ManagerStore from '../ManagerStore';
import { IError } from '../ProspectStore';
import {
  CommunicationDto,
  FindClientCommunicationsRequestDto,
  FindCommunicationsResponseDto,
  GetManyProductReasonsResponseDto,
  GetManyReasonsResponseDto,
  ITaskCompletedResponse,
  ProductReasonDto,
  ReasonDto,
} from '.';
import { plainToClass } from 'class-transformer';

export default class CommunicationStore {
  @observable activeCommunicationId?: string;
  @observable hasAvailableTask = false;
  @observable checkLoading = false;
  @observable isErrorOnCheckTask = false;
  @observable isActionProcessing = false; // признак того, что выполняется действие над текущей задачей

  @observable communication?: CommunicationDto;
  @observable clientCommunications?: CommunicationDto[];
  @observable managerCommunications?: CommunicationDto[];
  @observable missCallsCount?: number;

  @observable recentCommunication?: CommunicationDto;
  @observable recentCommunicationLoading?: boolean;
  @observable isLoadingReasons = false;
  @observable failReasons: ReasonDto[] = [];
  @observable isLoadingProductReasons = false;
  @observable productReasons: ProductReasonDto[] = [];

  @observable isLoadingManagerCommunications = false;

  @observable errors: IError[] = [];

  @action setErrors = (errors: IError[]) => {
    logger.debug('[CommunicationStore:setErrors]', { errors });
    this.errors = errors;
  };

  @action checkTask = async (): Promise<boolean> => {
    const { manager } = ManagerStore;
    if (this.checkLoading) {
      return false;
    }
    if (!manager?.ssoUid) {
      logger.error("[CommunicationStore.checkTask] manager ssoUid doesn't exist");
      return false;
    } else {
      const args: PlatformCRMWeb.ICheckCommunicationRequest = { managerSsoId: manager.ssoUid };
      logger.debug(`[CommunicationStore.checkTask] run`, args);
      this.checkLoading = true;
      try {
        const result = await GrpcService.PlatformCRMWeb.CommunicationService.Check(args);
        runInAction(() => {
          logger.debug(`[CommunicationStore.checkTask] ok`, result);
          this.checkLoading = false;
          this.activeCommunicationId = result.activeCommunicationId;
          this.hasAvailableTask = result.hasAvailableTask;
        });
      } catch (err) {
        logger.error(`[CommunicationStore.checkTask] error`, err);
        this.checkLoading = false;
        this.isErrorOnCheckTask = true;
      }

      return true;
    }
  };

  @action
  applyCommunication = async (taskId?: string): Promise<PlatformCRMWeb.IApplyCommunicationResponse | null> => {
    if (this.isActionProcessing) {
      return null;
    } else {
      const args: PlatformCRMWeb.IApplyCommunicationRequest = {
        taskId,
      };
      logger.debug(`[CommunicationStore.applyCommunication] run`, args);
      try {
        const result = await GrpcService.PlatformCRMWeb.CommunicationService.Apply(args);
        runInAction(() => {
          logger.debug(`[CommunicationStore.applyCommunication] ok`, result);
          this.isActionProcessing = false;
          this.activeCommunicationId = result.activeCommunicationId;
          this.hasAvailableTask = result.hasAvailableTask;
        });
        return result;
      } catch (err) {
        logger.error(`[CommunicationStore.applyCommunication] error`, err);
        this.isActionProcessing = false;
      }
      return null;
    }
  };

  @action
  getByIdCommunication = async (args: PlatformCRMWeb.ICommunicationByIdRequest) => {
    if (this.isActionProcessing) {
      return;
    }
    this.isActionProcessing = true;
    logger.debug(`[CommunicationStore.getByIdCommunication] run`, args);
    try {
      const communicationResponse = await GrpcService.PlatformCRMWeb.CommunicationService.GetById(args);
      const { result: missCallsCount } = await GrpcService.PlatformCRMWeb.CommunicationService.GetMissCallsCount(args);
      const communication = plainToClass(CommunicationDto, {
        ...communicationResponse,
        missCallsCount,
      });
      runInAction(() => {
        logger.debug(`[CommunicationStore.getByIdCommunication] ok`, communication);
        this.isActionProcessing = false;
        this.communication = communication;
      });
    } catch (err) {
      logger.error(`[CommunicationStore.getByIdCommunication] error`, err);
      this.isActionProcessing = false;
    }
  };

  @action
  cancelTask = async (args: PlatformCRMWeb.ICancelTaskRequest): Promise<ITaskCompletedResponse> => {
    if (this.isActionProcessing) {
      return { isOk: false };
    }
    this.isActionProcessing = true;
    logger.debug(`[CommunicationStore.cancelTask] run`, args);
    try {
      const result = await GrpcService.PlatformCRMWeb.CommunicationService.CancelTask(args);
      const communication = plainToClass(CommunicationDto, result);

      const newTaskId = communication?.taskList?.find(({ task }) => task.state === PlatformCRMWeb.TaskState.IN_WORK)?.task.id;
      logger.debug(`[CommunicationStore.cancelTask] ok`, result);

      runInAction(() => {
        this.isActionProcessing = false;
        this.communication = communication;
      });
      return { isOk: true, newTaskId };
    } catch (err) {
      logger.error(`[CommunicationStore.cancelTask] error`, err);
      this.isActionProcessing = false;
      return { isOk: false };
    }
  };

  @action
  proceedTask = async (args: PlatformCRMWeb.IProceedTaskRequest): Promise<ITaskCompletedResponse> => {
    if (this.isActionProcessing) {
      return { isOk: false, orderId: '' };
    }
    this.isActionProcessing = true;
    logger.debug(`[CommunicationStore.proceedTask] run`, args);
    try {
      const { communication: result, paymentStatus, orderId } = await GrpcService.PlatformCRMWeb.CommunicationService.ProceedTask(args);
      const communication = plainToClass(CommunicationDto, result);

      const newTaskId = communication?.taskList?.find(({ task }) => task.state === PlatformCRMWeb.TaskState.IN_WORK)?.task.id;
      logger.debug(`[CommunicationStore.proceedTask] ok`, { result, paymentStatus, orderId });

      runInAction(() => {
        this.isActionProcessing = false;
        this.communication = communication;
      });

      return {
        isOk: true,
        newTaskId,
        paymentStatus,
        orderId,
      };
    } catch (err) {
      logger.error(`[CommunicationStore.proceedTask] error`, err);
      this.isActionProcessing = false;
      return { isOk: false, orderId: '' };
    }
  };

  @action
  proceedTicketTask = async (args: PlatformCRMWeb.IProceedTicketTaskRequest): Promise<ITaskCompletedResponse> => {
    if (this.isActionProcessing) {
      return { isOk: false };
    }
    this.isActionProcessing = true;
    logger.debug(`[CommunicationStore.proceedTicketTask] run`, args);
    try {
      const result = await GrpcService.PlatformCRMWeb.CommunicationService.ProceedTicketTask(args);
      const communication = plainToClass(CommunicationDto, result);

      const newTaskId = communication?.taskList?.find(({ task }) => task.state === PlatformCRMWeb.TaskState.IN_WORK)?.task.id;
      logger.debug(`[CommunicationStore.proceedTask] ok`, result);

      runInAction(() => {
        this.isActionProcessing = false;
        this.communication = communication;
      });
      return { isOk: true, newTaskId };
    } catch (err) {
      logger.error(`[CommunicationStore.proceedTask] error`, err);
      this.isActionProcessing = false;
      return { isOk: false };
    }
  };

  @action
  addTask = async (args: PlatformCRMWeb.IAddTaskRequest): Promise<boolean> => {
    if (this.isActionProcessing) {
      return false;
    }
    this.isActionProcessing = true;
    logger.debug(`[CommunicationStore.addTask] run`, args);
    try {
      const result = await GrpcService.PlatformCRMWeb.CommunicationService.AddTask(args);
      const communication = plainToClass(CommunicationDto, result);

      logger.debug(`[CommunicationStore.addTask] ok`, result);

      runInAction(() => {
        this.isActionProcessing = false;
        this.communication = communication;
      });
      return true;
    } catch (err) {
      logger.error(`[CommunicationStore.addTask] error`, err);
      this.isActionProcessing = false;
    }
    return false;
  };

  @action
  reschedule = async (args: PlatformCRMWeb.IRescheduleRequest): Promise<boolean> => {
    if (this.isActionProcessing) {
      return false;
    }
    this.isActionProcessing = true;
    logger.debug(`[CommunicationStore.reschedule] run`, args);
    try {
      const result = await GrpcService.PlatformCRMWeb.CommunicationService.Reschedule(args);
      const communication = plainToClass(CommunicationDto, result);

      logger.debug(`[CommunicationStore.reschedule] ok`, result);

      runInAction(() => {
        this.isActionProcessing = false;
        this.communication = communication;
      });
      return true;
    } catch (err) {
      logger.error(`[CommunicationStore.reschedule] error`, err);
      this.isActionProcessing = false;
    }
    return false;
  };

  @action
  postpone = async (args: PlatformCRMWeb.IPostponeRequest): Promise<boolean> => {
    if (this.isActionProcessing) {
      return false;
    }
    this.isActionProcessing = true;
    logger.debug(`[CommunicationStore.postpone] run`, args);
    try {
      const result = await GrpcService.PlatformCRMWeb.CommunicationService.Postpone(args);
      const communication = plainToClass(CommunicationDto, result);

      logger.debug(`[CommunicationStore.postpone] ok`, result);

      runInAction(() => {
        this.isActionProcessing = false;
        this.communication = communication;
      });
      return true;
    } catch (err) {
      logger.error(`[CommunicationStore.postpone] error`, err);
      this.isActionProcessing = false;
    }
    return false;
  };

  @action
  fail = async (args: PlatformCRMWeb.IFailRequest): Promise<ITaskCompletedResponse> => {
    if (this.isActionProcessing) {
      return { isOk: false };
    }
    this.isActionProcessing = true;
    logger.debug(`[CommunicationStore.fail] run`, args);
    try {
      const result = await GrpcService.PlatformCRMWeb.CommunicationService.Fail(args);
      const communication = plainToClass(CommunicationDto, result);

      runInAction(() => {
        this.isActionProcessing = false;
        this.communication = communication;
      });
      return { isOk: true };
    } catch (err) {
      logger.error(`[CommunicationStore.fail] error`, err);
      this.isActionProcessing = false;
    }
    return { isOk: false };
  };

  @action
  findClientCommunications = async (args: FindClientCommunicationsRequestDto): Promise<boolean> => {
    if (this.isActionProcessing) {
      return false;
    }
    this.isActionProcessing = true;
    logger.debug(`[CommunicationStore.findClientCommunication] run`, args);
    try {
      const result = await GrpcService.PlatformCRMWeb.CommunicationService.FindCommunications(args);
      const { data } = plainToClass(FindCommunicationsResponseDto, result);
      logger.debug(`[CommunicationStore.findClientCommunication] ok`, result);

      runInAction(() => {
        this.isActionProcessing = false;
        this.clientCommunications = data;
      });
      return true;
    } catch (err) {
      logger.error(`[CommunicationStore.findClientCommunication] error`, err);
      this.isActionProcessing = false;
    }
    return false;
  };

  @action
  getManyReasons = async (args: PlatformCRMWeb.IGetManyReasonsRequest): Promise<boolean> => {
    if (this.isLoadingReasons) {
      return false;
    }
    this.isLoadingReasons = true;
    logger.debug(`[CommunicationStore.getManyReasons] run`, args);
    try {
      const result = await GrpcService.PlatformCRMWeb.CommunicationService.GetManyReasons(args);
      const { data } = plainToClass(GetManyReasonsResponseDto, result);
      logger.debug(`[CommunicationStore.getManyReasons] ok`, data);

      runInAction(() => {
        this.isLoadingReasons = false;
        this.failReasons = data;
      });
      return true;
    } catch (err) {
      logger.error(`[CommunicationStore.getManyReasons] error`, err);
      this.isLoadingReasons = false;
    }
    return false;
  };

  @action
  getManyProductReasons = async (args: PlatformCRMWeb.IGetManyProductReasonsRequest): Promise<boolean> => {
    if (this.isLoadingProductReasons) {
      return false;
    }
    this.isLoadingProductReasons = true;
    logger.debug(`[CommunicationStore.getManyProductReasons] run`, args);
    try {
      const result = await GrpcService.PlatformCRMWeb.CommunicationService.GetManyProductReasons(args);
      const { data } = plainToClass(GetManyProductReasonsResponseDto, result);
      logger.debug(`[CommunicationStore.getManyProductReasons] ok`, data);

      runInAction(() => {
        this.isLoadingProductReasons = false;
        this.productReasons = data;
      });
      return true;
    } catch (err) {
      logger.error(`[CommunicationStore.getManyProductReasons] error`, err);
      this.isLoadingProductReasons = false;
    }
    return false;
  };

  @action
  createCommunication = async (args: PlatformCRMWeb.ICreateCommunicationRequest): Promise<string | undefined> => {
    if (this.isActionProcessing) {
      return;
    }
    this.isActionProcessing = true;
    logger.debug(`[CommunicationStore.createCommunication] run`, args);
    try {
      const result = await GrpcService.PlatformCRMWeb.CommunicationService.CreateCommunication(args);
      const communication = plainToClass(CommunicationDto, result);

      logger.debug(`[CommunicationStore.createCommunication] ok`, result);

      runInAction(() => {
        this.isActionProcessing = false;
        this.activeCommunicationId = communication.id;
        this.communication = communication;
      });
      return communication.id;
    } catch (err) {
      logger.error(`[CommunicationStore.createCommunication] error`, err);
      this.isActionProcessing = false;
    }
    return;
  };

  @action
  findRecentCommunication = async (args: PlatformCRMWeb.IFindRecentCommunicationRequest): Promise<void> => {
    if (this.recentCommunicationLoading) {
      return;
    }
    this.recentCommunicationLoading = true;
    this.recentCommunication = undefined;
    try {
      const result = await GrpcService.PlatformCRMWeb.CommunicationService.FindRecentCommunication(args);

      const recentCommunication = plainToClass(CommunicationDto, result.data);
      logger.debug(`[CommunicationStore.recentCommunication] ok`, recentCommunication);

      runInAction(() => {
        this.recentCommunicationLoading = false;
        this.recentCommunication = recentCommunication;
      });
    } catch (err) {
      logger.error(`[CommunicationStore.findRecentCommunication] error`, err);
    }
  };
}
