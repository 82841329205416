import React from 'react';
import { observer } from 'mobx-react';
import { useHistory, useParams } from 'react-router-dom';

import CommunicationStore from 'src/store/CommunicationStore';
import ManagerStore from 'src/store/ManagerStore';

import TaskPinger from './TaskPinger';
import TaskModal from './TaskModal';
import { PlatformCRMWeb } from 'src/services/GrpcService';

interface IRouteParams {
  communicationId: string;
  taskId?: string;
}

const TaskNotifier = observer(() => {
  const {
    applyCommunication,
    checkLoading,
    activeCommunicationId,
    hasAvailableTask,
    isErrorOnCheckTask,
  } = CommunicationStore;
  const { manager } = ManagerStore;

  const history = useHistory();
  const { communicationId } = useParams<IRouteParams>();
  if (isErrorOnCheckTask) {
    return (
      <>
        <TaskModal isError={isErrorOnCheckTask} />
      </>
    );
  }

  if (manager?.state !== PlatformCRMWeb.ManagerState.ONLINE) {
    return (
      <div className="container-fluid d-flex flex-wrap justify-content-between text-center container-p-x pb-3">
        <div className="pt-3">
          <span className="footer-text font-weight-bold">
            Переведите статус менеджера в онлайн, чтобы увидеть доступные задачи
          </span>
        </div>
      </div>
    );
  }

  // если уже находимся на текущей задаче - не показываем
  if (communicationId) {
    return null;
  }

  // если нет активной задачи и мы находимся на задачик кототрую можно взять в работу
  // то показать кнопку "Взять задачу"

  // если есть активная задача, то предлагаем перейти к ней
  if (activeCommunicationId) {
    return (
      <>
        <TaskModal
          buttonText="Перейти к задаче"
          onClick={() => history.push(`/communication/${activeCommunicationId}`)}
        >
          Уже есть активная задача
        </TaskModal>
        <TaskPinger />
      </>
    );
  }

  // если активной задачи нет, но есть доступная, предлагаем взять ее в работу
  // после успешного взятия, нужно на нее перекинуть
  if (!activeCommunicationId && hasAvailableTask) {
    return (
      <>
        <TaskModal
          buttonText="Взять задачу в работу"
          isLoading={checkLoading}
          onClick={() => {
            applyCommunication().then((result) => {
              if (result) {
                history.push(`/communication/${result.activeCommunicationId}`);
              }
            });
          }}
        >
          Есть доступная задача
        </TaskModal>
        <TaskPinger />
      </>
    );
  }

  return (
    <>
      <TaskModal isLoading={checkLoading}>Нет доступных задач</TaskModal>
      <TaskPinger />
    </>
  );
});

export default TaskNotifier;
