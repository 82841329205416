import React, {useEffect, useState} from 'react';
import {Col, Form} from 'react-bootstrap';
import { observer } from "mobx-react";

import metaStore from '../../../store/MetaStore';
import {Field} from "react-final-form";
import {FieldLabel} from "../FieldLabel";
import ru from 'date-fns/locale/ru';
import 'src/vendor/libs/react-datepicker/react-datepicker.scss';
import DatePicker from 'react-datepicker';
import Select from 'react-select';

import {FieldError} from "../FieldError";
import { PlatformCRMWeb } from "../../../services/GrpcService";
import { logger } from '@qlean/front-logger';
import {TFieldProp} from "../field.interface";

interface IProps {
  name: string
  placeholder?: string;
  isInvalid?: boolean;
  isRequired?: boolean;
  readOnly?: boolean;
  initialValue?: PlatformCRMWeb.IDrycleaningSlot;
  variant?: 'pickup' | 'return',
  errors?: string;
}

interface IOption {
  value: string,
  title: string
}

export type TDrycleaningSlotsField = IProps & TFieldProp<IProps>;

export const DrycleaningSlots = observer((props: TDrycleaningSlotsField) => {
  const { isRequired, isReadonly } = props;
  const { dryCleaningSlots, dryCleaningSlotsLoading } = metaStore;

  const [ error, setError ] = useState<string>('');
  const [ date, setDate ] = useState<Date>();
  const [ timeSlot, setTimeSlot ] = useState<IOption>();
  const [ options, setOptions ] = useState<IOption[]>([]);

  useEffect(() => {
    if (props.errors) {
      setError(props.errors);
    }
  }, [props.errors]);

  const onFocus = () => {
    setError('');
  };

  const onBlur = () => {
    if (!timeSlot) {
      setError('Выбирете время');
    }
  };

  useEffect(() => {
    const selected = dryCleaningSlots?.find(item => item.id === props.initialValue?.id);
    if (selected && selected.startAt) {
      setDate(new Date(selected.startAt));
      setTimeSlot({
        value: props.initialValue?.id || '',
        title: props.initialValue?.timeRange || '',
      });
    }
  }, [ dryCleaningSlots, props.initialValue ]);

  useEffect(() => {
    const yy = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
    const mm = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
    const dd = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
    let items = dryCleaningSlots?.filter((item) => item.date === `${yy}-${mm}-${dd}`);

    if (props.variant === 'pickup') {
      items = items?.filter(item => !!item.pickup);
    } else {
      items = items?.filter(item => !!item['return']);
    }

    const result = items?.map(item => ({ value: item.id || '', title: item.timeRange || '' })) || [];

    result.unshift({
      title: `Выберите время ${props.variant === 'pickup' ? 'забора' : 'доставки'}`,
      value: '',
    });

    logger.info(`[DrycleaningSlots] set options`, { result, date: `${yy}-${mm}-${dd}` });
    setOptions(result);
  }, [ date ]);

  return (
    <Field
      name={props.name}
      render={({ input }) => (
        <Form.Row>
          <Form.Group className="mb-0" controlId={props.name} as={Col} md={6}>
            <FieldLabel label={`Дата ${props.variant === 'pickup' ? 'забора' : 'доставки'}`} isRequired={isRequired} />
            <DatePicker
              className={`form-control ${error ? 'is-invalid' : ''}`}
              selected={date}
              dateFormat="dd MMMM yyyy"
              locale={ru}
              placeholderText={`Выберите дату ${props.variant === 'pickup' ? 'забора' : 'доставки'}`}
              disabled={isReadonly || dryCleaningSlotsLoading || !dryCleaningSlots?.length}
              includeDates={dryCleaningSlots?.filter(item =>
                props.variant === 'pickup' && item.pickup || props.variant === 'return' && item['return'])
                .map(item => item.startAt ? new Date(item.startAt) : null)
              }
              onChange={dt => {
                setDate(dt);
                setTimeSlot(options[0]);
              }}
            />
            <FieldError error={error ? `${error}` : 'Не правильная дата'} />
          </Form.Group>
          <Form.Group className="mb-0" controlId={props.name} as={Col} md={6}>
            <FieldLabel label={`Время ${props.variant === 'pickup' ? 'забора' : 'доставки'}`} isRequired={isRequired} />
            <Select
              onBlur={onBlur}
              onFocus={onFocus}
              options={date ? options : []}
              getOptionLabel={(option) => option.title}
              getOptionValue={(option) => option.value}
              isDisabled={ isReadonly || options.length === 1 }
              defaultValue={options.find(
                ({ value }) => value === props.initialValue?.id,
              )}
              value={ timeSlot }
              className="react-select"
              classNamePrefix="react-select"
              onChange={(res) => {
                const option = res as IOption;
                const slot = dryCleaningSlots?.find(item => item.id === option.value);
                setTimeSlot(option);
                input.onChange(slot);
              }}
            />
          </Form.Group>
          <div className="mx-1">
            <Form.Control
              readOnly={true}
              hidden={true}
              isInvalid={!!error}
            />
            <FieldError error={error ? `${error}` : 'Не правильное время'} />
          </div>
        </Form.Row>
      )}/>
  );
});

