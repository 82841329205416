import React, { useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';

import { Button, Nav, Navbar } from 'react-bootstrap';
import { ReactComponent as Logo } from './logo.svg';
import OnlineStatus from 'src/features/Communication/OnlineStatus';
import ManagerStore from 'src/store/ManagerStore';
import CommunicationStore from 'src/store/CommunicationStore';

import layoutHelpers from './helpers';
import { CreateProspectModal } from 'src/components/CreateProspectModal';
import { PlatformCRMWeb } from 'src/services/GrpcService';
import { ISearchResult, SearchCustomer, SearchResultType } from 'src/components/SearchCustomer';
import { logger } from '@qlean/front-logger';

interface IProps {
  navbarBg?: string;
  sidenavToggle?: boolean;
}

const LayoutHeader = observer((props: IProps) => {
  const { manager } = ManagerStore;
  const { activeCommunicationId } = CommunicationStore;
  const history = useHistory();
  const toggleSidenav = (e: React.MouseEvent) => {
    e.preventDefault();
    layoutHelpers.toggleCollapsed();
  };
  const [showCreateProspectModal, setShowCreateProspectModal] = useState(false);
  const showAddCommunication =
    !activeCommunicationId && manager?.state === PlatformCRMWeb.ManagerState.ONLINE;

  const Divider = (
    <div
      className={
        'nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25 mr-3 ml-1'
      }
    >
      {'|'}
    </div>
  );

  return (
    <Navbar
      bg={props.navbarBg}
      expand={'lg'}
      style={{ minHeight: '46px' }}
      className={
        'layout-navbar align-items-lg-center container-p-x navbar navbar-expand-lg navbar-light bg-navbar-theme'
      }
    >
      {/* Brand demo (see src/demo.css) */}
      <Navbar.Brand as={NavLink} to={'/'} className={'app-brand demo d-lg-none py-0 mr-4'}>
        <Logo className={'app-brand-logo demo bg-primary'} />
        <span className={'app-brand-text demo sidenav-text font-weight-normal ml-2'}>
          Platform CRM UI
        </span>
      </Navbar.Brand>

      {/* Sidenav toggle (see src/demo.css) */}
      {props.sidenavToggle && (
        <Nav className={'layout-sidenav-toggle d-lg-none align-items-lg-center mr-auto'}>
          <Nav.Item
            as={'a'}
            className={'nav-link px-0 mr-lg-4'}
            href={'#toggle'}
            onClick={toggleSidenav}
          >
            <i className={'ion ion-md-menu text-large align-middle'} />
          </Nav.Item>
        </Nav>
      )}

      {/* Navbar toggle */}
      <Navbar.Toggle />

      <SearchCustomer
        placeholder="Поиск по номеру телефона"
        onChoose={(result?: ISearchResult) => {
          if (!result) {
            logger.debug('Клиент не найден')
            return;
          }

          if (result.type === SearchResultType.PERSON) {
            history.push(`/customer/${result.id}`);
            return;
          }

          if (result.type === SearchResultType.PROSPECT) {
            history.push(`/task/${result.id}`);
            return;
          }
        }}
        showAddCommunication={showAddCommunication}
      />

      <Navbar.Collapse className="flex-grow-1">
        <Nav className={'align-items-lg-center ml-auto'}>
          {showAddCommunication && (
            <>
              <Button variant="outline-dark" onClick={() => setShowCreateProspectModal(true)}>
                Создать заявку
              </Button>
              {Divider}
            </>
          )}
          {manager?.name && (
            <>
              <span>{manager?.name}</span>
              {Divider}
            </>
          )}
          <OnlineStatus />
        </Nav>
      </Navbar.Collapse>
      <CreateProspectModal
        isShowModal={showCreateProspectModal}
        closeModal={() => setShowCreateProspectModal(false)}
      />
    </Navbar>
  );
});

export default LayoutHeader;
