import React from 'react';

import { Badge, BadgeProps } from 'react-bootstrap';

import { TFieldProp } from 'src/components/Fields/field.interface';

interface AnyObject {
  [key: string]: any;
}

export type TSelectFieldProp = BadgeProps & TFieldProp<AnyObject>;

export const BadgeField = (props: TSelectFieldProp) => {
  const { label, initialValue, bsPrefix, variant, pill } = props;

  return (
    <div className="mb-3">
      <span className="form-label mr-1">{`${label?.label || ''}:`}</span>
      <Badge variant={variant} bsPrefix={bsPrefix} pill={pill}>
        {initialValue}
      </Badge>
    </div>
  );
};
