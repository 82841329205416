import { action, computed, observable, runInAction } from 'mobx';
import 'mobx-react-lite/batchingForReactDom';

import GrpcService from 'src/services/GrpcService';
import { genCsrfToken } from '@qlean/nest-csrf-gen';
import { AuthTokenValues, LoginRequestDto, OtpRequestDto, TokenResponseDto } from './LoginStore.dto';

import { logger } from '@qlean/front-logger';
import { plainToClass } from 'class-transformer';
import * as Sentry from '@sentry/react';

export type TUserProfile = {
  id: string;
  email: string | null;
  phone: string | null;
};

export const REFRESH_TOKEN_TIME = 'RefreshTokenTime';

export default class LoginStore {
  @observable isLoading: boolean = false;
  @observable accessToken?: string;
  @observable refreshToken?: string;

  constructor() {
    this.accessToken = localStorage.getItem('accessToken') || undefined;
    this.refreshToken = localStorage.getItem('refreshToken') || undefined;
    if (this.accessToken) {
      GrpcService.setMetadata({ token: this.accessToken });
    }
    window.addEventListener('storage', (e) => {
      console.log('storage event!', { e });
      if (e.key === 'refreshToken') {
        console.log('[refreshToken] get from another tab', { e });
        logger.debug('[refreshToken] get from another tab', e);
        if (!e.newValue) {
          console.log('LoginStore, exit!!!', { e });
          this.exit();
        }
        this.refreshToken = e.newValue ?? '';
      }
      if (e.key === 'accessToken') {
        this.accessToken = e.newValue ?? '';
        this.refreshMetadata();
      }
    });
    this.configureSentry();
  }

  refreshMetadata() {
    GrpcService.setMetadata({ token: String(localStorage.getItem('accessToken')) });
  }

  setTokens(accessToken = '', refreshToken = '') {
    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
    this.refreshMetadata();
  }

  @computed get userProfile(): TUserProfile | null {
    try {
      const email = sessionStorage.getItem('user_email') || localStorage.getItem('user_email');
      const phone = sessionStorage.getItem('user_phone') || localStorage.getItem('user_phone');
      const id = sessionStorage.getItem('user_id') || localStorage.getItem('user_id');

      if (id) {
        return { id, email, phone };
      }

      return null;
    } catch (err) {
      this.setTokens();

      return null;
    }
  }

  @action exit = () => {
    this.setTokens();
    window.location.href = '/';
  };

  @action submitEmail = (values: AuthTokenValues) => {
    this.isLoading = true;

    const { isRememberMe, ...credentials } = values;

    return GrpcService.PlatformCRMWeb.LoginService.SendEmailAndPass(credentials)
      .then((res) => {
        const { accessToken, refreshToken } = plainToClass(TokenResponseDto, res);
        logger.info('-res-getAuthToken-', res);

        if (accessToken) {
          const id = this.parseToken(accessToken)?.uid;

          if (isRememberMe) {
            localStorage.setItem('user_email', credentials.email);
            localStorage.setItem('user_id', id || '');
          } else {
            sessionStorage.setItem('user_email', credentials.email);
            sessionStorage.setItem('user_id', id || '');
          }
        }

        this.isLoading = false;
        this.setTokens(accessToken, refreshToken);
        return res;
      })
      .catch((e) => {
        logger.error('[LoginStore::submitEmail]', e);
        this.isLoading = false;
      })
      .finally(() => {
        this.configureSentry();
      });
  };

  @action submitPhone = async ({ phone }: OtpRequestDto) => {
    this.isLoading = true;

    localStorage.setItem('phone', phone);
    logger.setConfig({ uuid: phone });

    const payload = {
      connection: 3,
      userType: 3,
      login: phone,
      send: 1,
    };

    const { userAgent } = window.navigator;

    const csrfToken = genCsrfToken({ payload, userAgent });

    return GrpcService.PlatformCRMWeb.LoginService.RequestOtp({ csrfToken, userAgent, ...payload })
      .then((res) => {
        logger.info(`Auth code is`, res.code); // проверить что это будет только для стейджа
        runInAction(() => {
          this.isLoading = false;
        });
        return res;
      })
      .catch((e) => {
        logger.error('[LoginStore::submitPhone]', e);
        this.isLoading = false;
      });
  };

  @action submitCode = (args: LoginRequestDto) => {
    this.isLoading = true;
    const req = { ...args };

    return GrpcService.PlatformCRMWeb.LoginService.Login(req)
      .then((res) => {
        const { accessToken, refreshToken } = res;

        if (accessToken) {
          const id = this.parseToken(accessToken)?.uid;

          sessionStorage.setItem('user_phone', args.phone);
          sessionStorage.setItem('user_id', id || '');
        }

        runInAction(() => {
          this.setTokens(accessToken, refreshToken);
          this.isLoading = false;
        });
        return res;
      })
      .catch((e) => {
        logger.error('[LoginStore::submitCode]', e);
        this.isLoading = false;
      })
      .finally(() => {
        this.configureSentry();
      });
  };

  @action refresh = () => {
    logger.debug(`[refreshAccessToken] start refresh token`);
    localStorage.setItem(REFRESH_TOKEN_TIME, Date.now() + '');
    if (!this.refreshToken) {
      logger.warn(`[refreshAccessToken] empty refresh token`);
      this.setTokens();
      return Promise.reject(new Error(`refreshToken empty`));
    }
    return GrpcService.PlatformCRMWeb.LoginService.RefreshToken(
      {
        refreshToken: this.refreshToken,
      },
      { ignoreInterceptors: true }
    )
      .then(({ refreshToken, accessToken }) => {
        this.setTokens(accessToken, refreshToken);
        logger.debug(`[RefreshToken] refreshed ok`);
        localStorage.removeItem(REFRESH_TOKEN_TIME);
      })
      .catch(({ message }) => {
        logger.debug(`[RefreshToken] refreshed error`, { message });
        localStorage.removeItem(REFRESH_TOKEN_TIME);
        this.exit();
      });
  };

  private configureSentry(): void {
    const userProfile = this.userProfile;

    Sentry.setUser(
      userProfile
        ? {
            email: userProfile.email ?? undefined,
            phone: userProfile.phone ?? undefined,
            id: userProfile.id,
          }
        : null
    );
  }

  private parseToken = (token: string): { uid?: string } => {
    return JSON.parse(window.atob(token.split('.')[1]));
  };
}
