import React from 'react';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { dictionary } from '../../utils/dictionary';
import { TFieldLabelProp } from './field.interface';

export class FieldLabel extends React.Component<TFieldLabelProp> {
  static defaultProps: TFieldLabelProp = {
    isRequired: false,
    labelVisible: true,
  };

  render() {
    const { label, labelVisible, isRequired } = this.props;

    if (!labelVisible) {
      return false;
    }

    const tooltip = (
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id={`${label}_required`}>{dictionary.isRequired}</Tooltip>}
      >
        <i className="ion ion-ios-alert ml-1" />
      </OverlayTrigger>
    );

    return (
      <Form.Label>
        {label}
        {isRequired && tooltip}
      </Form.Label>
    );
  }
}
