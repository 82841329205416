import { Type } from 'class-transformer';
import { PlatformCRMWeb } from 'src/services/GrpcService';

export class ManagerRequestDto implements PlatformCRMWeb.CurrentManagerRequest {
  uid!: string;
}

export class ChangeManagerStatusRequestDto implements PlatformCRMWeb.ChangeManagerStatusRequest {
  uid!: string;
}

export class ManagerSkillDto implements PlatformCRMWeb.IManagerSkill {
  id!: string;
  managerId!: string;
  skillId!: string;
  createdAt!: string;
  updatedAt!: string;
  isDeleted!: boolean;
  deletedAt!: string;
}

export class ManagerDto implements PlatformCRMWeb.IManager {
  id!: string;
  name!: string;
  ssoUid!: string;
  state!: PlatformCRMWeb.ManagerState;
  @Type(() => ManagerSkillDto)
  skills!: ManagerSkillDto[];
}
