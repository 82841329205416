import React, { FC, Fragment } from 'react';
import { Card } from 'react-bootstrap';
import parse from 'html-react-parser';
import { CommunicationDto, TaskDto } from '../store/CommunicationStore';
import { ManagerDto } from '../store/ManagerStore';
import {TicketSummary} from "./TicketSummary";

interface ITicketProps {
  task: TaskDto;
  communication: CommunicationDto;
  manager: ManagerDto;
  isLoading: boolean;
}

export const Ticket: FC<ITicketProps> = ({ task, communication, isLoading, manager }) => {
  return (
    <Fragment>
      <Card>
        <Card.Body>
          <div className="mb-4">
            <h5 className="mb-2 d-block">Название заказа</h5>
            <div>{task.title}</div>
          </div>
          <div className="mb-2 d-block">
            <h5>Комментарий к заказу</h5>
          </div>
          <div>{parse(task.comment || '', { trim: true })}</div>
        </Card.Body>
      </Card>
      <TicketSummary manager={manager} communication={communication} isLoading={isLoading} task={task}/>
    </Fragment>
  );
};
