import React from 'react';

type ErrorFallbackProps = {
  error: Error;
  componentStack: string;
  eventId: string;
  resetError(): void;
};

export const ErrorFallback = ({ eventId, error, componentStack }: ErrorFallbackProps) => (
  <div>
    <h3>На странице произошла ошибка. Попробуйте обновить страницу.</h3>
    <h4>[{eventId}] Ошибка:</h4>
    <pre>{error.stack}</pre>
    <h4>Детальная информация:</h4>
    <div>{componentStack.split('\n').filter(Boolean).join('\n')}</div>
  </div>
);
