import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { Card, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { PlatformCRMWeb } from 'src/services/GrpcService/codegen/app_pb';
import { dictionary } from 'src/utils/dictionary';
import { parseDate } from 'src/utils/formatters';
import grpcService from '../../services/GrpcService';
import OrderStore from '../../store/OrderStore';

interface IRouteParams {
  customerId: string;
}

let baseApiProxyUrl;
async function getBaseApiProxyUrl() {
  if (!baseApiProxyUrl) {
    const { url } = await grpcService.PlatformCRMWeb.MetaService.GetCrmWebURL({});
    baseApiProxyUrl = url;
  }

  return baseApiProxyUrl;
}

export const CustomerOrders = observer(() => {
  const [proxyUrl, setProxyUrl] = React.useState();

  React.useEffect(() => {
    getBaseApiProxyUrl().then((url) => setProxyUrl(url));
  });

  const params = useParams<IRouteParams>();
  const { customerId } = params;
  const { searchOrders, shortOrders } = OrderStore;

  useEffect(() => {
    if (customerId) {
      searchOrders({
        page: 1,
        limit: 50,
        where: { person: { eq: customerId } },
        sort: [{ field: 'startDate', order: PlatformCRMWeb.SortOrder.DESC }],
      });
    }
  }, [customerId]);

  return (
    <Card style={{ maxHeight: '100px', minHeight: 'calc(100% - 30px)', overflowY: 'auto' }} className="m-4 h-100">
      <Table>
        <thead>
          <tr>
            <th>№ заказа</th>
            <th>Продукт</th>
            <th>Статус</th>
            <th>Дата исполнения заказа</th>
            <th>Адрес</th>
            <th>ПВЗ</th>
          </tr>
        </thead>
        <tbody>
          {shortOrders.orders &&
            shortOrders.orders.map((order) => {
              return (
                <tr key={order.id}>
                  <td>
                    <a target="_blank" rel="noopener noreferrer" href={`${proxyUrl}/orders/${order.id}/external_admin_url`}>
                      {order.orderNumber.toString()}
                    </a>
                  </td>
                  <td>{dictionary.selectOptions.serviceTypes.find((serviceType) => serviceType.id === order.serviceType)?.value || 'Ручной лид'}</td>
                  <td>{dictionary.selectOptions.orderStatus.find((status) => status.id === order.status)?.value}</td>
                  <td>{parseDate(order.startDate)}</td>
                  <td>{order.address.value}</td>
                  <td>{order.isService('drycleaning') && order.address.isPickupPoint ? 'Да' : 'Нет'}</td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </Card>
  );
});
