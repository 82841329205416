import React from 'react';

import OrderStore from 'src/store/OrderStore';

import 'src/vendor/styles/pages/users.scss';
import { PlatformCRMWeb } from 'src/services/GrpcService';
import { Calculator } from 'src/components/Calculator/Calculator';
import { OrderDto } from 'src/store/OrderStore';
import { logger } from '@qlean/front-logger';
import { AddressDto } from 'src/store/CustomerStore';
import { observer } from 'mobx-react';

interface IProps {
  order: OrderDto;
  onCalculatorSubmit: (values) => void;
}

const OrderCalculator = observer((props: IProps) => {
  const { order } = props;
  const { isLoading } = OrderStore;
  const product = order.price?.products?.[0];

  const onClickSubmit = (
    products: PlatformCRMWeb.Product[],
    keysDelivery?: AddressDto,
    keysPickup?: AddressDto
  ) => {
    logger.debug('[OrderCalculator] updateProspect', products, keysDelivery, keysPickup);

    const formValues = {
      id: order.id,
      products,
      keysPickup: keysPickup || {},
      keysDelivery: keysDelivery || {},
    };

    props.onCalculatorSubmit(formValues);
  };

  logger.debug('[OrderCalculator] getOrderPrice', order.price);

  return order.regionId && order.serviceType ? (
    <Calculator
      isActiveOrder={true}
      regionId={order.regionId}
      productSlug={order.serviceType}
      product={product}
      onSubmit={onClickSubmit}
      keysDelivery={order.keysDelivery}
      keysPickup={order.keysPickup}
      isLoading={isLoading}
    />
  ) : null;
});

export default OrderCalculator;
