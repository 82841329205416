import { observer } from 'mobx-react';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import DynamicForm from 'src/components/Forms/DynamicForm';
import { FIELDS } from 'src/components/Forms/form.interface';
import CommunicationStore from 'src/store/CommunicationStore';
import { dictionary } from 'src/utils/dictionary';
import { ERROR_MESSAGES, isValidServiceType, validateCreateTask } from 'src/utils/validation';
import { formatPhone } from 'src/utils/formatters';

interface IProps {
  isShowModal: boolean;
  closeModal: () => void;
  phoneNumber?: string;
}

export const CreateProspectModal = observer(({ isShowModal, closeModal, phoneNumber }: IProps) => {
  const { isActionProcessing, createCommunication, errors, setErrors } = CommunicationStore;
  const history = useHistory();
  const onSubmit = async (values) => {
    const serviceType = values?.serviceType?.id;
    const phone = formatPhone(values?.phone || '', true);

    const errors = validateCreateTask({ serviceType, phone });
    setErrors(errors);

    if (!errors.length) {
      const communicationId = await createCommunication({
        element: serviceType,
        phone: `7${phone}`,
        isCall: !!values.isCall,
      });
      if (communicationId) {
        closeModal();
        history.push(`/communication/${communicationId}`);
      }
    }
  };
  const dynamicForm = {
    name: 'prospect-edit',
    rows: [
      [
        {
          type: FIELDS.SELECT,
          name: 'serviceType',
          label: {
            label: 'Продукт',
          },
          placeholder: 'Выберите продукт',
          optionValueKey: 'id',
          optionLabelKey: 'value',
          options: dictionary.selectOptions.serviceTypes,
          variant: 'dropdown',
          defaultError: ERROR_MESSAGES.SERVICE_TYPE,
          isValid: isValidServiceType,
          errors: errors.find((error) => error.type === 'serviceType')?.message,
        },
      ],
      [
        {
          type: FIELDS.BOOLEAN,
          name: 'isCall',
          placeholder: 'Автодозвон',
          label: {
            labelVisible: false,
          },
        },
      ],
      [
        {
          type: FIELDS.MOBILE_PHONE,
          name: 'phone',
          initialValue: phoneNumber,
          hideCopyButton: true,
          label: {
            label: 'Телефон клиента',
          },
          placeholder: 'Введите телефон клиента',
          errors: errors.find((error) => error.type === 'phone')?.message,
        },
      ],
    ],
  };
  return (
    <Modal show={isShowModal} size="sm" onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title as="h5">Создать заявку</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DynamicForm
          form={dynamicForm}
          onCancel={closeModal}
          onSubmit={onSubmit}
          submitText="Создать"
          isLoading={isActionProcessing}
        />
      </Modal.Body>
    </Modal>
  );
});
