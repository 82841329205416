import React from 'react';

import LayoutMain from 'src/components/Layouts/LayoutMain';

import Dashboard from 'src/features/Dashboard';
import ReportsLabs from 'src/features/ReportsLabs/ReportsLabs';
import Demo from 'src/features/Demo';
import Order from 'src/features/Order';
import Customer, { CustomerOrders } from 'src/features/Customer';
import Communication from './features/Communication';
import { Task } from './features/Task/Task';

export interface IRoutes {
  path: string;
  pureComponent?: React.ComponentType & any;
  layout?: React.ComponentType & any;
  exact?: boolean;
  title?: string;
}

export interface INavLinksSalons {
  title: string;
  dependence: string;
}

// ---
// Default application layout

export const DefaultLayout = LayoutMain;

// ---
// Document title template

export const titleTemplate = '%s - Platform CRM Web';

// ---
// Routes
//
// Note: By default all routes use { "exact": true }. To change this
// behaviour, pass "exact" option explicitly to the route object

export const defaultRoute = '/';

export const routes = (permission: boolean): IRoutes[] => [
  {
    path: '/',
    pureComponent: Dashboard,
    exact: true,
    title: 'Dashboard',
  },
  {
    path: '/reports/labs',
    pureComponent: ReportsLabs,
    exact: true,
    title: 'Отчёты LABS',
  },
  {
    path: '/task/:taskId',
    pureComponent: Task,
    exact: true,
    title: 'Задача',
  },
  {
    path: '/communication/:communicationId',
    pureComponent: Communication,
    exact: true,
    title: 'Коммуникация',
  },
  {
    path: '/communication/:communicationId/:taskId',
    pureComponent: Communication,
    exact: true,
    title: 'Коммуникация',
  },
  {
    path: '/order/:orderId',
    pureComponent: Order,
    exact: true,
    title: 'Заказ',
  },
  {
    path: '/customer/:customerId',
    pureComponent: Customer,
    exact: true,
    title: 'Клиент',
  },
  {
    path: '/customer/:customerId/orders',
    pureComponent: CustomerOrders,
    exact: true,
    title: 'Заказы',
  },
  {
    path: '/demo',
    pureComponent: Demo,
    exact: true,
    title: 'Демо',
  },
];
