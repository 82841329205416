import { isValid } from 'date-fns/esm';
import PhoneNumber from 'awesome-phonenumber';
import { PlatformCRMWeb } from 'src/services/GrpcService';
import { IParsedAddress } from '../components/Fields/Address/interfaces/address.interface';
import { toParsedAddress } from '../components/Fields/Address/AddressField.utls';
import { IError } from 'src/store/ProspectStore';
import { logger } from '@qlean/front-logger';
import { AddressDto } from '../store/CustomerStore';
import { serviceTypesWithoutRegularity } from './dictionary';

const cleaningServiceTypes = [
  'cleaning_flat_standard',
  'cleaning_flat_deep',
  'cleaning_flat_renovation',
  'cleaning_flat_hourly',
  'hourly_deep_cleaning',
  'sell_goods_chemistry',
  'sell_goods',
];
const externalServiceTypes = ['laundry', 'water'];

const drycleaningServiceTypes = ['drycleaning', 'laundry_ondemand'];

export const isCleaningServiceType = (serviceType?: string | null) => cleaningServiceTypes.includes(serviceType || '');
export const isExternalServiceType = (serviceType?: string | null) => externalServiceTypes.includes(serviceType || '');
export const isDryCleaningServiceType = (serviceType?: string | null) => drycleaningServiceTypes.includes(serviceType || '');

export const ERROR_MESSAGES = {
  REGION_ID: 'Необходимо выбрать регион',
  ADDRESS: 'Необходимо ввести адрес до дома',
  REGULARITY: 'Необходимо выбрать регулярность',
  START_DATE: 'Необходимо выбрать дату и время',
  PAYMENT_TYPE: 'Необходимо выбрать способ оплаты',
  PRICE: 'Необходимо выбрать базовые опции',
  EXTERNAL_ID: 'Необходимо указать ID заказа',
  SERVICE_TYPE: 'Необходимо выбрать продукт',
  PHONE: 'Номер телефона введён не верно',
  PROMOCODE: 'Указан не верный промокод',
};

export const humanReadableErrors = {
  invalid_promocode: 'Промокод не существует / был использован',
};

interface IValidationProspect {
  regionId?: number | null;
  address?: AddressDto;
  regularity?: string | null;
  startDate?: string | null;
  paymentType?: string | null;
  serviceType?: string | null;
  price?: PlatformCRMWeb.ICalculateOption | null;
  externalId?: string | null;
  drycleaningMeta?: PlatformCRMWeb.IDrycleaningMeta;
}

export enum ClientErrorType {
  REGION_ID = 'regionId',
  ADDRESS = 'address',
  REGULARITY = 'regularity',
  START_DATE = 'startDate',
  PAYMENT_TYPE = 'paymentType',
  CALCULATOR = 'calculator',
  EXTERNAL_ID = 'externalId',
  PICKUP_TIMESLOT = 'pickupTimeslot',
  RETURN_TIMESLOT = 'returnTimeslot',
}

interface IValidationCreateTask {
  serviceType?: string | null;
  phone?: string | null;
}

export const isValidRegion = (regionId?: number | null) => {
  return Boolean(regionId);
};

export const isValidAddress = (address?: IParsedAddress | null) => {
  const { house } = address?.object || {};
  return Boolean(house);
};

export const isValidRegularity = (regularity?: string | null) => {
  return Boolean(regularity);
};

export const isValidStartDate = (startDate?: string | null) => {
  return Boolean(startDate);
};

export const isValidPaymentType = (paymentType?: string | null) => {
  return Boolean(paymentType);
};

export const isValidNumberOfPayments = (paymentType?: string | null) => {
  return Boolean(paymentType);
};

export const isValidPrice = (price?: PlatformCRMWeb.ICalculatePrice | null) => {
  return Boolean(price?.totalDuration);
};

export const isValidExternalId = (externalId?: string | null) => {
  return Boolean(externalId);
};

export const isValidServiceType = (serviceType?: string | null) => {
  return Boolean(serviceType);
};

export const isValidPhone = (phone?: string | null) => {
  if (phone && phone.length === 15) {
    const parsedPhone = new PhoneNumber(phone, 'RU');
    return parsedPhone.isValid();
  }
  return false;
};

export const validateCreateTask = (createTaskForm: IValidationCreateTask) => {
  const { serviceType } = createTaskForm;
  const { SERVICE_TYPE } = ERROR_MESSAGES;
  const errors: IError[] = [];
  if (!isValidServiceType(serviceType)) {
    errors.push({ type: 'serviceType', message: SERVICE_TYPE });
  }
  /**
  if (!isValidPhone(phone)) {
    errors.push({ type: 'phone', message: PHONE });
  }**/
  return errors;
};

export const unvalidateCreateTask = (errors: IError[], createTaskForm: IValidationCreateTask) => {
  const { serviceType, phone } = createTaskForm;
  const typeFilters: string[] = [];
  if (isValid(serviceType)) {
    typeFilters.push('serviceType');
  }
  if (isValidPhone(phone)) {
    typeFilters.push('phone');
  }
  const newErrors = errors.filter((error) => !typeFilters.includes(error.type));
  logger.debug('[Validation::unvalidateCreateTask]', createTaskForm, typeFilters, newErrors);
  return newErrors;
};

export const validateProspect = (errors: IError[], validationProspect: IValidationProspect): IError[] => {
  const { regionId, address, regularity, startDate, paymentType, serviceType, externalId, price, drycleaningMeta } = validationProspect;
  const { REGION_ID, ADDRESS, REGULARITY, START_DATE, PAYMENT_TYPE, PRICE, EXTERNAL_ID } = ERROR_MESSAGES;
  const typeFilters: string[] = [];
  errors = errors.map((error) => ({
    ...error,
    message: humanReadableErrors[error.type] || error.message,
  }));

  if (isDryCleaningServiceType(serviceType)) {
    if (!drycleaningMeta?.pickupTimeslot) {
      errors.push({ type: ClientErrorType.PICKUP_TIMESLOT, message: 'Заполните дату забора вещей' });
    } else {
      typeFilters.push(ClientErrorType.PICKUP_TIMESLOT);
    }
    if (!drycleaningMeta?.returnTimeslot) {
      errors.push({ type: ClientErrorType.RETURN_TIMESLOT, message: 'Заполните дату доставки вещей' });
    } else if (drycleaningMeta?.returnTimeslot?.startAt && drycleaningMeta?.pickupTimeslot?.startAt) {
      const pk = new Date(drycleaningMeta?.pickupTimeslot?.startAt);
      const rt = new Date(drycleaningMeta?.returnTimeslot?.startAt);

      if (pk > rt) {
        errors.push({ type: ClientErrorType.RETURN_TIMESLOT, message: 'Дата доставки не может быть реньше забора' });
      }
    } else {
      typeFilters.push(ClientErrorType.RETURN_TIMESLOT);
    }
    if (isValidRegion(regionId)) {
      typeFilters.push(ClientErrorType.REGION_ID);
    } else if (errors.findIndex((error) => error.type === ClientErrorType.REGION_ID) < 0) {
      errors.push({ type: ClientErrorType.REGION_ID, message: REGION_ID });
    }
    if (isValidAddress(toParsedAddress(address)) && !errors.find((item) => item.type === 'address')) {
      typeFilters.push(ClientErrorType.ADDRESS);
    } else if (errors.findIndex((error) => error.type === ClientErrorType.ADDRESS) < 0) {
      errors.push({ type: ClientErrorType.ADDRESS, message: ADDRESS });
    }
    if (isValidPaymentType(paymentType)) {
      typeFilters.push(ClientErrorType.PAYMENT_TYPE);
    } else if (errors.findIndex((error) => error.type === ClientErrorType.PAYMENT_TYPE) < 0) {
      errors.push({ type: ClientErrorType.PAYMENT_TYPE, message: PAYMENT_TYPE });
    }
  }

  if (isCleaningServiceType(serviceType)) {
    if (isValidRegion(regionId)) {
      typeFilters.push(ClientErrorType.REGION_ID);
    } else if (errors.findIndex((error) => error.type === ClientErrorType.REGION_ID) < 0) {
      errors.push({ type: ClientErrorType.REGION_ID, message: REGION_ID });
    }
    if (isValidAddress(toParsedAddress(address))) {
      typeFilters.push(ClientErrorType.ADDRESS);
    } else if (errors.findIndex((error) => error.type === ClientErrorType.ADDRESS) < 0) {
      errors.push({ type: ClientErrorType.ADDRESS, message: ADDRESS });
    }
    if (isValidRegularity(regularity) || serviceTypesWithoutRegularity.includes(serviceType || '')) {
      typeFilters.push(ClientErrorType.REGULARITY);
    } else if (errors.findIndex((error) => error.type === ClientErrorType.REGULARITY) < 0) {
      errors.push({ type: ClientErrorType.REGULARITY, message: REGULARITY });
    }
    if (isValidStartDate(startDate)) {
      typeFilters.push(ClientErrorType.START_DATE);
    } else if (errors.findIndex((error) => error.type === ClientErrorType.START_DATE) < 0) {
      errors.push({ type: ClientErrorType.START_DATE, message: START_DATE });
    }
    if (isValidPaymentType(paymentType)) {
      typeFilters.push(ClientErrorType.PAYMENT_TYPE);
    } else if (errors.findIndex((error) => error.type === ClientErrorType.PAYMENT_TYPE) < 0) {
      errors.push({ type: ClientErrorType.PAYMENT_TYPE, message: PAYMENT_TYPE });
    }
    if (isValidPrice(price)) {
      typeFilters.push(ClientErrorType.CALCULATOR);
    } else if (errors.findIndex((error) => error.type === ClientErrorType.CALCULATOR) < 0) {
      errors.push({ type: ClientErrorType.CALCULATOR, message: PRICE });
    }
  }
  if (isExternalServiceType(serviceType)) {
    if (isValidExternalId(externalId)) {
      typeFilters.push(ClientErrorType.EXTERNAL_ID);
    } else if (errors.findIndex((error) => error.type === ClientErrorType.EXTERNAL_ID) < 0) {
      errors.push({ type: ClientErrorType.EXTERNAL_ID, message: EXTERNAL_ID });
    }
  }

  const wellKnownErrorTypes: string[] = Object.entries(ClientErrorType).map(([, value]) => value);

  const newErrors = errors
    // удаляем ошибки, которые были присланы сервером (те не известны на клиенте)
    // .filter((error) => wellKnownErrorTypes.includes(error.type))
    .filter((error) => !typeFilters.includes(error.type));

  logger.debug('[Validation::validateProspect]', {
    validationProspect,
    typeFilters,
    wellKnownErrorTypes,
    newErrors,
    errors,
  });

  return newErrors;
};
