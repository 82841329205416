import React from 'react';
import { observer } from 'mobx-react';
import { Card, CardGroup, OverlayTrigger, Popover } from 'react-bootstrap';

import { parseDate } from 'src/utils/formatters';
import { CommunicationDto } from 'src/store/CommunicationStore';

import 'src/vendor/styles/pages/users.scss';

interface IProps {
  communication: CommunicationDto;
  activeTaskIndex: number;
}

const MAX_MISS_CALLS = 3; // максимально количество недозвонов (выставляется в сервисе crm-task)

const CommunicationStatusToText = {
  0: '-',
  1: 'Создана',
  2: 'Назначена',
  3: 'Закрыта',
  4: 'Отложенна',
  5: 'Нет коммуникации',
  6: 'Перенесена',
  7: 'Отменена',
  8: 'Негатив',
}

const Info = observer((props: IProps) => {
  const { communication, activeTaskIndex } = props;
  const orderedTask = communication.taskList[activeTaskIndex];
  const lastMissedCallDate = parseDate(orderedTask?.task.lastMissedCallDate);
  const postponedTo = parseDate(orderedTask?.task.postponedTo);
  return (
    <CardGroup>
      <Card>
        <div className="py-4 px-4">
          <div className={'text-muted small'}>Скилл</div>
          <div className={'d-flex justify-content-between align-items-center'}>
            <span>{orderedTask?.task.skill.name}</span>
            {orderedTask?.task?.comment && (
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Popover id="comment">
                    <Popover.Content>{orderedTask?.task?.comment}</Popover.Content>
                  </Popover>
                }
              >
                <i style={{ fontSize: 24 }} className="ion ion-ios-contacts"></i>
              </OverlayTrigger>
            )}
          </div>
        </div>
      </Card>
      <Card>
        <div className="py-4 px-4">
          <div className={'text-muted small'}>Статус коммуникации</div>
          <div className={'d-flex flex-wrap'}>{CommunicationStatusToText[communication.state] ?? '-'}</div>
        </div>
      </Card>
      <Card>
        <div className="py-4 px-4">
          <div className={'text-muted small'}>Дата и время создания задачи</div>
          <div className={'d-flex flex-wrap'}>{parseDate(orderedTask?.task?.createdAt)}</div>
        </div>
      </Card>
      <Card>
        <div className="py-4 px-4">
          <div className={'text-muted small'}>Последний недозвон</div>
          <div className={'d-flex justify-content-between align-items-center'}>
            <span>{lastMissedCallDate || '-'}</span>
            <span className="text-success">{`${
              communication.missCallsCount || 0
            } из ${MAX_MISS_CALLS}`}</span>
          </div>
        </div>
      </Card>
      <Card>
        <div className="py-4 px-4">
          <div className={'text-muted small'}>Последний перезвон</div>
          <div className={'d-flex justify-content-between align-items-center'}>
            <span>{postponedTo || '-'}</span>
          </div>
        </div>
      </Card>
      <Card>
        <div className="py-4 px-4">
          <div className={'text-muted small'}>Владелец</div>
          <div className={'d-flex justify-content-between align-items-center'}>
            <span>{communication.owner?.name || '-'}</span>
          </div>
        </div>
      </Card>
    </CardGroup>
  );
});

export default Info;
