import 'reflect-metadata';
import React from 'react';
import * as Sentry from '@sentry/react';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { IErrorDictionary, Login, SignInByPhone } from '@qlean/admin-web-login';

import LoginStore from 'src/store/LoginStore';
import PrivateRoute from 'src/features/PrivateRoute';

import 'src/vendor/styles/pages/authentication.scss';
import 'src/vendor/styles/bootstrap.scss';
import 'src/vendor/styles/appwork.scss';
import 'src/vendor/styles/theme-corporate.scss';
import 'src/vendor/styles/colors.scss';
import 'src/vendor/styles/uikit.scss';
import 'src/App.scss';

import { titleTemplate } from './routes';
import { ErrorFallback } from './components/ErrorFallback/ErrorFallback';

const DICTIONARY_ERR: IErrorDictionary[] = [
  {
    error: 'CANCELLED - Error: login error Request failed with status code 401',
    message: 'Введен не верный код из смс',
  },
  {
    error: 'CANCELLED - Error: requestOtp error Request failed with status code 400',
    message: 'Попробуйте позже, слишком много запросов',
  },
  {
    error: 'CANCELLED - Error: requestOtp error Request failed with status code 429',
    message: 'Попробуйте позже, слишком много запросов',
  },
];

const setTitle = (title) => {
  document.title = titleTemplate.replace('%s', title);
};

const CloseButton = ({ closeToast }) => (
  <button className="Toastify__close-button" type="button" aria-label="close" onClick={closeToast}>
    &times;
  </button>
);

const App = () => {
  const onSubmitEmail = (values) => {
    const { submitEmail } = LoginStore;

    return submitEmail(values);
  };

  const onSubmitPhone = (values) => {
    const { ...credentials } = values;
    const { submitPhone } = LoginStore;

    return submitPhone({ phone: credentials.phone });
  };

  const onSubmitCode = (values) => {
    const { ...credentials } = values;
    const { submitCode } = LoginStore;

    return submitCode({ phone: credentials.phone, code: credentials.code });
  };

  const restProps = {
    dictionary: DICTIONARY_ERR,
    setTitle,
  };

  return (
    <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
      <Switch>
        <Route
          path={'/login/phone'}
          exact
          render={(props) => (
            <Sentry.ErrorBoundary fallback={ErrorFallback}>
              <SignInByPhone
                {...props}
                {...restProps}
                path={{ linkSubmit: '/', linkChangeTypeAuth: '/login' }}
                onSubmitPhone={onSubmitPhone}
                onSubmitCode={onSubmitCode}
              />
            </Sentry.ErrorBoundary>
          )}
        />
        <Route
          path={'/login'}
          exact
          render={(props) => (
            <Sentry.ErrorBoundary fallback={ErrorFallback}>
              <Login
                {...props}
                {...restProps}
                path={{ linkSubmit: '/', linkChangeTypeAuth: '/login/phone' }}
                onSubmit={onSubmitEmail}
                titleBtnEmail={'Войти'}
              />
            </Sentry.ErrorBoundary>
          )}
        />
        <PrivateRoute />
        <Redirect from="*" to="/demo" exact={true} />
      </Switch>
      <ToastContainer closeButton={<CloseButton closeToast={() => {}} />} />
    </BrowserRouter>
  );
};

export default App;
