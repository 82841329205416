export const TITLES = {
  address: 'Адрес',
  flat: 'Квартира',
  intercom: 'Домофон',
  floor: 'Этаж',
  entrance: 'Подъезд',
  comment: 'Комментарий к адресу',
};

export const PLACEHOLDERS = {
  address: 'Введите адрес',
  flat: 'Введите номер квартиры',
  intercom: 'Введите код домофона',
  floor: 'Введите этаж',
  entrance: 'Введите подъезд',
  comment: 'комментарий к адресу',
};
