import React, {useEffect, useState} from 'react';

import { observer } from 'mobx-react';
import { Form, Col } from 'react-bootstrap';
import { Field } from 'react-final-form';
import Select from 'react-select';

import { FieldLabel } from 'src/components/Fields/FieldLabel';
import { FieldError } from 'src/components/Fields/FieldError';

import { TFieldProp } from 'src/components/Fields/field.interface';
import { ERROR_MESSAGES } from '../../../utils/validation';

interface IInitialValue {
  promocode?: string;
  discount?: IOption;
}

interface IOption {
  id: number;
  value: string;
  promocode: string;
}

export interface IDiscountWithPromocodeField {
  initialValue: IInitialValue;
  options: IOption[];
}

export type TDiscountWithPromocodeField = IDiscountWithPromocodeField & TFieldProp<string>;

export const DiscountWithPromocodeField = observer((props: TDiscountWithPromocodeField) => {
  const { name, isRequired, isReadonly, initialValue, options, errors } = props;
  const [promocode, setPromocode] = useState<string | undefined>(initialValue.promocode);
  const [discount, setDiscount] = useState<IOption | undefined>(
    initialValue.discount || options[0],
  );
  

  useEffect(() => {
    setDiscount(initialValue.discount);
  }, [initialValue.discount]);

  return (
    <Field
      name={name}
      render={({ input, meta }) => (
        <Form.Row>
          <Form.Group controlId="promocode" as={Col} md={6}>
            <FieldLabel label="Промокод" isRequired={isRequired} />
            <Form.Control
              {...input}
              value={promocode}
              placeholder="Введите промокод"
              onChange={(e) => {
                setPromocode(e.target.value);
                setDiscount(
                  options.find((option) => option.promocode === e.target.value) || options[0]
                );
                input.onChange({
                  promocode: e.target.value,
                  discount: undefined,
                });
              }}
              isInvalid={FieldError.isInvalid(meta) || !!errors}
              disabled={isReadonly}
            />
            <FieldError error={errors ? `${errors}` : ERROR_MESSAGES.PROMOCODE} />
          </Form.Group>
          <Form.Group controlId="discount" as={Col} md={6}>
            <FieldLabel label="Скидка" isRequired={isRequired} />
            <Select
              {...input}
              name="discount"
              options={options}
              getOptionLabel={(option) => option.value}
              // @ts-ignore
              getOptionValue={(option) => option}
              placeholder="Выберите скидку"
              className="react-select"
              classNamePrefix="react-select"
              value={discount}
              isDisabled={isReadonly}
              onChange={(e) => {
                // @ts-ignore
                setPromocode(e.promocode);
                // @ts-ignore
                setDiscount(e);
                input.onChange({
                  // @ts-ignore
                  promocode: e.promocode,
                  discount: undefined,
                });
              }}
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            />
          </Form.Group>
          <FieldError error={meta.error} />
        </Form.Row>
      )}
    />
  );
});
