import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import { dictionary } from 'src/utils/dictionary';
import ManagerStore from 'src/store/ManagerStore';
import { PlatformCRMWeb } from 'src/services/GrpcService';

const OnlineStatus = observer(() => {
  const { getCurrentManager, changeStatusManager, manager } = ManagerStore;

  useEffect(() => {
    getCurrentManager();
  }, []);

  return (
    <div className="ml-auto">
      <label className="switcher switcher-success">
        <input
          type="checkbox"
          className="switcher-input"
          checked={manager?.state === PlatformCRMWeb.ManagerState.ONLINE}
          onChange={changeStatusManager}
        />
        <span className="switcher-indicator">
          <span className="switcher-yes">
            <span className="ion ion-md-checkmark"></span>
          </span>
          <span className="switcher-no">
            <span className="ion ion-md-close"></span>
          </span>
        </span>
        <span className="switcher-label">
          {dictionary.checkControl.managerState[manager?.state !== 0 ? 1 : 0]}
        </span>
      </label>
    </div>
  );
});

export default OnlineStatus;
