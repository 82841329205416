import React from 'react';
import { Form, Card, Button, InputGroup } from 'react-bootstrap';
import { Field } from 'react-final-form';
import { FieldLabel } from '../FieldLabel';
import { TFieldProp } from '../field.interface';
import { FieldError } from '../FieldError';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import TextareaAutosize from 'react-autosize-textarea';

export interface IStringFieldProp {
  variant?: 'default' | 'textarea';
  defaultError?: string;
  isValid?: (value: any) => boolean;
  hasCopyButton?: boolean;
}

export type TStringFieldProp = IStringFieldProp & TFieldProp<string>;

interface IState {
  showError: boolean;
}
export class StringField extends React.Component<TStringFieldProp, IState> {
  constructor(props: TStringFieldProp) {
    super(props);
    this.state = {
      showError: false,
    };
  }
  static defaultProps: Partial<TFieldProp<string>> = {
    isReadonly: false,
    isRequired: false,
  };

  renderField = (input) => {
    const { variant, isReadonly, placeholder, disabled, initialValue, href, hasCopyButton } = this.props;
    if (variant === 'textarea') {
      return (
        <>
          <Form.Control hidden readOnly={true} isInvalid={this.state.showError} />
          <TextareaAutosize
            {...input}
            rows={3}
            maxRows={15}
            className={`form-control ${this.state.showError ? 'is-invalid' : ''}`}
            readOnly={isReadonly}
            placeholder={placeholder}
            disabled={disabled}
          />
        </>
      );
    }
    if (isReadonly) {
      return (
        <div className="form-control" style={{ border: 'none' }}>
          <Card.Link target="_blank" rel="noopener noreferrer" href={href}>
            {initialValue}
          </Card.Link>
          {hasCopyButton && (
            <CopyToClipboard text={input.value}>
              <Button variant="primary">копировать</Button>
            </CopyToClipboard>
          )}
        </div>
      );
    }
    return (
      <>
        <InputGroup>
          <Form.Control {...input} readOnly={isReadonly} placeholder={placeholder} isInvalid={this.state.showError} disabled={disabled} />
          {hasCopyButton && (
            <CopyToClipboard text={input.value}>
              <Button variant="primary">копировать</Button>
            </CopyToClipboard>
          )}
        </InputGroup>
      </>
    );
  };

  private onFocus = () => {
    this.setState({
      showError: false,
    });
  };

  private onBlur = (input) => {
    const { isValid } = this.props;
    if (isValid) {
      this.setState({
        showError: !isValid(input?.value),
      });
    }
  };

  componentDidUpdate(prevProps: TStringFieldProp) {
    const { errors } = this.props;
    if (prevProps.errors !== errors) {
      this.setState({
        showError: Boolean(errors),
      });
    }
  }

  render() {
    const { name, initialValue, isRequired, label, errors, defaultError } = this.props;

    return (
      <Field
        name={name}
        parse={x => x}
        initialValue={initialValue}
        render={({ input }) => {
          return (
            <Form.Group onFocus={this.onFocus} onBlur={() => this.onBlur(input)} controlId={name}>
              <FieldLabel {...label} isRequired={isRequired} />
              {this.renderField(input)}
              <FieldError error={errors ? `${errors}` : defaultError || ''} />
            </Form.Group>
          );
        }}
      />
    );
  }
}
