import { PlatformCRMWeb, sso } from 'src/services/GrpcService';
import { Type } from 'class-transformer';
import { OrderDto } from '../OrderStore/OrderStore.dto';
import { ProspectDto } from '../ProspectStore/ProspectStore.dto';

export class FioObjectDto implements PlatformCRMWeb.IIDadataFIO {
  source!: string;
  result!: string;
  resultGenitive!: string;
  resultDative!: string;
  resultAblative!: string;
  surname!: string;
  name!: string;
  patronymic!: string;
  gender!: string;
  qc!: number;
}

export class FioDto implements PlatformCRMWeb.IFIO {
  fullName!: string; // Полное, правильно отформатированное ФИО возвращаемый из Dadata
  lastName!: string; // Фамилия
  middleName!: string; // Отчество
  firstName!: string; //  Имя
  isValid?: boolean; // Признка того, что ФИО разобрано или через DATA или в ручную менджером
  gender!: number;
  @Type(() => FioObjectDto)
  object!: FioObjectDto;
}

export class PersonDto implements PlatformCRMWeb.IPerson {
  id!: string;
  email!: string;
  phone!: string;
  status!: string;
  ssoId!: string;
  commentForExecutor!: string;
  commentForSupport!: string;
  @Type(() => FioDto)
  fio!: FioDto;
  createdAt!: string;
  updatedAt!: string;
  qleanClientId?: string;
  qleanAdminLink!: string;
  qleanCreditCardNumber?: string;
}

export class CustomerOrdersDto implements PlatformCRMWeb.IGetCustomerOrdersResponse {
  @Type(() => OrderDto)
  orders!: OrderDto[];
}
export class CustomerCallDto implements PlatformCRMWeb.ICustomerCall {
  id!: number;
  orderId!: number;
  comment!: string;
  fullName!: string;
  calledAt!: string;
  callTypes!: string;
  adminLink?: string;
}

export class CustomerCallsDto implements PlatformCRMWeb.IGetCustomerCallsResponse {
  @Type(() => CustomerCallDto)
  calls!: CustomerCallDto[];
}

export class SsoAddressDetail implements sso.IAddressDetail {
  floor?: string;
  comment?: string;
  intercom?: string;
  entrance?: string;
}

export class SsoCoordinates implements sso.ICoordinates {
  lat!: number;
  lng!: number;
}

export class SsoMetro implements sso.IMetro {
  distance!: number;
  line!: string;
  name!: string;
}

export class SsoAddressDto implements PlatformCRMWeb.IAddressRes {
  id?: string;
  label?: string;
  value?: string;
  unrestrictedValue?: string;
  object?: string;
  @Type(() => SsoAddressDetail)
  detail?: SsoAddressDetail;
  @Type(() => SsoCoordinates)
  coordinates?: SsoCoordinates;
  @Type(() => SsoMetro)
  metro?: SsoMetro[];
  type?: string;
}
export class GetCustomerAddressesResponseDto
  implements PlatformCRMWeb.IGetCustomerAddressesResponse {
  @Type(() => SsoAddressDto)
  addresses!: SsoAddressDto[];
}

export class AddressDto implements sso.IAddress {
  value!: string;
  unrestrictedValue!: string;
  object!: string;
  @Type(() => AddressDetailDto)
  detail!: AddressDetailDto;

  @Type(() => AddressPointDto)
  coordinates!: AddressPointDto;

  @Type(() => AddressMetroDto)
  metro!: AddressMetroDto[];
  id?: string;
  type?: sso.ADDRESS_TYPE | null
  get isPickupPoint(): boolean {
    return this.type === sso.ADDRESS_TYPE.PICKUP_POINT
  }
}

export class AddressDetailDto {
  intercom!: string;
  floor!: string;
  comment!: string;
  entrance!: string;
}

export class AddressPointDto implements sso.ICoordinates {
  lat!: number;
  lng!: number;
}

export class AddressMetroDto implements sso.IMetro {
  name!: string;
  distance!: number;
  line!: string;
}

export class GetBonusesAccountResponseDto implements PlatformCRMWeb.IGetBonusesAccountResponse {
  id!: number;
  total!: number;
  blocker!: number;
}

export class SearchPersonDto implements PlatformCRMWeb.ISearchPerson {
  id!: string;
  @Type(() => FioDto)
  fio!: FioDto;
  ssoId!: string;
  phone!: string;
}


export class SearchCustomerResponseDto implements PlatformCRMWeb.ISearchCustomerResponse {
  @Type(() => SearchPersonDto)
  data!: SearchPersonDto[];
  @Type(() => ProspectDto)
  prospects!: ProspectDto[];
  page!: number;
  pageCount!: number;
  limit!: number;
  count!: number;
  total!: number;
}
