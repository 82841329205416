import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Button, Card, Tab, Tabs } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { PlatformCRMWeb } from 'src/services/GrpcService/codegen/app_pb';
import CommunicationStore from 'src/store/CommunicationStore';
import CustomerStore, { PersonDto } from 'src/store/CustomerStore';
import OrderStore from 'src/store/OrderStore';
import 'src/vendor/styles/pages/users.scss';
import CallInHistory from './CallInHistory';
import CommunicationInHistory from './CommunicationInHistory';
import OrderInHistory from './OrderInHistory';

interface IProps {
  communicationId?: string;
  person: PersonDto;
}

const PersonHistory = observer(({ communicationId, person }: IProps) => {
  const history = useHistory();
  const { getCustomerCalls, customerCalls } = CustomerStore;
  const { searchOrders, shortOrders } = OrderStore;
  const { findClientCommunications, clientCommunications, isActionProcessing } = CommunicationStore;

  const [communicationsAccordionValue, setCommunicationsAccordionValue] = useState(undefined);
  const expandCommunications = (e, pane) => {
    e.preventDefault();
    const value = communicationsAccordionValue === pane ? null : pane;
    setCommunicationsAccordionValue(value);
  };

  const [ordersAccordionValue, setOrdersAccordionValue] = useState(undefined);
  const expandOrders = (e, pane) => {
    e.preventDefault();
    const value = ordersAccordionValue === pane ? null : pane;
    setOrdersAccordionValue(value);
  };

  useEffect(() => {
    if (person?.id) {
      findClientCommunications({ clientId: person.ssoId });
      searchOrders({
        page: 1,
        limit: 5,
        where: { person: { eq: person.id } },
        sort: [{ field: 'startDate', order: PlatformCRMWeb.SortOrder.DESC }],
      });
    }
    if (person.qleanClientId) {
      getCustomerCalls({ clientId: person.qleanClientId });
    }
  }, []);

  return (
    <div className="nav-tabs-top mb-4 bg-white" style={{ borderRadius: '3px' }}>
      <Tabs className="m-0 pl-3 pt-3" defaultActiveKey="communications" id="customer-history-tabs">
        <Tab eventKey="communications" title="Коммуникации">
          <div>
            {isActionProcessing ? (
              <Card className="border-0 m-4">Загрузка ...</Card>
            ) : (
              <>
                {clientCommunications?.length ? (
                  clientCommunications.map((communication, index) => (
                    <CommunicationInHistory
                      key={`communication-${communication.id}`}
                      communication={communication}
                      expand={expandCommunications}
                      accordionValue={index + 1}
                      stateValue={communicationsAccordionValue}
                      isCurrentCommunication={communicationId === communication.id}
                    />
                  ))
                ) : (
                  <Card className="border-0 m-4">Нет активных коммуникаций</Card>
                )}
              </>
            )}
          </div>
        </Tab>

        <Tab eventKey="orders" title="Заказы">
          <div>
            {shortOrders.isLoading ? (
              <Card className="border-0 m-4">Загрузка ...</Card>
            ) : (
              <>
                {shortOrders.orders?.length ? (
                  <div>
                    {shortOrders.orders.map((order, index) => (
                      <OrderInHistory
                        key={`order-${order.id}`}
                        order={order}
                        expand={expandOrders}
                        accordionValue={index + 1}
                        stateValue={ordersAccordionValue}
                        isLast={index === shortOrders.orders.length - 1}
                      />
                    ))}
                    <Button className="p-0 w-100 py-2" onClick={(e) => history.push(`/customer/${person.id}/orders`)}>
                      Все заказы
                    </Button>
                  </div>
                ) : (
                  <Card className="border-0 m-4">Нет активных заказов</Card>
                )}
              </>
            )}
          </div>
        </Tab>
        <Tab eventKey="calls" title="Обращения">
          <div>
            {customerCalls.isLoading ? (
              <Card className="border-0 m-4">Загрузка ...</Card>
            ) : (
              <>
                {customerCalls.calls?.length ? (
                  customerCalls.calls.map((call, index) => (
                    <CallInHistory key={`call-${call.id}`} isLast={index === customerCalls.calls.length - 1} call={call} />
                  ))
                ) : (
                  <Card className="border-0 m-4">Нет обращений</Card>
                )}
              </>
            )}
          </div>
        </Tab>
      </Tabs>
    </div>
  );
});

export default PersonHistory;
