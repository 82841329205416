import React from 'react';

import axios from 'axios';
import { ActionMeta, ValueType } from 'react-select';
import { Field, FieldInputProps } from 'react-final-form';

import {
  IValue,
  IParsedAddress,
  IAddressFieldProps,
  IAddressFieldState,
  OptionType,
} from 'src/components/Fields/Address/interfaces/address.interface';
import { ISuggestion } from 'src/components/Fields/Address/interfaces/dadata.interface';
import { TFieldProp } from 'src/components/Fields/field.interface';

import 'src/vendor/libs/react-select/react-select.scss';
import { ERROR_MESSAGES, isValidAddress, isValidRegion } from 'src/utils/validation';
import {
  toAddress,
  toParsedAddress,
  getAddressBySuggestion,
  favoriteAddressesToSuggests,
  isAddressesEqual,
  trimAddressApartmentsPart,
} from './AddressField.utls';
import { Region } from './Region';
import { Address } from './Address';
import './style.scss';

const DADATA_URL = 'https://qlean.ru/dadata/suggestions/api/4_1/rs/suggest';
const DADATA_TOKEN = '1a3b0db7bee65d261d6b29ac9f5907446bf788d5';

const LOCATIONS = {
  [1]: [{ region: 'Москва' }, { region: 'Московская' }],
  [2]: [{ region: 'Санкт-Петербург' }, { region: 'Ленинградская' }],
};

export class AddressField extends React.Component<
  TFieldProp<IAddressFieldProps>,
  IAddressFieldState
> {
  constructor(props: TFieldProp<IAddressFieldProps>) {
    super(props);
    const { initialValue } = props;

    this.state = {
      isLoading: false,
      options: [],
      selected: {
        regionId: initialValue?.value?.regionId,
        address: toParsedAddress(initialValue?.value?.address),
      },
      isShortForm: initialValue?.isShortForm || false,
      isValid: initialValue?.isValid || this.isDefaultValid,
      showErrorRegion: false,
      showErrorAddress: false,
      isFocusedField: false,
      isFocusedAddress: false,
      isActiveOrder: initialValue?.isActiveOrder ?? false,
      inputValue: initialValue?.value?.address?.value || '',
    };
  }

  componentDidUpdate(prevProps: TFieldProp<IAddressFieldProps>) {
    const { errors, initialValue } = this.props;
    if (prevProps.errors !== errors) {
      this.setState({
        showErrorRegion: Boolean(errors?.['regionId']),
        showErrorAddress: Boolean(errors?.['address']),
      });
    }
    if (
      prevProps.initialValue?.value?.regionId !== initialValue?.value?.regionId &&
      !initialValue?.withRegion
    ) {
      this.setState({
        selected: {
          regionId: initialValue?.value?.regionId,
          address: undefined,
        },
        inputValue: '',
      });
    }
  }

  private isDefaultValid(address?: IParsedAddress): boolean {
    const { house } = address?.object || {};
    return !!house;
  }

  private instance = axios.create({
    baseURL: DADATA_URL,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Token ${DADATA_TOKEN}`,
    },
  });

  private search = async (data: any): Promise<ISuggestion[]> => {
    const response = await this.instance.post('/address', data);
    const { suggestions = [] } = response.data as { suggestions: ISuggestion[] };

    return suggestions;
  };

  private onSearch = async (query: string) => {
    this.setState({ isLoading: true });

    if (query.length > 2) {
      const options = await this.search({
        query,
        locations: LOCATIONS[this.state.selected?.regionId || 1],
      });
      this.setState({ options });
    }
    this.setState({ isLoading: false });
  };

  private onChangeFlatNumber = (event) => {
    const { selected } = this.state;
    const { value } = event.target;

    if (!selected) {
      return;
    }

    if (selected?.address?.object) {
      selected.address.object.flat = value;
    }

    let inputValue = selected?.address?.value ? trimAddressApartmentsPart(selected.address.value) : '';

    if (value) {
      inputValue += `, кв ${value}`;
    }

    if (selected?.address?.id) {
      delete selected?.address?.id;
    }

    const newSelected = {
      ...selected,
      address: {
        ...selected.address,
        value: inputValue,
      },
    };

    this.onSearch(newSelected?.address.value || '');
    this.setState({ selected: { ...newSelected }, inputValue });
  };

  private onChangeAddress = async (
    [option]: ISuggestion[],
    input: FieldInputProps<IAddressFieldProps, HTMLElement>
  ) => {
    const { selected } = this.state;
    let regionId = selected?.regionId;
    let detail = selected?.address?.detail;
    if (option?.isFavourite) {
      const favorite = this.props.initialValue?.favourites?.find((value) => value?.address?.value === option.value) || selected;

      regionId = favorite?.regionId;
      detail = favorite?.address?.detail;
    }

    if (!option) {
      return;
    }

    const [suggestion] = await this.search({
      query: option.value,
      count: 1,
    });

    if (detail && selected?.address && selected?.address.detail) {
      selected.address.detail = detail;
    }

    if (suggestion.data) {
      this.setState(
        {
          selected: {
            regionId,
            address: getAddressBySuggestion(
              { ...suggestion, detail: option?.detail, id: option.id },
              selected,
            ),
          },
          inputValue: suggestion.value,
        },
        () => {
          if (option.isFavourite) {
            this.onBlurField(input.onChange);
          }
        }
      );
    }
  };

  private onChangeAddressDetails = (type: string, event) => {
    const { selected } = this.state;

    const newSelectedAddress = Object.assign(selected?.address || { value: '', detail: {} }, {
      detail: {
        ...selected?.address?.detail,
        [type]: event.target.value,
      },
    });

    this.setState({ selected: { ...selected, address: newSelectedAddress } });
  };

  private onFocusField = () => {
    this.setState({ isFocusedField: true });
  };

  private onBlurField = (onChange: (arg: { value?: IValue }) => void) => {
    const { selected, inputValue } = this.state;

    const isAddressChanged = !isAddressesEqual(
      selected?.address,
      this.props.initialValue?.value?.address
    );

    this.setState({ isFocusedField: false });
    if (!inputValue) {
      this.setState(
        {
          selected: {
            regionId: selected?.regionId,
            address: undefined,
          },
        },
        () => {
          onChange({
            value: {
              regionId: selected?.regionId,
              address: undefined,
            },
          });
        }
      );
    } else {
      this.setState(
        {
          inputValue: selected?.address?.value || '',
        },
        () => {
          if (isAddressChanged) {
            onChange({
              value: {
                regionId: selected?.regionId,
                address: toAddress(selected?.address),
              },
            });
          }
        }
      );
    }
  };

  private onFocusRegion = () =>
    this.setState({
      showErrorRegion: false,
    });

  private onBlurRegion = () =>
    this.setState({
      showErrorRegion: !isValidRegion(this.state.selected?.regionId),
    });

  private onFocusAddress = () =>
    this.setState({
      showErrorAddress: false,
      isFocusedAddress: true,
    });

  private onBlurAddress = (value: IParsedAddress = { value: '' }) => {
    return this.setState({
      showErrorAddress: !isValidAddress(value),
      isFocusedAddress: false,
    });
  }

  private onChangeRegion = (value: ValueType<OptionType>, actionMeta: ActionMeta<OptionType>) => {
    this.setState({
      selected: {
        address: undefined,
        // @ts-ignore
        regionId: value.id,
      },
      inputValue: '',
    });
  };

  private onChangeInput = (e, onChange) => {
    this.setState({ inputValue: e.target.value });
    onChange(e);
  };

  render() {
    const {
      isRequired,
      isReadonly,
      name,
      label,
      placeholder,
      initialValue,
      // @ts-ignore
      withRegion,
      errors,
    } = this.props;
    const {
      isLoading,
      options,
      isShortForm,
      selected,
      isValid,
      showErrorRegion,
      showErrorAddress,
      isFocusedAddress,
      inputValue,
      isActiveOrder,
    } = this.state;
    const { REGION_ID, ADDRESS } = ERROR_MESSAGES;

    return (
      <Field
        name={name}
        initialValue={initialValue}
        render={({ input }) => (
          <div onFocus={this.onFocusField} onBlur={() => this.onBlurField(input.onChange)}>
            {withRegion && (
              <Region
                isRequired={isRequired}
                initialValue={initialValue}
                showError={showErrorRegion}
                input={input}
                isReadonly={isReadonly}
                selected={selected}
                onFocus={this.onFocusRegion}
                onBlur={this.onBlurRegion}
                onChange={this.onChangeRegion}
                errorMessage={errors?.['regionId'] || REGION_ID}
              />
            )}
            <Address
              isActiveOrder={isActiveOrder}
              isRequired={isRequired}
              label={label}
              isReadonly={isReadonly}
              initialValue={initialValue}
              showError={showErrorAddress}
              errorMessage={errors?.['address'] || ADDRESS}
              input={input}
              name={name}
              options={options}
              placeholder={placeholder}
              selected={selected}
              isLoading={isLoading}
              onSearch={this.onSearch}
              onChange={this.onChangeAddress}
              onFocus={this.onFocusAddress}
              onChangeInput={this.onChangeInput}
              inputValue={inputValue}
              onBlur={this.onBlurAddress}
              favouriteOptions={favoriteAddressesToSuggests(initialValue?.favourites)}
              isShortForm={isShortForm}
              isValid={isValid}
              onChangeFlatNumber={this.onChangeFlatNumber}
              onChangeAddressDetails={this.onChangeAddressDetails}
              onClickClear={() => {
                this.setState(
                  {
                    selected: { ...this.state.selected, address: undefined },
                    inputValue: '',
                  },
                  () => {
                    this.onBlurField(input.onChange);
                  }
                );
              }}
              isFocused={isFocusedAddress}
              withRegion={withRegion}
            />
          </div>
        )}
      />
    );
  }
}
