import React, { useEffect, useState } from 'react';

import DatePicker from 'react-datepicker';
import { observer } from 'mobx-react';
import { Form, Col } from 'react-bootstrap';
import { Field } from 'react-final-form';
import { logger } from '@qlean/front-logger';
import moment from 'moment';

import MetaStore from 'src/store/MetaStore';
import { FieldLabel } from 'src/components/Fields/FieldLabel';
import { FieldError } from 'src/components/Fields/FieldError';

import { TFieldProp } from 'src/components/Fields/field.interface';

import ru from 'date-fns/locale/ru';

import 'src/vendor/libs/react-datepicker/react-datepicker.scss';
import { ERROR_MESSAGES, isValidStartDate } from 'src/utils/validation';
import usePrevious from 'src/utils/usePrevious';
import { lmsServiceTypes } from '../../../utils/dictionary';
import { isSameDay } from 'date-fns';

export interface ITimeSlotField {
  prospectId: string;
  serviceType: string;
  timeslotsSource: 'CRM' | 'LMS' | null;
}

export interface ITimeSlotValue {
  date?: string;
  startDate?: string;
}
interface IValueDate {
  date?: Date;
  startDate?: Date;
}

export type TTimeSlotField = ITimeSlotField & TFieldProp<ITimeSlotValue>;
const MOSCOW_TIMEZONE_OFFSET = 180;

const getValueDate = (value?: ITimeSlotValue): IValueDate => {
  const dateString = value?.date;
  const date = dateString ? new Date(dateString) : undefined;
  return {
    date: date ? new Date(date.setHours(12, 0)) : undefined,
    startDate: value?.startDate ? new Date(value.startDate) : undefined,
  };
};

const isDateEqual = (a?: Date, b?: Date) => {
  return a?.getTime() === b?.getTime();
};

const isValuesDateEqual = (a: IValueDate, b: IValueDate): boolean => {
  return isDateEqual(a?.date, b?.date) && isDateEqual(a?.startDate, b?.startDate);
};

export const TimeSlotField = observer((props: TTimeSlotField) => {
  const { name, isRequired, isReadonly, prospectId, serviceType, errors, timeslotsSource } = props;
  const initialValue = getValueDate(props.initialValue);
  const prevInitialValue = getValueDate(usePrevious(props.initialValue));

  const { getAvailableDates, getAvailableTimeSlots, getAvailableLmsTimeSlotsByCoordinates } = MetaStore;

  const [includedDates, setIncludedDates] = useState<Date[] | undefined>(undefined);
  const [includedTimes, setIncludedTimes] = useState<Date[] | undefined>([]);
  const [chosedDate, setChosedDate] = useState<Date | undefined>(undefined);
  const [chosedTime, setChosedTime] = useState<Date | undefined>(undefined);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    setShowError(Boolean(errors));
  }, [errors]);

  const retrieveDates = async (): Promise<string[] | undefined> => {
    if (!prospectId || !serviceType || !timeslotsSource) {
      return undefined;
    }

    const requestBody = {
      prospectId,
    };

    if (timeslotsSource === 'CRM' && !lmsServiceTypes.includes(serviceType)) {
      logger.debug('fetchDates', prospectId);
      return getAvailableDates(requestBody);
    }

    if (timeslotsSource === 'LMS') {
      logger.debug('fetchDates LMS', prospectId);
      return getAvailableLmsTimeSlotsByCoordinates(requestBody);
    }

    return [];
  };

  const fetchDates = async () => {
    const shouldResetDates = timeslotsSource === null;
    setShowError(false);

    const availableDates: string[] | undefined = await retrieveDates();
    logger.debug('fetchDates result', prospectId);

    if (!availableDates) {
      setIncludedDates(undefined);
    } else {
      setIncludedDates(availableDates.map((date) => new Date(date)));
    }

    if (shouldResetDates) {
      setIncludedTimes(undefined);
    }
  };

  const retrieveTimeSlots = async (): Promise<string[]> => {
    if (!serviceType || !prospectId || !chosedDate) {
      return [];
    }

    const requestBody = {
      prospectId,
      date: chosedDate.toUTCString(),
    };

    if (timeslotsSource === 'CRM') {
      return getAvailableTimeSlots(requestBody);
    }

    if (timeslotsSource === 'LMS') {
      return getAvailableLmsTimeSlotsByCoordinates(requestBody);
    }

    return [];
  };

  const fetchTimeSlots = async () => {
    const shouldResetSlots = timeslotsSource === null;
    setShowError(false);

    const availableTimes: string[] = await retrieveTimeSlots();
    let dates = availableTimes.map((date) => new Date(date));

    if (shouldResetSlots) {
      setIncludedTimes(undefined);
    }

    if (!initialValue?.startDate || chosedDate?.toUTCString() !== initialValue?.startDate.toUTCString()) {
      setChosedTime(undefined);
    }

    if (chosedDate) {
      dates = dates.filter((d) => isSameDay(d, chosedDate));
    }
    setIncludedTimes(dates.length ? dates : undefined);
  };

  const onBlur = () => {
    setShowError(!isValidStartDate(chosedTime?.toISOString()));
  };

  return (
    <Field
      name={name}
      render={({ input }) => {
        useEffect(() => {
          if (!isValuesDateEqual(initialValue, prevInitialValue) && !isDateEqual(chosedDate, initialValue.date)) {
            setChosedDate(initialValue.date);
            setChosedTime(initialValue.startDate);
            input.onChange({
              date: initialValue.date ? initialValue.date.toISOString().substring(0, 10) : null,
              startDate: initialValue.startDate,
            });
          }
        }, [props.initialValue]);
        return (
          <Form.Row>
            <Form.Group className="mb-0" controlId={name} as={Col} md={6}>
              <FieldLabel label="Дата" isRequired={isRequired} />
              <DatePicker
                className={`form-control ${showError ? 'is-invalid' : ''}`}
                selected={chosedDate}
                dateFormat="dd MMMM yyyy"
                locale={ru}
                placeholderText="Выберите дату"
                disabled={isReadonly}
                onFocus={fetchDates}
                onBlur={onBlur}
                includeDates={includedDates}
                filterDate={(date) => {
                  return date >= new Date().setDate(new Date().getDate() - 1);
                }}
                onChange={(date) => {
                  if (date && !isNaN(date.getTime())) {
                    const dateMidDay = new Date(date?.setHours(12, 0));
                    const dateMidDayString = dateMidDay.toISOString().substring(0, 10);
                    setChosedDate(dateMidDay);
                    input.onChange({
                      date: dateMidDayString,
                      startDate: null,
                    });
                  } else {
                    input.onChange({ date: null, startDate: null });
                    setChosedDate(undefined);
                  }
                  setChosedTime(undefined);
                  onBlur();
                }}
              />
            </Form.Group>
            <Form.Group className="mb-0" controlId={name} as={Col} md={6}>
              <FieldLabel label="Время" isRequired={isRequired} />
              <DatePicker
                className={`form-control ${showError ? 'is-invalid' : ''}`}
                selected={chosedTime}
                onChange={(date: Date) => {
                  const dtWithTz = moment(date.getTime()).utcOffset(MOSCOW_TIMEZONE_OFFSET);
                  const copiedDate = chosedDate ? new Date(chosedDate?.getTime()) : undefined;
                  const startDate = copiedDate ? new Date(copiedDate.setHours(date.getHours(), date.getMinutes())) : undefined;
                  if (copiedDate && startDate && !isNaN(startDate.getTime())) {
                    input.onChange({
                      date: chosedDate ? chosedDate.toISOString().substring(0, 10) : null,
                      startDate: new Date(copiedDate.setHours(dtWithTz.hours(), dtWithTz.minutes())),
                    });
                    setChosedTime(startDate);
                  }
                }}
                disabled={isReadonly || !chosedDate}
                onFocus={fetchTimeSlots}
                onBlur={onBlur}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={30}
                locale={ru}
                placeholderText="Выберите время"
                dateFormat="HH:mm"
                timeFormat="HH:mm"
                timeCaption="Время"
                includeTimes={includedTimes}
              />
            </Form.Group>
            <div className="mx-1">
              <Form.Control readOnly={true} hidden={true} value={chosedTime?.toISOString() || ''} isInvalid={showError} />
              <FieldError error={errors ? `${errors}` : ERROR_MESSAGES.START_DATE} />
            </div>
          </Form.Row>
        );
      }}
    />
  );
});
