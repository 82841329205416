import { GrpcService } from './GrpcService';
import LoginStore from 'src/store/LoginStore';
import { REFRESH_TOKEN_TIME } from 'src/store/LoginStore/LoginStore';
import { logger } from '@qlean/front-logger';
import { BaseException, ExceptionBuilder, InvalidArgumentException } from '@qlean/front-exceptions';
import { toast } from 'react-toastify';
import { captureGrpcException } from '@qlean/front-sentry';

export * from './codegen/app_pb';
const grpcService = new GrpcService(process.env.REACT_APP_API);

function getUserFriendlyErrors(error: Error) {
  const errorMessage = decodeURI(error.message || '');

  if (errorMessage.includes('check_plan_deliveries_limit_reached')) {
    return 'Измените план заказа или слоты забора/доставки!';
  }

  if (errorMessage.includes('address_id')) {
    return 'Проверьте корректность адреса (он должен быть указан до квартиры)!';
  }

  const apiException = ExceptionBuilder.buildFromRpcException(error);

  if (apiException instanceof InvalidArgumentException) {
    if (apiException.validationErrors.length) {
      return apiException.validationErrors.map(({ message }) => message).join('\n');
    }
  }

  if (apiException instanceof BaseException) {
    return apiException.toString();
  }

  return error;
}

const TOAST_TIMEOUT = 15000;
const REFRESH_TIMEOUT = 2500;

grpcService.interceptors.errors.push((error) => {
  const errorMessage = decodeURI(error.message || '');
  console.log('Error', error);
  if (error.code === 419) {
    const lastRefreshTokenTime = localStorage.getItem(REFRESH_TOKEN_TIME);
    const lockExpirationTime = parseInt(lastRefreshTokenTime ?? '0', 10) + REFRESH_TIMEOUT;

    console.log('419 error!', { lastRefreshTokenTime, lockExpirationTime });

    // Эта проверка нужна, так как в процессе обновления - вклакда,
    // которая обновляет, может умереть и тогда лок никогда не снимется
    if (lastRefreshTokenTime && lockExpirationTime > Date.now()) {
      logger.debug('Interceptor, [refreshToken] already refreshed', {
        lockExpirationTime,
      });

      console.log('Interceptor, [refreshToken] already refreshed', {
        lockExpirationTime,
      });
      return new Promise((resolve) => setTimeout(resolve, REFRESH_TIMEOUT));
    }
    console.log('Interceptor, [refreshToken] was not refreshed', {
      lockExpirationTime,
    });

    return LoginStore.refresh();
  } else if (errorMessage === 'NOT_FOUND - account not found') {
    return Promise.resolve();
  } else {
    logger.error(`[GrpcService] error`, error);
    toast(getUserFriendlyErrors(error), {
      type: 'error',
      autoClose: TOAST_TIMEOUT,
      pauseOnHover: true,
    });

    // if error.code in 500...599
    if (error.code >= 500 && error.code < 600) {
      captureGrpcException(error);
    }

    return Promise.resolve();
  }
});

export default grpcService;
