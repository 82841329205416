import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { ManagerCommunications } from '../ManagerCommunications';

const Dashboard = observer(({ setTitle }) => {
  useEffect(() => {
    setTitle();
  }, []);
  return (
    <>
      <ManagerCommunications />
    </>
  );
});

export default Dashboard;
