import React from 'react';

import { Form } from 'react-bootstrap';
import { Field } from 'react-final-form';
import { FieldLabel } from 'src/components/Fields/FieldLabel';
import { FieldError } from 'src/components/Fields/FieldError';

import { TFieldProp } from 'src/components/Fields/field.interface';

export interface INumberFieldProp {
  isInteger?: boolean; // ввод только целых чисел
  isPositive?: boolean; // ввод только положительных чисел
}

export type TNumberFieldProp = INumberFieldProp & TFieldProp<number>;

export class NumberField extends React.Component<TNumberFieldProp> {
  static defaultProps: Partial<TNumberFieldProp> = {
    isReadonly: false,
    isRequired: false,
  };

  private parser = (value?: string | number) => {
    const { isInteger = false, isPositive = false } = this.props;
    if (!value) {
      return value;
    }

    let res = value.toString().replace(/[^\d-.]/g, '');

    if (isPositive) {
      res = res.replace(/-/, '');
    }

    if (isInteger) {
      res = res.replace(/\./g, '');
    }

    return res;
  };

  render() {
    const { name, placeholder, initialValue, isRequired, isReadonly, label } = this.props;

    return (
      <Field
        name={name}
        initialValue={this.parser(initialValue)}
        parse={this.parser}
        render={({ input, meta }) => (
          <Form.Group controlId={name}>
            <FieldLabel {...label} isRequired={isRequired} />
            <Form.Control
              {...input}
              value={input.value?.toString()}
              readOnly={isReadonly}
              placeholder={placeholder}
              isInvalid={FieldError.isInvalid(meta)}
            />
            <FieldError error={meta.error} />
          </Form.Group>
        )}
      />
    );
  }
}
