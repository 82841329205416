import React from 'react';
import { observer } from 'mobx-react';
import { DropdownButton } from 'react-bootstrap';
import { toast } from 'react-toastify';

import CommunicationStore, { TaskDto, TaskListItemDto } from 'src/store/CommunicationStore';
import { dictionary } from 'src/utils/dictionary';
import { PlatformCRMWeb } from 'src/services/GrpcService';
import { ReactComponent as SuccessLogo } from './assets/success.svg';
import { ReactComponent as CancelledLogo } from './assets/cancelled.svg';
import { DropdownList } from 'src/components/DropdownList';
import { TelephoneForward, Alarm, Clock, TelephoneX, XSquareFill } from 'react-bootstrap-icons';

interface IProps {
  tasks: TaskListItemDto[];
  activeTaskIndex: number;
  communicationId: string;
  isReadonly?: boolean;
  phone?: string;
}

export const CrossellTabs = observer((props: IProps) => {
  const { tasks, activeTaskIndex, communicationId, isReadonly, phone } = props;
  const { addTask } = CommunicationStore;
  const isRTL = document.documentElement.getAttribute('dir') === 'rtl';

  const getClassName = (state: PlatformCRMWeb.TaskState, id: string | undefined) => {
    const entityId = tasks[activeTaskIndex]?.task?.entity?.entityId;
    if (!entityId || id !== entityId) {
      if (state === PlatformCRMWeb.TaskState.CANCELLED) {
        return 'text-danger';
      }
      if (state === PlatformCRMWeb.TaskState.PROCEED) {
        return 'text-success';
      }
      return '';
    } else {
      return 'active';
    }
  };

  const onCreate = (skill: string, title: string) => {
    addTask({ communicationId, taskInput: { skill, title }, phone }).then((res) => {
      if (res) {
        toast('Успешно создана подзадача', { type: 'success', autoClose: 1500 });
      }
    });
  };

  const getTabHeaderContent = (task: TaskDto) => {
    const product = task?.skill?.name.substr(0, task?.skill?.name.indexOf('-'));
    const productText =
      dictionary.selectOptions.serviceTypes.find((pr) => pr.id === product)?.value || 'Ручной лид';
    return (
      <div className={`nav-link ${getClassName(task.state, task.entity?.entityId)}`}>
        {task.state === PlatformCRMWeb.TaskState.IN_WORK && (
          <TelephoneForward className="mr-2" color="royalblue" size={16} />
        )}
        {task.state === PlatformCRMWeb.TaskState.PROCEED && (
          <SuccessLogo className="mr-1" width="20px" height="20px" />
        )}
        {task.state === PlatformCRMWeb.TaskState.CANCELLED && (
          <CancelledLogo className="mr-1" width="20px" height="20px" />
        )}
        {task.state === PlatformCRMWeb.TaskState.RESCHEDULED && (
          <Alarm className="mr-2" color="green" size={16} />
        )}
        {task.state === PlatformCRMWeb.TaskState.POSTPONED && (
          <Clock className="mr-2" color="blue" size={16} />
        )}
        {task.state === PlatformCRMWeb.TaskState.NO_COMMUNICATION && (
          <TelephoneX className="mr-2" color="red" size={16} />
        )}
        {task.state === PlatformCRMWeb.TaskState.DEDUPLICATED && (
          <XSquareFill className="mr-2" color="red" size={16} />
        )}
        {productText ? productText : product}
      </div>
    );
  };

  return (
    <>
      <div className="d-flex">
        <div className="nav nav-tabs tabs-alt mb-3">
          {tasks
            .sort((a, b) => a.order - b.order)
            .map(({ task }) => {
              return (
                <>
                  {task.state === PlatformCRMWeb.TaskState.NEW ? (
                    <div key={task.id} className="nav-item mb-1 mr-1">
                      {getTabHeaderContent(task)}
                    </div>
                  ) : (
                    <a
                      href={`/communication/${communicationId}/${task.id}`}
                      key={task.id}
                      className="nav-item mb-1 mr-1"
                    >
                      {getTabHeaderContent(task)}
                    </a>
                  )}
                </>
              );
            })}
          <DropdownButton
            id="add-subtask"
            variant="secondary"
            title="Добавить продукт"
            className="d-inline-block ml-4"
            alignRight={isRTL}
            disabled={isReadonly}
          >
            <DropdownList
              options={dictionary.selectOptions.serviceTypesMenu}
              onClick={(option) => onCreate(option.id.toString(), option.value)}
            />
          </DropdownButton>
        </div>
      </div>
    </>
  );
});
