import React from 'react';

import { Form } from 'react-bootstrap';

import { FieldInputProps } from 'src/components/Fields';

import { dictionary } from 'src/utils/dictionary';

interface Props {
  placeholder?: string;
  isInvalid?: boolean;
  isRequired?: boolean;
  readOnly?: boolean;
}

export class SimpleText extends React.Component<Props & FieldInputProps> {
  render() {
    const { isInvalid, isRequired, ...props } = this.props;
    const placeholder = props.placeholder || dictionary.formControl[props.name].toLowerCase();

    return (
      <>
        <Form.Control {...props} isInvalid={isInvalid} placeholder={`Введите ${placeholder} ...`} />
        {isRequired && (
          <Form.Control.Feedback type="invalid">Обязательное поле ввода</Form.Control.Feedback>
        )}
      </>
    );
  }
}
