import { IAddress, IParsedAddress, ISelectedValue, IValue } from 'src/components/Fields/Address/interfaces/address.interface';
import { AddressDto, SsoAddressDto } from '../../../store/CustomerStore';
import { plainToClass } from 'class-transformer';
import { ISuggestion } from './interfaces/dadata.interface';

const REGIONS_ISO = {
  'RU-MOS': 1,
  'RU-MOW': 1,
  'RU-SPE': 2,
  'RU-LEN': 2,
};

export function toParsedAddress(address?: AddressDto | IAddress): IParsedAddress | undefined {
  if (!address) {
    return;
  }

  const result: IParsedAddress = {
    ...address,
    object: {},
    detail: address?.detail || {},
    id: address?.id || undefined,
  };

  if (address.object) {
    result.object = JSON.parse(address.object);
  }

  return result;
}

export function toAddress(parsedAddress?: IParsedAddress): AddressDto | undefined {
  if (!parsedAddress) {
    return;
  }

  const result: AddressDto = plainToClass(AddressDto, parsedAddress);

  if (parsedAddress.object) {
    result.object = JSON.stringify(parsedAddress.object);
  }

  return result;
}

export const getAddressBySuggestion = (
  suggestion: ISuggestion,
  selected?: ISelectedValue,
): IParsedAddress | undefined => {
  if (suggestion.data) {
    const { metro, geo_lat, geo_lon, ...object } = suggestion.data;

    return {
      ...selected?.address,
      value: suggestion.value,
      unrestrictedValue: suggestion.unrestricted_value,
      object,
      metro,
      coordinates: {
        lat: parseFloat(geo_lat || '0'),
        lng: parseFloat(geo_lon || '0'),
      },
      id: suggestion.id || undefined,
      detail: selected?.address?.detail,
    };
  }
};

export const valuesFromAddresses = (addresses: SsoAddressDto[]): IValue[] => {
  const values: IValue[] = addresses.map((address) => {
    const obj = JSON.parse(address.object || '{}');
    const regionId = REGIONS_ISO[obj.region_iso_code] || 1; // TODO пока что в лоб (потом будем переделывать)

    return {
      regionId,
      address: {
        address_id: address?.id,
        value: address?.value || '',
        unrestrictedValue: address?.unrestrictedValue || '',
        object: address?.object,
        detail: address?.detail,
        coordinates: address?.coordinates,
        metro: address?.metro,
      },
    };
  });
  return values;
};

export const favoriteAddressesToSuggests = (favourites?: IValue[]): ISuggestion[] | undefined => {
  if (!favourites) return undefined;
  return favourites?.length
    ? favourites.map<ISuggestion>((value) => ({
        value: value?.address?.value || '',
        unrestricted_value: value?.address?.unrestrictedValue,
        data: JSON.parse(value?.address?.object || '{}'),
        isFavourite: true,
        detail: value?.address?.detail,
        id: value?.address?.address_id || null,
      }))
    : [];
};

export const isAddressesEqual = (a?: IParsedAddress | IAddress, b?: IParsedAddress | IAddress) => {
  const isValueEqual = a?.value === b?.value;
  const isDetailEqual =
    a?.detail?.intercom === b?.detail?.intercom &&
    a?.detail?.floor === b?.detail?.floor &&
    a?.detail?.entrance === b?.detail?.entrance &&
    a?.detail?.comment === b?.detail?.comment;
  return isValueEqual && isDetailEqual;
};

export function trimAddressApartmentsPart(address: string): string {
  return address.replace(/(,\s*кв(?!-)\.?\s*[\d]*.*)/, '');
}
