import React from 'react';

import PersonInfo from './PersonInfo';
import PersonHistory from './PersonHistory';
import { PersonDto } from 'src/store/CustomerStore';

interface IProps {
  person: PersonDto;
  communicationId?: string;
}

const Person = ({ person, communicationId }: IProps) => {
  return (
    <>
      <PersonInfo person={person} />
      <PersonHistory communicationId={communicationId} person={person} />
    </>
  );
};

export default Person;
